import React, { useState} from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validateEmail } from "../../../common/AuthValidation";


export default function ForgotPassword() {

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [id, setId] = useState("");
  const onEmailChange = e => {
    setEmailError(validateEmail(e.target.value));
    setEmail(e.target.value);
  };

  const onHandleSubmit = async e => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    await DataService.post(API.User.UserForgotPassword, formData)
      .then(response => {
        localStorage.setItem("forgotId", response.data.data.forgotId);
        setId(response.data.data.forgotId);
        toast(response.data.message);
      })
      .catch(error => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <div>
      <Index.Box className="auth-flex-main">
        <Index.Box className="auth-left">
          <Index.AuthVideo />
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-inner-main">
            <Index.Box className="auth-inner">
              <Index.Box className="auth-logo-main">
                <img
                  src={Index.Svg.ncxbluelogo}
                  className="auth-logo"
                  alt="auth-logo"
                />
                <Index.Typography className="auth-logo-text">
                  Token
                </Index.Typography>
              </Index.Box>
              <ToastContainer />
              <Index.Typography
                className="auth-title auth-forget-title"
                variant="h1"
                component="h1"
              >
                Reset Password
              </Index.Typography>
              <Index.Typography className="auth-para forget-para" variant="p" component="p">
                If you forgot your password, well, then we'll email you
                instructions to reset your password.
              </Index.Typography>
              <Index.Box className="input-box auth-field">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    value={email}
                    onChange={onEmailChange}
                    onKeyPress={e => {
                      if (e.key === "Enter") {
                        onHandleSubmit();
                      }
                    }}
                    id="fullWidth"
                    className="form-control"
                    placeholder="Your Email Address"
                  />
                </Index.Box>
                <p className="error-text">
                  {emailError}
                </p>
              </Index.Box>
              <Index.Box className="auth-forget-flex">
                <Index.PrimaryButton
                  className="primary-btn auth-btn auth-forget-btn"
                  btnLabel="Send Reset Link"
                  type="button"
                  onClick={onHandleSubmit}
                />
                <Index.Typography
                  className="auth-sub-para"
                  variant="p"
                  component="p"
                >
                  <span className="auth-sub-para-span">
                    <Index.Link to="/sign-in" className="back-to-login">
                      Return to Login
                    </Index.Link>
                  </span>{""}
                </Index.Typography>
              </Index.Box>
              <Index.AuthFooter />

            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
