import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { ToastContainer, toast } from "react-toastify";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";

const Profile = () => {
  const token = useSelector((store) => store.admin.token);
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [number,setNumber] = useState("");
  const [semail,setSemail] = useState("");
  const [error, setError] = useState({
    name: "",
    email: "",
    number: "",
    semail: "",
  });


  const onHandleNameChange=(event) =>{
    setName(event.target.value);
    if (name.length) {
      setError((prev) => ({
        ...prev,
        name: "",
      }));
    }
  }
  const onHandleemailChange=(event) =>{
    setEmail(event.target.value);
    if (name.length) {
      setError((prev) => ({
        ...prev,
        name: "",
      }));
    }
  }
  const onHandlenumberChange=(event) =>{
    setNumber(event.target.value);
    if (name.length) {
      setError((prev) => ({
        ...prev,
        name: "",
      }));
    }
  }
  const onHandlesemailChange=(event) =>{
    setSemail(event.target.value);
    if (name.length) {
      setError((prev) => ({
        ...prev,
        name: "",
      }));
    }
  }





  const onSubmitError = () => {
    if (name.length === 0) {
      setError((prev) => ({
        ...prev,
        name: "Please Enter name",
      }));
    }
    if (email.length === 0) {     
      setError((prev) => ({
        ...prev,
        email: "Please Enter email",
      }));
    }
    if (number.length === 0) {
      setError((prev) => ({
        ...prev,
        number: "Please Enter phone number",
      }));
    }
    if (semail.length === 0) {
      setError((prev) => ({
        ...prev,
        semail: "Please Enter secoundary mail",
      }));
    }
  };
  const getProfile = async () => {
    await DataService.get(API.Admin.PROFILE_DATA, {
      headers: {
        auth: token,
      },
    }).then((response) => {
      console.log(response?.data?.data , "response?.data?.data")
      let res = response?.data?.data
      setName(res?.adminName);
      setEmail(res.email);
      setNumber(res.phoneNumber);
      setSemail(res.secondaryEmail);
    });
  };
  
  const onChange = async () => {
    if (
      name?.length > 0 &&
      email?.length > 0 &&
      semail?.length > 0 &&
      number?.length > 0
    ) {
      const formData = new URLSearchParams();
      formData.append("adminName",name);
      formData.append("email",email);
      formData.append("phoneNumber",number);
      formData.append("secondaryEmail",semail);
      await DataService.post(API.Admin.EDIT_PROFILE, formData, {
        headers: {
          auth: token,
        },
      })
        .then((response) => {
          toast(response?.data?.message);
          setName("");
          setEmail("");
          setSemail("");
          setNumber("");
        })
        .catch((error) => {
          toast(error?.response?.data?.message);
        });
    } else {
      onSubmitError();
    }
  };
  useEffect(()=>{
    getProfile();
  },[token])

  const onDiscard = () => {
    setName("");
    setEmail("");
    setSemail("");
    setNumber("");
  };
  return (
    <Index.Box className="tabpanel-main">
      <ToastContainer />
      {/* <Index.Typography
      className="common-para edit-highlight-text"
      component="p"
      variant="p"
    >
      General
    </Index.Typography>
    <Index.Typography
      className="common-para edit-para-text"
      component="p"
      variant="p"
    >
      Basic info, like your name and address that will
      displayed in public
    </Index.Typography>
    <Index.Box className="edit-profile-flex">
      <Index.Box className="file-upload-btn-main">
        <img
          src={Index.Png.usericon}
          className="upload-profile-img"
          alt="upload img"
        ></img>
        <Index.Button
          variant="contained"
          component="label"
          className="file-upload-btn"
        >
          <img
            src={Index.Svg.edit}
            className="upload-icon-img"
            alt="img"
          ></img>
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
          />
        </Index.Button>
      </Index.Box>
      <Index.Box className="switch-main">
        <Index.FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
          label="Public Profile"
          className="switch-lable"
        />
      </Index.Box>
    </Index.Box> */}
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              {console.log(name , "name")}
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Name
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={name ? name : ''}
                    name="name"
                    onChange={onHandleNameChange}
                  />
                </Index.Box>
                <p className="error-text">{error.name}</p>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Email
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={email}
                    name="email"
                    onChange={onHandleemailChange}
                  />
                </Index.Box>
                <p className="error-text">{error.email}</p>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Phone Number
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    type="number"
                    className="form-control"
                    value={number}
                    name="number"
                    onChange={onHandlenumberChange}
                  />
                </Index.Box>
                <p className="error-text">{error.number}</p>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Secondary Email
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={semail}
                    name="semail"
                    onChange={onHandlesemailChange}
                  />
                </Index.Box>
                <p className="error-text">{error.semail}</p>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="user-btn-flex comman-btn-main">
                <Index.Box className="discard-btn-main border-btn-main">
                  <Index.Button
                    className="discard-user-btn border-btn"
                    onClick={onDiscard}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                <Index.Box className="save-btn-main ">
                  <Index.Button
                    className="save-user-btn primary-btn"
                    onClick={onChange}
                  >
                    <img
                      src={Index.Svg.save}
                      alt="save"
                      className="user-save-icon"
                    ></img>
                    Save
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Profile;
