import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import PropTypes from "prop-types";
import PendingWithdrawal from "./PendingWithdrawal";
import Withdrawal from "./Withdrawal";
import ListTab from "../../../../component/common/ListTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WithdrawalHistory() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title withdrawal-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Withdrawal History
                </Index.Typography>
                <ToastContainer />
              </Index.Box>

              <Index.Box className="tabs-main-box">
                <Index.Box sx={{ width: "100%" }}>
                  <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <ListTab
                      value={value}
                      onChange={handleChange}
                      label1={"Withdrawal History"}
                      label2={"Pending Withdrawal"}
                      children1={< Withdrawal/>}
                      children2={<PendingWithdrawal />}
                      tabVal={value}
                    ></ListTab>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
