import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const Privatesale = () => {
  const data = useSelector((store) => store.admin.userData);
  const [privateSaleData, setPrivateSaleData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPrivateSale = async () => {
    await DataService.get(API.User.getPrivateSale, {
      headers: {
        auth: data.token,
      },
    }).then((response) => {
      setPrivateSaleData(response?.data?.data);
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/")
      }
    });
  };
  useEffect(() => {
    getPrivateSale();
  }, []);
  return (

      <Index.Box className="user-dash-third-row">
        <Index.Box className="dash-token-inner-box">
          <Index.Box className="dashboard-referral-flex dash-token-title-flex">
            <Index.Typography
              className="dash-referral-text"
              component="h3"
              variant="h3"
            >
              Private
              <span className="dash-referral-span-text"> Sale</span>
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dash-token-flex-row display-flex">
            <Index.Box className="dash-token-main dash-token-col-1 dash-token-mob-1">
              <Index.Box className="dash-token-heading-main">
                <Index.Typography
                  className="dash-token-heading"
                  component="h5"
                  variant="h5"
                >
                  Private Sale Round
                </Index.Typography>
              </Index.Box>
              <Index.Box className="dash-token-col">
                {privateSaleData?.map((round) => {
                  return (
                    <Index.Box className="dash-token-data-flex">
                      <img
                        src={Index.Svg.tokenarrow}
                        className="token-arrow-icon"
                      />

                      <Index.Typography
                        className="dash-token-label"
                        component="h5"
                        variant="h5"
                      >
                        {round.phaseName}
                      </Index.Typography>
                    </Index.Box>
                  );
                })}
                <Index.Box className="dash-token-data-flex">
                  <img
                    src={Index.Svg.tokenarrow}
                    className="token-arrow-icon"
                  />
                  <Index.Typography
                    className="dash-token-label"
                    component="h5"
                    variant="h5"
                  >
                    Public Sale
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="dash-token-data-flex listing-flex">
                  <img
                    src={Index.Svg.listingprice}
                    className="listing-arrow-icon"
                  />
                  <Index.Typography
                    className="dash-token-label"
                    component="h5"
                    variant="h5"
                  >
                    Listing Price
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="dash-token-main dash-token-col-2 dash-token-mob-2">
              <Index.Box className="dash-token-heading-main">
                <Index.Typography
                  className="dash-token-heading"
                  component="h5"
                  variant="h5"
                >
                  NCX Token Price in USDT
                </Index.Typography>
              </Index.Box>
              <Index.Box className="dash-token-col">
                {privateSaleData?.map((round) => {
                  return (
                    <Index.Box className="dash-token-data-flex">
                      <Index.Typography
                        className="dash-token-label"
                        component="h5"
                        variant="h5"
                      >
                        ${round?.tokenPrice}
                      </Index.Typography>
                    </Index.Box>
                  );
                })}
                <Index.Box className="dash-token-data-flex">
                  <Index.Typography
                    className="dash-token-label"
                    component="h5"
                    variant="h5"
                  >
                    $0.004
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Typography
                    className="dash-token-label"
                    component="h5"
                    variant="h5"
                  >
                    $0.008
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="dash-token-main dash-token-col-3 dash-token-mob-3">
              <Index.Box className="dash-token-heading-main">
                <Index.Typography
                  className="dash-token-heading"
                  component="h5"
                  variant="h5"
                >
                  Lockup Period
                </Index.Typography>
              </Index.Box>
              <Index.Box className="dash-token-col">
                {privateSaleData?.map((round) => {
                  return (
                    <Index.Box className="dash-token-data-flex">
                      <Index.Typography
                        className="dash-token-label"
                        component="h5"
                        variant="h5"
                      >
                        {round?.vestingPeriod}{" "}
                        {round?.vestingPeriod > 12 ? "Days" : "Months"}
                      </Index.Typography>
                    </Index.Box>
                  );
                })}
                <Index.Box className="dash-token-data-flex">
                  <Index.Typography
                    className="dash-token-label"
                    component="h5"
                    variant="h5"
                  >
                    Unlocked
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="dash-token-main dash-token-col-4 dash-token-mob-4">
              <Index.Box className="dash-token-heading-main">
                <Index.Typography
                  className="dash-token-heading"
                  component="h5"
                  variant="h5"
                >
                  After Lockup Period Ends
                </Index.Typography>
              </Index.Box>
              <Index.Box className="dash-token-col">
                {privateSaleData?.map((round) => {
                  return (
                    <Index.Box className="dash-token-data-flex">
                      <Index.Typography
                        className="dash-token-label"
                        component="h5"
                        variant="h5"
                      >
                        {round?.monthlyRelease}% Monthly Release
                      </Index.Typography>
                    </Index.Box>
                  );
                })}
                <Index.Box className="dash-token-data-flex">
                  <Index.Typography
                    className="dash-token-label"
                    component="h5"
                    variant="h5"
                  >
                    25% Monthly Release
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="dash-token-main dash-token-col-5 dash-token-mob-5">
              <Index.Box className="dash-token-heading-main">
                <Index.Typography
                  className="dash-token-heading"
                  component="h5"
                  variant="h5"
                >
                  Receive Trading Fee Discounts during the Vesting Period
                </Index.Typography>
              </Index.Box>
              <Index.Box className="dash-token-col">
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex"></Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex"></Index.Box>
                <Index.Box className="dash-token-data-flex"></Index.Box>
                <Index.Box className="dash-token-data-flex"></Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex">
                    <img
                      src={Index.Svg.greycheck}
                      className="grey-check-icon"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex">
                    <img
                      src={Index.Svg.greycheck}
                      className="grey-check-icon"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex">
                    <img
                      src={Index.Svg.greycheck}
                      className="grey-check-icon"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex">
                    <img
                      src={Index.Svg.greycheck}
                      className="grey-check-icon"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="dash-token-main dash-token-col-6 dash-token-mob-6">
              <Index.Box className="dash-token-heading-main">
                <Index.Typography
                  className="dash-token-heading"
                  component="h5"
                  variant="h5"
                >
                  Receive Staking Rewards during the Vesting Period
                </Index.Typography>
              </Index.Box>
              <Index.Box className="dash-token-col">
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex"></Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex"></Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex"></Index.Box>
                <Index.Box className="dash-token-data-flex"></Index.Box>
                <Index.Box className="dash-token-data-flex"></Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex">
                    <img
                      src={Index.Svg.greycheck}
                      className="grey-check-icon"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex">
                    <img
                      src={Index.Svg.greycheck}
                      className="grey-check-icon"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="dash-token-data-flex">
                  <Index.Box className="dash-token-check-flex">
                    <img
                      src={Index.Svg.greycheck}
                      className="grey-check-icon"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
   
  );
};

export default Privatesale;
