import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination ,
  InputLabel,
  FilledInput,
} from "@mui/material";

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Video from "../assets/Video";

import Header from "../component/defaulLayout/user/Header";
import Footer from "../component/defaulLayout/user/Footer";
import UserSidebar from "../component/defaulLayout/user/UserSidebar";
import PrimaryButton from "../component/common/PrimaryButton";
// import GreyButton from "../component/common/GreyButton";
import GreyButton from "../component/common/GreyButton";
import TextButton from "../component/common/TextButton";
import WhiteBorderButton from "../component/common/WhiteBorderButton";
import GreenButton from "../component/common/GreenButton";
import PriceCard from "../component/common/PriceCard";
import PrimaryBorderButton from "../component/common/PrimaryBorderButton";
import PrivateSaleWallet from "../component/common/PrivateSaleWallet";
import OwlCarousel from "react-owl-carousel3";
import Countdown from "react-countdown";
import GoToTop from '../component/defaulLayout/user/GoToTop'





export default {
  Countdown,
  OwlCarousel,
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  PrivateSaleWallet,
  ArrowForwardIosSharpIcon,
  MuiAccordion , 
  MuiAccordionSummary,
  MuiAccordionDetails,
  InputLabel,
  FilledInput,
  // components
  Header,
  Footer,
  UserSidebar,
  PrimaryButton,
  GreyButton,
  TextButton,
  WhiteBorderButton,
  GreenButton,
  PriceCard,
  PrimaryBorderButton,
  Video,
  TablePagination,
  GoToTop,
};
