import React from "react";
import Index from "../../../Index";

const SecuritySettings = () => {
  const IOSSwitch = Index.styled((props) => (
    <Index.Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(25px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(125, 130, 135, 1)"
              : "rgba(110, 102, 230, 1)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "rgba(125, 130, 135, 1)" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
  
      <Index.Box className="tabpanel-main">
        <Index.Box className="security-setting-main">
          <Index.Typography
            className="page-mid-title"
            component="h3"
            variant="h3"
          >
            General Security Settings
          </Index.Typography>
          <Index.Box className="switch-main profile-switch">
            <Index.FormControlLabel
              control={<IOSSwitch defaultChecked />}
              label="Save my Activities Log."
              className="switch-lable"
            />
          </Index.Box>
          <Index.Box className="switch-main profile-switch">
            <Index.FormControlLabel
              control={<IOSSwitch defaultChecked />}
              label="Alert me by email for unusual activity"
              className="switch-lable"
            />
          </Index.Box>

          <Index.Typography
            className="page-mid-title manage-noti-text"
            component="h3"
            variant="h3"
          >
            Manage Notification
          </Index.Typography>
          <Index.Box className="switch-main profile-switch manage-noti">
            <Index.FormControlLabel
              control={<IOSSwitch defaultChecked />}
              label="Get notification for all purchase"
              className="switch-lable"
            />
          </Index.Box>
          <Index.PrimaryButton
            className="primary-btn profile-btn"
            btnLabel="Update"
          />
        </Index.Box>
      </Index.Box>
  );
};

export default SecuritySettings;
