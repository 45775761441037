import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Index from "../../../Index";
import MyProfile from "./MyProfile";
import ChangePassword from "./ChangePassword";
import SecuritySettings from "./SecuritySettings";
import TwoFA from "./TowFA";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const profile = useMemo(() => <MyProfile />, []);
  const secureSettings = useMemo(() => <SecuritySettings />, []);
  const Password = useMemo(() => <ChangePassword />, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Index.Header />
      <Index.Box className="page-wrapper">
        <Index.Box className="container">
          <Index.Box className="page-inner-main">
            <Index.Box className="page-left-main">
              <Index.UserSidebar />
            </Index.Box>
            <ToastContainer />
            <Index.Box className="page-right-main profile-right-main">
              <Index.Box className="profile-main page-main-box">
                <Index.Typography className="page-titles">
                  Profile Details
                </Index.Typography>
                <Index.Box className="tabs-main-box">
                  <Index.Box sx={{ width: "100%" }}>
                    <Index.Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="tabs-main"
                    >
                      <Index.Tab
                        label="My Profile"
                        {...a11yProps(0)}
                        className="tab"
                      />
                      <Index.Tab
                        label="Security Settings"
                        {...a11yProps(1)}
                        className="tab"
                      />
                      <Index.Tab
                        label="Change Password"
                        {...a11yProps(2)}
                        className="tab"
                      />
                    </Index.Tabs>

                    <TabPanel value={value} index={0} className="tabpanel">
                      {profile}
                    </TabPanel>
                    <TabPanel value={value} index={1} className="tabpanel">
                      {secureSettings}
                    </TabPanel>
                    <TabPanel value={value} index={2} className="tabpanel">
                      {Password}
                    </TabPanel>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <TwoFA />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Footer />
    </div>
  );
}
