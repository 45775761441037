import React from "react";
import Index from "../../../Index";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <Index.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <Index.MuiAccordionSummary
    expandIcon={<Index.ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(Index.MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const RecentTransaction = () => {
  const token = useSelector((store) => store.admin.userToken);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(2);
  const [expanded, setExpanded] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [searchTransaction, setSearchTransaction] = useState("");
  const [expandedTransaction, setExpandedTransaction] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (panel, table) => (event, newExpanded) => {
    if (table === "Tr") {
      setExpandedTransaction(newExpanded ? panel : false);
    }
    setExpanded(newExpanded ? panel : false);
  };
  const onHandleSearchTransaction = (e) => {
    const filteredGames = transactionData.filter((item) => {
      const topicIncludes = item?.tranxId
        ?.toLowerCase()
        ?.includes(e.target.value?.toLowerCase());
      return topicIncludes;
    });
    setTransactionData(filteredGames);
    setSearchTransaction(e.target.value);
  };
  const getrecentTransaction = async () => {
    await DataService.get(API.User.TRANSACTION_HISTORY, {
      headers: {
        auth: token,
      },
    }).then((res) => {
      setTransactionData(res?.data?.data);
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/")
      }
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    getrecentTransaction();
  }, []);

  return (
  
      <Index.Box className="user-dash-second-row display-flex">
        <Index.Box className="dashboard-graph-flex">
          <Index.Box className="dash-recent-main">
            <Index.Box className="dashboard-referral-flex">
              <Index.Typography
                className="dash-referral-text"
                component="h3"
                variant="h3"
              >
                Recent
                <span className="dash-referral-span-text"> Transactions</span>
              </Index.Typography>
              <Index.Link
                className="dash-view-text"
                component="h3"
                variant="h3"
                to="/transactions"
              >
                VIEW ALL{" "}
                <img src={Index.Svg.greyright} className="grey-right-errow" />
              </Index.Link>
            </Index.Box>
            <Index.Link to="/privatesale" className="text-decoration">
              <Index.Box className="dash-add-end-flex">
                <Index.Box className="trans-btn-main">
                  <Index.Button className="trans-btn">
                    <img src={Index?.Svg?.plus} className="plus-icon" />
                    Add Tokens
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Link>
            <Index.Box className="trans-search-flex dash-trans-flex">
              <Index.Box className="trans-search-box">
                <Index.Box className="input-box trans-field">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      type="text"
                      value={searchTransaction}
                      onChange={onHandleSearchTransaction}
                      className="form-control"
                      placeholder="Transaction ID to quick Search"
                    />
                    <img src={Index?.Svg?.greysearch} className="search-icon" />
                    <Index.Box className="trans-search-main">
                      <Index.Box className="trans-search-btn-main">
                        <Index.Button className="trans-search-btn">
                          <img
                            src={Index?.Svg?.token1}
                            className="trans-icons"
                          />
                        </Index.Button>
                        <Index.Button className="trans-search-btn">
                          <img
                            src={Index?.Svg?.token2}
                            className="trans-icons"
                          />
                        </Index.Button>
                        <Index.Button className="trans-search-btn">
                          <img
                            src={Index?.Svg?.settingicon}
                            className="trans-icons"
                          />
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="desktop-view">
              <Index.Box className="trans-data-main scrollbar">
                {/* Fields */}
                <Index.Box className="trans-data-head-row">
                  <Index.Typography
                    className="trans-col-title trans-data-col-1"
                    component="h3"
                    variant="h3"
                  >
                    TRANX ID
                    <br />
                    TOKEN DASHBOARD
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title trans-data-col-2"
                    component="h3"
                    variant="h3"
                  >
                    ROUND
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title trans-data-col-3"
                    component="h3"
                    variant="h3"
                  >
                    AMOUNT
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title trans-data-col-4"
                    component="h3"
                    variant="h3"
                  >
                    TOKEN
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title trans-data-col-5"
                    component="h3"
                    variant="h3"
                  >
                    BONUS
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title trans-data-col-6"
                    component="h3"
                    variant="h3"
                  >
                    TOTAL
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title trans-data-col-7"
                    component="h3"
                    variant="h3"
                  >
                    TRANX ID
                    <br />
                    ON NCX
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title trans-data-col-8"
                    component="h3"
                    variant="h3"
                  >
                    STATUS
                  </Index.Typography>
                </Index.Box>

                {/* Transaction Data */}
                {transactionData?.length > 0
                  ? transactionData?.map((data) => {
                      return (
                        <Index.Box className="trans-data-inner-row">
                          <Index.Box className="trans-data-inner trans-data-col-1">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {data?.tranxId}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-trans-lables"
                              component="h3"
                              variant="h3"
                            >
                              {moment(transactionData?.createdAt).format(
                                "DD MMM, YYYY h:mm A"
                              )}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="trans-data-inner trans-data-col-2">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {data?.roundId?.phaseName}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-trans-lables"
                              component="h3"
                              variant="h3"
                            >
                              ROUND
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="trans-data-inner trans-data-col-3">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {data?.requestUsdt && data?.requestUsdt !== 0
                                ? data?.requestUsdt
                                    ?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    })
                                    .split("₹")[1]
                                    .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-trans-lables"
                              component="h3"
                              variant="h3"
                            >
                              USDT
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="trans-data-inner trans-data-col-4">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {data?.receivedToken && data?.receivedToken !== 0
                                ? data?.receivedToken
                                    ?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    })
                                    .split("₹")[1]
                                    .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-trans-lables"
                              component="h3"
                              variant="h3"
                            >
                              NCXT
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="trans-data-inner trans-data-col-5">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {data?.bonusToken && data?.bonusToken !== 0
                                ? data?.bonusToken
                                    ?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    })
                                    .split("₹")[1]
                                    .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-trans-lables"
                              component="h3"
                              variant="h3"
                            >
                              NCXT
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="trans-data-inner trans-data-col-6">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {data?.totalToken && data?.totalToken !== 0
                                ? data?.totalToken
                                    ?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    })
                                    .split("₹")[1]
                                    .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-trans-lables"
                              component="h3"
                              variant="h3"
                            >
                              NCXT
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="trans-data-inner trans-data-col-7">
                            <Index.Typography
                              className="dash-trans-titles"
                              component="h3"
                              variant="h3"
                            >
                              {data?.tranxIdOnNcx ? data?.tranxIdOnNcx :"-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="trans-data-inner trans-data-col-8">
                            <Index.Box className="trans-data-flex trans-data-flex-end">
                              <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                                <Index.Button
                                  className={`${
                                    data?.status.toLowerCase() === "pending"
                                      ? "trans-data-btn"
                                      : data?.status.toLowerCase() ===
                                        "approved"
                                      ? "trans-green-btn"
                                      : "trans-red-btn"
                                  } yellow-border-btn`}
                                >
                                  {data?.status}
                                  <Index.Typography className="trans-span-text">
                                    {data?.status.toLowerCase() === "rejected"
                                      ? "Insufficient Funds"
                                      : ""}
                                  </Index.Typography>
                                </Index.Button>
                              </Index.Box>
                              <Index.Box className="dots-drop-main">
                                <Index.Button className="dots-drop-btn">
                                  <img
                                    src={Index?.Svg?.threedots}
                                    className="thre-dots-icon"
                                    alt="logo"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      );
                    })
                  : "No Data Found"}
              </Index.Box>
            </Index.Box>

            <Index.Box className="mobile-view">
              <Index.Box className="trans-data-main">
                <Index.Box className="trans-data-head-row">
                  <Index.Typography
                    className="trans-col-title mob-trans-data-col-1"
                    component="h3"
                    variant="h3"
                  >
                    TRANX ID
                  </Index.Typography>
                  <Index.Typography
                    className="trans-col-title mob-trans-data-col-2"
                    component="h3"
                    variant="h3"
                  >
                    TOKENS
                  </Index.Typography>

                  <Index.Typography
                    className="trans-col-title mob-trans-data-col-3"
                    component="h3"
                    variant="h3"
                  >
                    STATUS
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="cuts-accordian-main">
                  {transactionData?.length > 0
                    ? transactionData?.map((data, index) => {
                        return (
                          <Accordion
                            expanded={expandedTransaction === `panel${index}`}
                            onChange={handleChange(`panel${index}`, "Tr")}
                            className="cuts-accordian"
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              className="cuts-accordian-summary"
                            >
                              <Index.Box className="trans-data-inner-row">
                                <Index.Box className="trans-data-inner mob-trans-data-col-1">
                                  <Index.Box className="trans-inner-data-flex">
                                    <Index.Box className="trans-icon-box yellow-icon-box">
                                      <img
                                        src={Index?.Svg?.yellowwatch}
                                        className="transaction-icon"
                                      />
                                    </Index.Box>
                                    <Index.Box className="trans-data-col-flex">
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {data?.tranxId}
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {moment(
                                          transactionData?.createdAt
                                        ).format("DD MMM, YYYY h:mm A")}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className="trans-data-inner mob-trans-data-col-2">
                                  <Index.Typography
                                    className="dash-trans-titles"
                                    component="h3"
                                    variant="h3"
                                  >
                                    {data?.token && data?.token !== 0
                                      ? data?.token
                                          ?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "INR",
                                          })
                                          .split("₹")[1]
                                          .split(".")[0]
                                      : "0.00"}
                                  </Index.Typography>
                                  <Index.Typography
                                    className="dash-trans-lables"
                                    component="h3"
                                    variant="h3"
                                  >
                                    NCXT
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="trans-data-inner mob-trans-data-col-3">
                                  <Index.Box className="trans-data-flex trans-data-flex-end">
                                    <Index.Box className="dots-drop-main">
                                      <Index.Button className="dots-drop-btn">
                                        <img
                                          src={Index?.Svg?.threedots}
                                          className="thre-dots-icon"
                                          alt="logo"
                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </AccordionSummary>
                            <AccordionDetails className="cuts-accordian-details">
                              <Index.Box className="trans-data-wrapper">
                                <Index.Box className="trans-data-wrapper-inner">
                                  <Index.Box className="trans-collpase-row">
                                    <Index.Typography
                                      className="trans-col-title"
                                      component="h3"
                                      variant="h3"
                                    >
                                      TRANX ID
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-titles"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {data?.tranxId}
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-lables"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {moment(
                                        transactionData?.createdAt
                                      ).format("DD MMM, YYYY h:mm A")}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="trans-collpase-row">
                                    <Index.Typography
                                      className="trans-col-title"
                                      component="h3"
                                      variant="h3"
                                    >
                                      REQUEST AMOUNT
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-titles"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {data?.requestUsdt &&
                                      data?.requestUsdt !== 0
                                        ? data?.requestUsdt
                                            ?.toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "INR",
                                            })
                                            .split("₹")[1]
                                            .split(".")[0]
                                        : "0.00"}
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-lables"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {moment(
                                        transactionData?.createdAt
                                      ).format("DD MMM, YYYY h:mm A")}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="trans-collpase-row">
                                    <Index.Typography
                                      className="trans-col-title"
                                      component="h3"
                                      variant="h3"
                                    >
                                      RECEIVED AMOUNT
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-titles"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {data?.receivedUsdt &&
                                      data?.receivedUsdt !== 0
                                        ? data?.receivedUsdt
                                            ?.toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "INR",
                                            })
                                            .split("₹")[1]
                                            .split(".")[0]
                                        : "0.00"}
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-lables"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {moment(
                                        transactionData?.createdAt
                                      ).format("DD MMM, YYYY h:mm A")}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="trans-collpase-row">
                                    <Index.Typography
                                      className="trans-col-title"
                                      component="h3"
                                      variant="h3"
                                    >
                                      TOTAL TOKENS
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-titles"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {data?.totalToken && data?.totalToken !== 0
                                        ? data?.totalToken
                                            ?.toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "INR",
                                            })
                                            .split("₹")[1]
                                            .split(".")[0]
                                        : "0.00"}
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-lables"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {moment(
                                        transactionData?.createdAt
                                      ).format("DD MMM, YYYY h:mm A")}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="trans-collpase-row">
                                    <Index.Typography
                                      className="trans-col-title"
                                      component="h3"
                                      variant="h3"
                                    >
                                      WALLET HASH
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-titles"
                                      component="h3"
                                      variant="h3"
                                    >
                                      Wallet{" "}
                                      <span className="trans-data-title-span">
                                        {data?.WalletAddress}
                                      </span>
                                    </Index.Typography>
                                    <Index.Typography
                                      className="dash-trans-lables"
                                      component="h3"
                                      variant="h3"
                                    >
                                      Hash{" "}
                                      <span className="trans-data-span">
                                        {data?.hash}
                                      </span>
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className="yellow-border-btn-main trans-yellow-btn-main">
                                  <Index.Button className="yellow-border-btn trans-data-btn">
                                    {data?.status}
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })
                    : "No Data Found"}
                </Index.Box>
              </Index.Box>

              <Index.Box className="pages-pagination-main">
                <Index.TablePagination
                  className="pages-pagination"
                  component="div"
                  count={100}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Index.Box>
              <Index.Box className="pages-pagination-main">
                <Index.Box className="pages-pagination-flex">
                  <Index.Typography className="pages-pagination-text">
                    Show
                  </Index.Typography>
                  <Index.Box className="pages-dropdown-main">
                    <Index.Button className="pages-pagination-btn">
                      5
                    </Index.Button>
                  </Index.Box>
                  <Index.Typography className="pages-pagination-text">
                    OF 05
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
  
  );
};

export default RecentTransaction;
