import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import message from "../../../../ValidationMessage.json";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  getUserData,
  getUserToken,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import { validateEmail } from "../../../common/AuthValidation";
import { SimpleRegister } from "../../../../redux/features/slices/Admin/AdminSlice";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ emailError: "", passwordError: "" });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseEvents = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(getUserData({}));
  }, []);

  const onEmailChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      emailError: validateEmail(e.target.value),
    }));
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    if (password.length > 0) {
      setErrors({
        ...errors,
        passwordError: "",
      });
    }
    setPassword(e.target.value);
  };

  const onSubmitError = () => {
    if (password.length < 1) {
      setErrors({
        ...errors,
        passwordError: message.ENTER_PASSWORD,
      });
    } else {
      setErrors({
        ...errors,
        passwordError: "",
      });
    }
    if (email.length === 0) {
      setErrors((prev) => ({ ...prev, emailError: message.ENTER_EMAIL }));
    } else {
      setErrors((prev) => ({ ...prev, emailError: "" }));
    }
  };

  const onHandleSubmit = async () => {
    if (email.length > 1 && password.length > 1) {
      const formData = new URLSearchParams();
      formData.append("email", email);
      formData.append("password", password);
      await DataService.post(API.User.UserLogin, formData)
        .then((response) => {
          if (
            response?.data?.message !== "User not exist" &&
            response?.data?.message !==
              "Please verify your email address from Gmail" &&
            response?.data?.message !==
              "Your account has been blocked due to multiple purchase orders request on Presale NCX"
          ) {
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
            dispatch(getUserData(response?.data?.data));
            dispatch(getUserToken(response?.data?.data?.token));
          }
          if (
            response?.data?.message ===
            "Your account has been blocked due to multiple purchase orders request on Presale NCX"
          ) {
            toast.error(response?.data?.message);
          }
          if (response?.data?.message === "User not exist") {
            toast.error(response?.data?.message);
          } else {
            toast.success(response?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      onSubmitError();
    }
  };

  // start checkbox

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 0,
    border: "1px solid #E6EFFB",
    width: 16,
    height: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#FFFFFF",
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "transparent",
    "&:before": {
      display: "block",
      width: 12,
      height: 12,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%236E66E6'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
  });

  // Inspired by blueprintjs
  function BpCheckbox(props) {
    return (
      <Index.Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }

  return (
    <div>
      <Index.Box className="auth-flex-main">
        <Index.Box className="auth-left">
          <Index.AuthVideo />
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-inner-main">
            <Index.Box className="auth-inner">
              <Index.Box className="auth-logo-main">
                <img
                  src={Index.Svg.ncxbluelogo}
                  className="auth-logo"
                  alt="auth-logo"
                ></img>
                <Index.Typography className="auth-logo-text">
                  Token
                </Index.Typography>
              </Index.Box>
              <ToastContainer />
              <Index.Typography
                className="auth-title"
                variant="h1"
                component="h1"
              >
                Sign In
              </Index.Typography>
              <Index.Typography className="auth-para" variant="p" component="p">
                with your NCX Account
              </Index.Typography>
              <Index.Box className="input-box auth-field">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    value={email}
                    onChange={onEmailChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        onHandleSubmit();
                      }
                    }}
                    className="form-control"
                    placeholder="Email"
                  />
                </Index.Box>
                <p className="error-text">{errors.emailError}</p>
              </Index.Box>
              <Index.Box className="input-box auth-field">
                <Index.Box className="input-box">
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye form-control"
                      value={password}
                      placeholder="Password"
                      onChange={onPasswordChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onHandleSubmit();
                        }
                      }}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseEvents}
                            edge="end"
                          >
                            {showPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">{errors.passwordError}</p>
                </Index.Box>
              </Index.Box>

              <Index.Box className="auth-checkbox-main">
                <Index.FormControlLabel
                  control={<BpCheckbox />}
                  className="checkbox-lable"
                  label="Remember Me"
                />
                <Index.Typography className="auth-forgot-lable">
                  <Index.Link
                    to="/forgot-password"
                    className="auth-sub-para-span text-decoration"
                  >
                    Forgot Password?
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
              <Index.PrimaryButton
                onClick={onHandleSubmit}
                className="primary-btn auth-btn"
                type="button"
                btnLabel="Sign In"
              />
              <Index.Typography
                className="auth-sub-para"
                variant="p"
                component="p"
              >
                Don't have an account?
                <span className="auth-sub-para-span">
                  <Index.Link
                    to="/sign-up"
                    className="text-decoration auth-sub-para-span"
                    onClick={() => dispatch(SimpleRegister())}
                  >
                    Sign Up here
                  </Index.Link>
                </span>{" "}
              </Index.Typography>
              <Index.AuthFooter />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
