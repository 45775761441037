import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../component/Modal/CustomModal";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

const ITEM_HEIGHT = 48;
export default function Bonus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((store) => store.admin.token);
  const adminType = useSelector((store) => store.admin.adminType);

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editId, setEditId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const onOpenModal = () => {
    setModalOpen(true);
  };
  const onCloseModal = () => {
    setModalOpen(false);
  };

  const fetchData = async () => {
    await DataService.post(
      API.Admin.BONUS_LIST,
      {},
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        const rows = res.data.data.map((item) => {
          return {
            startValue: item?.startPrice,
            endValue: item?.endPrice,
            percentage: item?.bonusPercent,
            id: item?._id,
          };
        });
        setUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const onDelete = async (id) => {
    setIsFetching(true);
    const formData = new URLSearchParams();
    formData.append("id", id);
    await DataService.post(API.Admin.REMOVE_BONUS, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setIsFetching(false);
        toast("User Deleted");
        fetchData();
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };
  const options = [
    <Index.Button
      variant="contained"
      onClick={() => {
        navigate(`/admin/edit-bonus/${editId}`);
      }}
    >
      Edit
    </Index.Button>,
    <Index.Button
      variant="contained"
      onClick={() => {
        onOpenModal();
      }}
    >
      Delete
    </Index.Button>,
  ];
  const columns = [
    {
      field: "startValue",
      headerName: "Start Value",
      width: "200",
    },
    {
      field: "endValue",
      headerName: "End Value",
      width: "200",
    },
    {
      field: "percentage",
      headerName: "Percentage",
      width: "200",
    },

    adminType == 0 && {
      field: "actions",
      headerName: "Action",
      width: "100",
      renderCell: (params) => {
     
        return (
          <>
            <Index.IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setEditId(params?.id);
            
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
            <Index.Menu
              className=""
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open1}
              onClose={handleClose1}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "100px",
                  boxShadow: "none",
                  border: "1px solid rgba(224, 224, 224, 1)",
                  marginTop:"6px"
                },
              }}
            >
              {options.map((option) => (
                <Index.MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={handleClose1}
                  className="menu-option-li"
                >
                  {option}
                </Index.MenuItem>
              ))}
            </Index.Menu>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                 Bonus Percentage
                </Index.Typography>
                <ToastContainer />
                <CustomModal
                  modalOpen={modalOpen}
                  onOpenModal={onOpenModal}
                  onCloseModal={onCloseModal}
                >
                  <Index.DialogTitle
                    style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
                  >
                    Are you sure want to delete the user?
                  </Index.DialogTitle>
                  I<Index.DialogActions>
                    <Index.Button
                      onClick={() => {
                        onCloseModal();
                        onDelete(editId);
                      }}
                      style={{
                        color: "white",
                        backgroundColor: "#00235f",
                        fontFamily: "system-ui",
                        fontSize: 13,
                        padding: 5,
                      }}
                    >
                      Confirm
                    </Index.Button>
                    <Index.Button
                      onClick={onCloseModal}
                      style={{
                        color: "white",
                        backgroundColor: "red",
                        fontSize: 12,
                        fontFamily: "system-ui",
                      }}
                    >
                      Cancel
                    </Index.Button>
                  </Index.DialogActions>
                </CustomModal>

                {adminType == 0 && (
                  <Index.Link
                    to="/admin/add-bonus"
                    className="add-user-btn"
                    style={{
                      textDecoration: "none",
                      fontSize: 15,
                      fontFamily: "Poppins-SemiBold",
                    }}
                  >
                    <Index.AddIcon /> Add
                  </Index.Link>
                )}
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
