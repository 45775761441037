import React from "react";
import Index from "../../../../component/Index";
import { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const Withdrawal = () => {
    const token = useSelector((store) => store.admin.token);
    const [isFetching, setIsFetching] = useState(false);
    const [users, setUsers] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const fetchData = async () => {
        setIsFetching(true);
        await DataService.get(API.Admin.TOTAL_WITHDRAW, {
          headers: {
            auth: token,
          },
        })
          .then((res) => {
            const rows = res.data.data.map((item) => {
              return {
                tranxId: item?.tranxId,
                round: item?.roundId?.phaseName,
                updatedAt: item?.updatedAt,
                createdAt: item?.createdAt,
                clientId: item?.UserId?.ncxUserId,
                userName: item?.UserId.userName,
                email: item.UserId.email,
                withdrawTokens: item?.receivedToken,
                status: item.status,
                tranxonId: item.tranxIdOnNcx,
                usdtSent: item?.receivedUsdt,
                processedBy: item.processedBy?.adminName,
                id: item._id,
              };
            });
            setIsFetching(false);
            setUsers(rows);
          })
          .catch((error) => {
            if (error.response.data.message === "Token not authorized") {
              dispatch(getAdminToken(""));
              dispatch(getAdminType(""));
              dispatch(getAdminData({}));
              navigate("/admin/login")
            }
          });
      };

      
  const columns = [
    {
      field: "tranxId",
      headerName: "TranxId",
      width: "120",
    },
    {
      field: "round",
      headerName: "Round Name",
      width: "150",
    },
    {
      field: "createdAt",
      headerName: " Request Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "clientId",
      headerName: "Client ID",
      width: "100",
    },
    {
      field: "userName",
      headerName: "Name Surname",
      width: "100",
    },
    {
      field: "email",
      headerName: "Email",
      width: "100",
    },
    {
      field: "withdrawTokens",
      headerName: "Request NCXT",
      width: "100",
    },
    {
      field: "status",
      headerName: "Status",
      width: "100",
    },
    {
      field: "tranxonId",
      headerName: "Tranx ID NCX",
      width: "100",
    },
    {
      field: "usdtSent",
      headerName: "NCXT Credited",
      width: "100",
    },
    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "processedBy",
      headerName: "Processed By",
      width: "100",
    },
  ];

      useEffect(() => {
        fetchData();
        
      }, []);
  return (
    <div>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ height: 530, width: "100%" }}>
          <Index.DataGrid
            className="user-data-head"
            rows={users}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Withdrawal;
