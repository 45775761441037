import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import TransactionModal from "../../../../component/Modal/Modal";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <Index.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <Index.MuiAccordionSummary
    expandIcon={<Index.ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(Index.MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Transaction = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [purchaseData, setPurchaseData] = useState([]);
  const [WithdrawalData, setWithdrawalData] = useState([]);
  const [searchTransaction, setSearchTransaction] = useState("");
  const [searchHistory, setSearchHistory] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [filteredDataTransaction, setFilteredDataTransaction] = useState([]);
  const [filteredDataHistory, setFilteredDataHistory] = useState([]);
  const [filteredWithdrawalDataHistory, setFilteredWithdrawalDataHistory] =
    useState([]);
  const [searchWithdrawal, setSearchWithdrawal] = useState("");

  const user = useSelector((store) => store.admin.userData);
  const handleClosePayment = () => setOpenModal(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [expandedTransaction, setExpandedTransaction] = useState("");
  const [expandedHistory, setExpandedHistory] = useState("");
  const [statusTransaction, setStatusTransaction] = useState("");
  const [statusHistory, setStatusHistory] = useState("");
  const [statusWithdrawal, setStatusWithdrawal] = useState("");
  const handleChange = (panel, table) => (event, newExpanded) => {
    if (table === "Tr") {
      setExpandedTransaction(newExpanded ? panel : false);
    }
    if (table === "Hr") {
      setExpandedHistory(newExpanded ? panel : false);
    }
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#6e66e6",
      color: "rgba(255, 255, 255, 1)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding: 10,
      fontFamily: "Urbanist-Bold",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#6e66e6",
    },
  }));
  //table pagination

  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onHandleSearchTransaction = (e) => {
    const filteredGames = transactionData.filter((item) => {
      const topicIncludes = item?.tranxId
        ?.toLowerCase()
        ?.includes(e.target.value?.toLowerCase());
      return topicIncludes;
    });
    setFilteredDataTransaction(filteredGames);
    setSearchTransaction(e.target.value);
  };
  const onHandleSearchPurchaseHistory = (e) => {
    const filteredGames = purchaseData.filter((item) => {
      const topicIncludes = item?.tranxId
        ?.toLowerCase()
        ?.includes(e.target.value?.toLowerCase());
      return topicIncludes;
    });
    setFilteredDataHistory(filteredGames);
    setSearchHistory(e.target.value);
  };
  const onHandleSearchWithdrawalHistory = (e) => {
    const filteredGames = WithdrawalData.filter((item) => {
      const topicIncludes = item?.tranxId
        ?.toLowerCase()
        ?.includes(e.target.value?.toLowerCase());
      return topicIncludes;
    });
    setFilteredWithdrawalDataHistory(filteredGames);
    setSearchWithdrawal(e.target.value);
  };

  const statusFilterTransaction = (e) => {
    const filteredData = transactionData.filter((item) => {
      const topicIncludes = item?.status
        ?.toLowerCase()
        ?.includes(e?.toLowerCase());
      return topicIncludes;
    });
    setFilteredDataTransaction(filteredData);
    setStatusTransaction(e);
  };
  const statusFilterHistory = (e) => {
    const filteredData = purchaseData.filter((item) => {
      const topicIncludes = item?.status
        ?.toLowerCase()
        ?.includes(e?.toLowerCase());
      return topicIncludes;
    });
    setFilteredDataHistory(filteredData);
    setStatusHistory(e);
  };
  const statusFilterWithdraw = (e) => {
    const filteredData = WithdrawalData.filter((item) => {
      const topicIncludes = item?.status
        ?.toLowerCase()
        ?.includes(e?.toLowerCase());
      return topicIncludes;
    });
    setFilteredWithdrawalDataHistory(filteredData);
    setStatusWithdrawal(e);
  };

  const fetchPurchaseHistory = async () => {
    await DataService.get(API.User.Purchase_HISTORY, {
      headers: {
        auth: user?.token,
      },
    }).then((response) => {
      setPurchaseData(response?.data?.data);
      setFilteredDataHistory(response?.data?.data);
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/")
      }   
    });
  };

  const fetchWithdrawalHistory = async () => {
    await DataService.get(API.User.getwithdrawalList, {
      headers: {
        auth: user?.token,
      },
    }).then((response) => {
      setWithdrawalData(response?.data?.data);
      setFilteredWithdrawalDataHistory(response?.data?.data);
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/")
      }   
    });
  };

  const getTransactionHistory = async () => {
    await DataService.get(API.User.TRANSACTION_HISTORY, {
      headers: {
        auth: user?.token,
      },
    }).then((response) => {
      setTransactionData(response?.data?.data);
      setFilteredDataTransaction(response?.data?.data);
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/")
      } 
    });
  };

  useEffect(() => {
    fetchWithdrawalHistory();
    fetchPurchaseHistory();
    getTransactionHistory();
  }, []);

  return (
    <>
      <Index.Header />
      <Index.Box className="page-wrapper">
        <Index.Box className="container">
          <Index.Box className="page-inner-main">
            <Index.Box className="page-left-main">
              <Index.UserSidebar />
            </Index.Box>
            <Index.Box className="page-right-main  transaction-right-main">
              {/* Transactions Section */}
              <Index.Box className="desktop-view">
                <Index.Box className="transaction-main ">
                  <Index.Box className="trans-head-row">
                    <Index.Typography
                      className="trans-title"
                      variant="h2"
                      component="h2"
                    >
                      Transactions
                    </Index.Typography>
                    <Index.Link
                      to="/privatesale"
                      className="text-decoration"
                    >
                      <Index.Box className="trans-btn-main">
                        <Index.Button className="trans-btn">
                          <img src={Index?.Svg?.plus} className="plus-icon" />
                          Add Tokens
                        </Index.Button>
                      </Index.Box>
                    </Index.Link>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                      <Index.Box className="input-box trans-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchTransaction}
                            onChange={onHandleSearchTransaction}
                            className="form-control"
                            placeholder="Transaction ID to quick Search"
                          />
                          <img
                            src={Index?.Svg?.greysearch}
                            className="search-icon"
                          />
                          <Index.Box className="trans-search-main">
                            <Index.Box className="trans-search-btn-main">
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.token1}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.token2}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.settingicon}
                                  className="trans-icons"
                                />
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main scrollbar">
                    {/* Fields */}
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title trans-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                        <br />
                        TOKEN DASHBOARD
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title trans-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        ROUND
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title trans-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        AMOUNT
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title trans-data-col-4"
                        component="h3"
                        variant="h3"
                      >
                        TOKEN
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title trans-data-col-5"
                        component="h3"
                        variant="h3"
                      >
                        BONUS
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title trans-data-col-6"
                        component="h3"
                        variant="h3"
                      >
                        TOTAL
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title trans-data-col-7"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                        <br />
                        ON NCX
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title trans-data-col-8"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>

                    {/* Transaction Data */}
                    {filteredDataTransaction?.length > 0
                      ? filteredDataTransaction?.map((data) => {
                          return (
                            <Index.Box className="trans-data-inner-row">
                              <Index.Box className="trans-data-inner trans-data-col-1">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {data?.tranxId}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  {moment(data?.createdAt).format(
                                    "DD MMM, YYYY h:mm A"
                                  )}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner trans-data-col-2">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {data?.roundId?.phaseName}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  ROUND
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner trans-data-col-3">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {data?.requestUsdt &&
                                  data?.requestUsdt !== 0
                                    ? data?.requestUsdt
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  USDT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner trans-data-col-4">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {data?.receivedToken &&
                                  data?.receivedToken !== 0
                                    ? data?.receivedToken
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner trans-data-col-5">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {data?.bonusToken && data?.bonusToken !== 0
                                    ? data?.bonusToken
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="trans-data-inner trans-data-col-6">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {data?.totalToken && data?.totalToken !== 0
                                    ? data?.totalToken
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner trans-data-col-7">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {data.tranxIdOnNcx}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner trans-data-col-8">
                                <Index.Box className="trans-data-flex trans-data-flex-end">
                                  <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                                    <Index.Button
                                      className={`${
                                        data?.status.toLowerCase() === "pending"
                                          ? "trans-data-btn"
                                          : data?.status.toLowerCase() ===
                                            "approved"
                                          ? "trans-green-btn"
                                          : "trans-red-btn"
                                      } yellow-border-btn`}
                                    >
                                      {data?.status}

                                      <Index.Typography className="trans-span-text">
                                        {data?.status.toLowerCase() ===
                                        "rejected"
                                          ? "Insufficient Funds"
                                          : ""}
                                      </Index.Typography>
                                    </Index.Button>
                                  </Index.Box>
                                  <Index.Box className="dots-drop-main">
                                    <Index.Button className="dots-drop-btn">
                                      <img
                                        src={Index?.Svg?.threedots}
                                        className="thre-dots-icon"
                                        alt="logo"
                                      />
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          );
                        })
                      : "No Data Found"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              {/* Purchase History Section */}
              <Index.Box className="desktop-view">
                <Index.Box className="transaction-main purchase-history ">
                  <Index.Box className="trans-head-row">
                    <Index.Typography
                      className="trans-title"
                      variant="h2"
                      component="h2"
                    >
                      Purchase History
                    </Index.Typography>
                    <Index.Link
                      to="/privatesale"
                      className="text-decoration"
                    >
                      <Index.Box className="trans-btn-main">
                        <Index.Button className="trans-btn">
                          <img src={Index?.Svg?.plus} className="plus-icon" />
                          Add Tokens
                        </Index.Button>
                      </Index.Box>
                    </Index.Link>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                      <Index.Box className="input-box trans-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchHistory}
                            onChange={onHandleSearchPurchaseHistory}
                            className="form-control"
                            placeholder="Transaction ID to quick Search"
                          />
                          <img
                            src={Index?.Svg?.greysearch}
                            className="search-icon"
                          />
                          <Index.Box className="trans-search-main">
                            <Index.Box className="trans-search-btn-main">
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.token1}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.token2}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.settingicon}
                                  className="trans-icons"
                                />
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main scrollbar">
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title purchas-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        ROUND
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        TOKEN
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-4"
                        component="h3"
                        variant="h3"
                      >
                        BONUS
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-5"
                        component="h3"
                        variant="h3"
                      >
                        TOTAL
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-6"
                        component="h3"
                        variant="h3"
                      >
                        MONTHLY
                        <br />
                        TOKEN
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-7"
                        component="h3"
                        variant="h3"
                      >
                        LOCKUP
                        <br />
                        PERIOD
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-8"
                        component="h3"
                        variant="h3"
                      >
                        PAYMENT
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-9"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                        <br />
                        ON NCX
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-10"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>
                    {filteredDataHistory?.length > 0
                      ? filteredDataHistory.map((row) => {
                          return (
                            <Index.Box className="trans-data-inner-row">
                              <Index.Box className="trans-data-inner purchas-data-col-1">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="id"
                                >
                                  {row.tranxId}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  {moment(row?.createdAt).format(
                                    "DD MMM, YYYY h:mm A"
                                  )}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-2">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="round"
                                >
                                  {row?.roundId?.phaseName}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  ROUND
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-3">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="token"
                                >
                                  {row?.receivedToken &&
                                  row?.receivedToken !== 0
                                    ? row?.receivedToken
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-4">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="bonus"
                                >
                                  {row?.bonusToken && row?.bonusToken !== 0
                                    ? row?.bonusToken
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-5">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="total"
                                >
                                  {row?.totalToken && row?.totalToken !== 0
                                    ? row?.totalToken
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-6">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {row?.monthlyReleaseAmount &&
                                  row?.monthlyReleaseAmount &&
                                  row?.monthlyReleaseAmount !== 0
                                    ? row?.monthlyReleaseAmount
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-7">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {row?.roundId?.vestingPeriod &&
                                  row?.roundId?.vestingPeriod !== 0
                                    ? row?.roundId?.vestingPeriod
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  Months
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-8">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="payment"
                                >
                                  {row?.requestUsdt && row?.requestUsdt !== 0
                                    ? row?.requestUsdt
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  USDT
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-9">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="payment"
                                >
                                  {row.tranxIdOnNcx}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-10">
                                <Index.Box className="trans-data-flex trans-data-flex-end">
                                  <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                                    <Index.Button
                                      className={`${
                                        row?.status.toLowerCase() === "pending"
                                          ? "trans-data-btn"
                                          : row?.status.toLowerCase() ===
                                            "approved"
                                          ? "trans-green-btn"
                                          : "trans-red-btn"
                                      } yellow-border-btn`}
                                    >
                                      {row?.status}

                                      <Index.Typography className="trans-span-text">
                                        {row?.status.toLowerCase() ===
                                        "rejected"
                                          ? "Insufficient Funds"
                                          : ""}
                                      </Index.Typography>
                                    </Index.Button>
                                  </Index.Box>
                                  <Index.Box className="dots-drop-main">
                                    <Index.Button className="dots-drop-btn">
                                      <img
                                        src={Index?.Svg?.threedots}
                                        className="thre-dots-icon"
                                        alt="logo"
                                      />
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          );
                        })
                      : "No Data Found"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* Withdrawal History Section */}

              <Index.Box className="desktop-view">
                <Index.Box className="transaction-main withdrow-history ">
                  <Index.Box className="trans-head-row">
                    <Index.Typography
                      className="trans-title"
                      variant="h2"
                      component="h2"
                    >
                      Withdrawal History
                    </Index.Typography>
                    <Index.Link
                      to="/privatesale"
                      className="text-decoration"
                    >
                      <Index.Box className="trans-btn-main">
                        <Index.Button className="trans-btn">
                          <img src={Index?.Svg?.plus} className="plus-icon" />
                          Add Tokens
                        </Index.Button>
                      </Index.Box>
                    </Index.Link>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                      <Index.Box className="input-box trans-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchWithdrawal}
                            onChange={onHandleSearchWithdrawalHistory}
                            className="form-control"
                            placeholder="Transaction ID to quick Search"
                          />
                          <img
                            src={Index?.Svg?.greysearch}
                            className="search-icon"
                          />
                          <Index.Box className="trans-search-main">
                            <Index.Box className="trans-search-btn-main">
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.token1}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.token2}
                                  className="trans-icons"
                                />
                              </Index.Button>
                              <Index.Button className="trans-search-btn">
                                <img
                                  src={Index?.Svg?.settingicon}
                                  className="trans-icons"
                                />
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main scrollbar">
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title purchas-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        TOTAL MONTHLY
                        <br />
                        TOKEN
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        WITHDRAWAL AMOUNT
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-4"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                        <br />
                        ON NCX
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title purchas-data-col-5"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>
                    {filteredWithdrawalDataHistory?.length > 0
                      ? filteredWithdrawalDataHistory.map((row) => {
                          return (
                            <Index.Box className="trans-data-inner-row">
                              <Index.Box className="trans-data-inner purchas-data-col-1">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="id"
                                >
                                  {row.tranxId}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  {moment(row?.createdAt).format(
                                    "DD MMM, YYYY h:mm A"
                                  )}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-2">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                >
                                  {row?.monthlyReleaseAmount &&
                                  row?.monthlyReleaseAmount &&
                                  row?.monthlyReleaseAmount !== 0
                                    ? row?.monthlyReleaseAmount
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="trans-data-inner purchas-data-col-3">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="token"
                                >
                                  {row?.receivedToken &&
                                  row?.receivedToken !== 0
                                    ? row?.receivedToken
                                        ?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                        .split("₹")[1]
                                        .split(".")[0]
                                    : "0.00"}
                                </Index.Typography>
                                <Index.Typography
                                  className="dash-trans-lables"
                                  component="h3"
                                  variant="h3"
                                >
                                  NCXT
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="trans-data-inner purchas-data-col-4">
                                <Index.Typography
                                  className="dash-trans-titles"
                                  component="h3"
                                  variant="h3"
                                  name="payment"
                                >
                                  {row.tranxIdOnNcx}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="trans-data-inner purchas-data-col-5">
                                <Index.Box className="trans-data-flex trans-data-flex-end">
                                  <Index.Box className="yellow-border-btn-main trans-data-btn-main">
                                    <Index.Button
                                      className={`${
                                        row?.status.toLowerCase() === "pending"
                                          ? "trans-data-btn"
                                          : row?.status.toLowerCase() ===
                                            "approved"
                                          ? "trans-green-btn"
                                          : "trans-red-btn"
                                      } yellow-border-btn`}
                                    >
                                      {row?.status}
                                      <span className="trans-span-text">
                                        <Index.Typography className="trans-span-text">
                                          {row?.status.toLowerCase() ===
                                          "rejected"
                                            ? "Insufficient Funds"
                                            : ""}
                                        </Index.Typography>
                                      </span>
                                    </Index.Button>
                                  </Index.Box>
                                  <Index.Box className="dots-drop-main">
                                    <Index.Button className="dots-drop-btn">
                                      <img
                                        src={Index?.Svg?.threedots}
                                        className="thre-dots-icon"
                                        alt="logo"
                                      />
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          );
                        })
                      : "No Data Found"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* Mobile View Section */}
              <Index.Box className="mobile-view">
                <Index.Box className="transaction-main">
                  <Index.Box className="trans-head-row">
                    <Index.Typography
                      className="trans-title"
                      variant="h2"
                      component="h2"
                    >
                      Transactions
                    </Index.Typography>
                    <Index.Link
                      to="/privatesale"
                      className="text-decoration"
                    >
                      <Index.Box className="trans-btn-main">
                        <Index.Button className="trans-btn">
                          <img src={Index?.Svg?.plus} className="plus-icon" />
                          Add
                        </Index.Button>
                      </Index.Box>
                    </Index.Link>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                    <Index.Box className="trans-status-flex">
                        <Index.Typography
                          className={`trans-status-text ${
                            statusTransaction === "pending"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterTransaction("pending")}
                        >
                          Pending
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusTransaction === "approved"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterTransaction("approved")}
                        >
                          Approved
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusTransaction === "bonuses"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterTransaction("bonuses")}
                        >
                          Bonuses
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusTransaction === "" ? "trans-status-highlight" : ""
                          }`}
                          onClick={() => statusFilterTransaction("")}
                        >
                          All
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="input-box trans-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchTransaction}
                            onChange={onHandleSearchTransaction}
                            className="form-control"
                            placeholder="Transaction ID to quick Search"
                          />
                          <img
                            src={Index?.Svg?.greysearch}
                            className="search-icon"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="mobile-trans-serch-main">
                        <Index.Box className="trans-search-btn-main">
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.token1}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.token2}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.settingicon}
                              className="trans-icons"
                            />
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main">
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        TOKENS
                      </Index.Typography>

                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="cuts-accordian-main">
                      {filteredDataTransaction?.length > 0
                        ? filteredDataTransaction?.map((data, index) => {
                            return (
                              <Accordion
                                expanded={
                                  expandedTransaction === `panel${index}`
                                }
                                onChange={handleChange(`panel${index}`, "Tr")}
                                className="cuts-accordian"
                              >
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                  className="cuts-accordian-summary"
                                >
                                  <Index.Box className="trans-data-inner-row">
                                    <Index.Box className="trans-data-inner mob-trans-data-col-1">
                                      <Index.Box className="trans-inner-data-flex">
                                        <Index.Box className="trans-icon-box yellow-icon-box">
                                          <img
                                            src={Index?.Svg?.yellowwatch}
                                            className="transaction-icon"
                                          />
                                        </Index.Box>
                                        <Index.Box className="trans-data-col-flex">
                                          <Index.Typography
                                            className="dash-trans-titles"
                                            component="h3"
                                            variant="h3"
                                          >
                                            {data?.tranxId}
                                          </Index.Typography>
                                          <Index.Typography
                                            className="dash-trans-lables"
                                            component="h3"
                                            variant="h3"
                                          >
                                            {moment(data?.createdAt).format(
                                              "DD MMM, YYYY h:mm A"
                                            )}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="trans-data-inner mob-trans-data-col-2">
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {data?.totalToken &&
                                        data?.totalToken !== 0
                                          ? data?.totalToken
                                              ?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "INR",
                                              })
                                              .split("₹")[1]
                                              .split(".")[0]
                                          : "0.00"}
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        NCXT
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trans-data-inner mob-trans-data-col-3">
                                      <Index.Box className="trans-data-flex trans-data-flex-end">
                                        <Index.Box className="dots-drop-main">
                                          <Index.Button className="dots-drop-btn">
                                            <img
                                              src={Index?.Svg?.threedots}
                                              className="thre-dots-icon"
                                              alt="logo"
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </AccordionSummary>
                                <AccordionDetails className="cuts-accordian-details">
                                  <Index.Box className="trans-data-wrapper">
                                    <Index.Box className="trans-data-wrapper-inner">
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TRANX ID
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.tranxId}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {moment(
                                            transactionData?.createdAt
                                          ).format("DD MMM, YYYY h:mm A")}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          ROUND
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.roundId?.phaseName}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          Round
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          AMOUNT
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.requestUsdt &&
                                          data?.requestUsdt !== 0
                                            ? data?.requestUsdt
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          USDT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TOKEN
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.receivedToken &&
                                          data?.receivedToken !== 0
                                            ? data?.receivedToken
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          BONUS
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.bonusToken &&
                                          data?.bonusToken !== 0
                                            ? data?.bonusToken
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {moment(
                                            transactionData?.createdAt
                                          ).format("DD MMM, YYYY h:mm A")}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TOTAL
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.totalToken &&
                                          data?.totalToken !== 0
                                            ? data?.totalToken
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TRANX ID ON NCX
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {transactionData?.tranxIdOnNcx || "-"}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="yellow-border-btn-main trans-data-btn-main trans-yellow-btn-main">
                                      <Index.Button
                                        className={`${
                                          data?.status.toLowerCase() ===
                                          "pending"
                                            ? "trans-data-btn"
                                            : data?.status.toLowerCase() ===
                                              "approved"
                                            ? "trans-green-btn"
                                            : "trans-red-btn"
                                        } yellow-border-btn`}
                                      >
                                        {data?.status}
                                        <Index.Typography className="trans-span-text">
                                          {data?.status.toLowerCase() ===
                                          "rejected"
                                            ? "Insufficient Funds"
                                            : ""}
                                        </Index.Typography>
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        : "No Data Found"}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="pages-pagination-main">
                  <Index.TablePagination
                    className="pages-pagination"
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box> */}
                {/* <Index.Box className="pages-pagination-main">
                  <Index.Box className="pages-pagination-flex">
                    <Index.Typography className="pages-pagination-text">
                      Show
                    </Index.Typography>
                    <Index.Box className="pages-dropdown-main">
                      <Index.Button className="pages-pagination-btn">
                        5
                      </Index.Button>
                    </Index.Box>
                    <Index.Typography className="pages-pagination-text">
                      OF 05
                    </Index.Typography>
                  </Index.Box>
                </Index.Box> */}
              </Index.Box>

              <Index.Box className="mobile-view">
                <Index.Box className="transaction-main">
                  <Index.Box className="trans-head-row">
                    <Index.Typography
                      className="trans-title"
                      variant="h2"
                      component="h2"
                    >
                      Purchase History
                    </Index.Typography>
                    <Index.Link
                      to="/privatesale"
                      className="text-decoration trans-btn-main"
                    >
                      <Index.Button className="trans-btn">
                        <img src={Index?.Svg?.plus} className="plus-icon" />
                        Add
                      </Index.Button>
                    </Index.Link>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                      <Index.Box className="trans-status-flex">
                        <Index.Typography
                          className={`trans-status-text ${
                            statusHistory === "pending"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterHistory("pending")}
                        >
                          Pending
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusHistory === "approved"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterHistory("approved")}
                        >
                          Approved
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusHistory === "bonuses"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterHistory("bonuses")}
                        >
                          Bonuses
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusHistory === "" ? "trans-status-highlight" : ""
                          }`}
                          onClick={() => statusFilterHistory("")}
                        >
                          All
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="input-box trans-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchHistory}
                            onChange={onHandleSearchPurchaseHistory}
                            className="form-control"
                            placeholder="Transaction ID to quick Search"
                          />
                          <img
                            src={Index?.Svg?.greysearch}
                            className="search-icon"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="mobile-trans-serch-main">
                        <Index.Box className="trans-search-btn-main">
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.token1}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.token2}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.settingicon}
                              className="trans-icons"
                            />
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main">
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        TOKENS
                      </Index.Typography>

                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="cuts-accordian-main">
                      {filteredDataHistory?.length > 0
                        ? filteredDataHistory?.map((data, index) => {
                            return (
                              <Accordion
                                expanded={expandedHistory === `panel${index}`}
                                onChange={handleChange(`panel${index}`, "Hr")}
                                className="cuts-accordian"
                              >
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                  className="cuts-accordian-summary"
                                >
                                  <Index.Box className="trans-data-inner-row">
                                    <Index.Box className="trans-data-inner mob-trans-data-col-1">
                                      <Index.Box className="trans-inner-data-flex">
                                        <Index.Box className="trans-icon-box yellow-icon-box">
                                          <img
                                            src={Index?.Svg?.yellowwatch}
                                            className="transaction-icon"
                                          />
                                        </Index.Box>
                                        <Index.Box className="trans-data-col-flex">
                                          <Index.Typography
                                            className="dash-trans-titles"
                                            component="h3"
                                            variant="h3"
                                          >
                                            {data?.tranxId}
                                          </Index.Typography>
                                          <Index.Typography
                                            className="dash-trans-lables"
                                            component="h3"
                                            variant="h3"
                                          >
                                            {moment(data?.createdAt).format(
                                              "DD MMM, YYYY h:mm A"
                                            )}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="trans-data-inner mob-trans-data-col-2">
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {data?.token && data?.token !== 0
                                          ? data?.token
                                              ?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "INR",
                                              })
                                              .split("₹")[1]
                                              .split(".")[0]
                                          : "0.00"}
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        NCXT
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trans-data-inner mob-trans-data-col-3">
                                      <Index.Box className="trans-data-flex trans-data-flex-end">
                                        <Index.Box className="dots-drop-main">
                                          <Index.Button className="dots-drop-btn">
                                            <img
                                              src={Index?.Svg?.threedots}
                                              className="thre-dots-icon"
                                              alt="logo"
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </AccordionSummary>
                                <AccordionDetails className="cuts-accordian-details">
                                  <Index.Box className="trans-data-wrapper">
                                    <Index.Box className="trans-data-wrapper-inner">
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TRANX ID
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.tranxId}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {moment(data?.createdAt).format(
                                            "DD MMM, YYYY h:mm A"
                                          )}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          ROUND
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.roundId?.phaseName}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {moment(
                                            data?.roundId?.createdAt
                                          ).format("DD MMM, YYYY h:mm A")}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TOKENS
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.token && data?.token !== 0
                                            ? data?.token
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          Bonus
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.bonus && data?.bonus !== 0
                                            ? data?.bonus
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TOTAL
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.totalReward &&
                                          data?.totalReward !== 0
                                            ? data?.totalReward
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          MONTHLY TOKEN
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.UserId?.monthlyToken &&
                                          data?.UserId?.monthlyToken !== 0
                                            ? data?.UserId?.monthlyToken
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          LOCKUP PERIOD
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.UserId?.locked}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          MONTHS
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          PAYMENT
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.receivedAmount &&
                                          data?.receivedAmount &&
                                          data?.receivedAmount !== 0
                                            ? data?.receivedAmount
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          USDT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TRANX ID ON NCX
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="yellow-border-btn-main trans-data-btn-main trans-yellow-btn-main">
                                      <Index.Button
                                        className={`${
                                          data?.status.toLowerCase() ===
                                          "pending"
                                            ? "trans-data-btn"
                                            : data?.status.toLowerCase() ===
                                              "approved"
                                            ? "trans-green-btn"
                                            : "trans-red-btn"
                                        } yellow-border-btn`}
                                      >
                                        {data?.status}

                                        <Index.Typography className="trans-span-text">
                                          {data?.status.toLowerCase() ===
                                          "rejected"
                                            ? "Insufficient Funds"
                                            : ""}
                                        </Index.Typography>
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        : "No Data Found"}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="pages-pagination-main">
                  <Index.TablePagination
                    className="pages-pagination"
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box> */}
                {/* <Index.Box className="pages-pagination-main">
                  <Index.Box className="pages-pagination-flex">
                    <Index.Typography className="pages-pagination-text">
                      Show
                    </Index.Typography>
                    <Index.Box className="pages-dropdown-main">
                      <Index.Button className="pages-pagination-btn">
                        5
                      </Index.Button>
                    </Index.Box>
                    <Index.Typography className="pages-pagination-text">
                      OF 05
                    </Index.Typography>
                  </Index.Box>
                </Index.Box> */}
              </Index.Box>

              <Index.Box className="mobile-view">
                <Index.Box className="transaction-main">
                  <Index.Box className="trans-head-row">
                    <Index.Typography
                      className="trans-title"
                      variant="h2"
                      component="h2"
                    >
                      Withdrawal History
                    </Index.Typography>
                    <Index.Link
                      to="/privatesale"
                      className="text-decoration trans-btn-main"
                    >
                      <Index.Button className="trans-btn">
                        <img src={Index?.Svg?.plus} className="plus-icon" />
                        Add
                      </Index.Button>
                    </Index.Link>
                  </Index.Box>

                  <Index.Box className="trans-search-flex">
                    <Index.Box className="trans-search-box">
                      <Index.Box className="trans-status-flex">
                        <Index.Typography
                          className={`trans-status-text ${
                            statusWithdrawal === "pending"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterWithdraw("pending")}
                        >
                          Pending
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusWithdrawal === "approved"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterWithdraw("approved")}
                        >
                          Approved
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusWithdrawal === "bonuses"
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterWithdraw("bonuses")}
                        >
                          Bonuses
                        </Index.Typography>
                        <Index.Typography
                          className={`trans-status-text ${
                            statusWithdrawal === ""
                              ? "trans-status-highlight"
                              : ""
                          }`}
                          onClick={() => statusFilterWithdraw("")}
                        >
                          All
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="input-box trans-field">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            value={searchWithdrawal}
                            onChange={onHandleSearchWithdrawalHistory}
                            className="form-control"
                            placeholder="Transaction ID to quick Search"
                          />
                          <img
                            src={Index?.Svg?.greysearch}
                            className="search-icon"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="mobile-trans-serch-main">
                        <Index.Box className="trans-search-btn-main">
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.token1}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.token2}
                              className="trans-icons"
                            />
                          </Index.Button>
                          <Index.Button className="trans-search-btn">
                            <img
                              src={Index?.Svg?.settingicon}
                              className="trans-icons"
                            />
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="trans-data-main">
                    <Index.Box className="trans-data-head-row">
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-1"
                        component="h3"
                        variant="h3"
                      >
                        TRANX ID
                      </Index.Typography>
                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-2"
                        component="h3"
                        variant="h3"
                      >
                        TOKENS
                      </Index.Typography>

                      <Index.Typography
                        className="trans-col-title mob-trans-data-col-3"
                        component="h3"
                        variant="h3"
                      >
                        STATUS
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="cuts-accordian-main">
                      {filteredWithdrawalDataHistory?.length > 0
                        ? filteredWithdrawalDataHistory?.map((data, index) => {
                            return (
                              <Accordion
                                expanded={expandedHistory === `panel${index}`}
                                onChange={handleChange(`panel${index}`, "Hr")}
                                className="cuts-accordian"
                              >
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                  className="cuts-accordian-summary"
                                >
                                  <Index.Box className="trans-data-inner-row">
                                    <Index.Box className="trans-data-inner mob-trans-data-col-1">
                                      <Index.Box className="trans-inner-data-flex">
                                        <Index.Box className="trans-icon-box yellow-icon-box">
                                          <img
                                            src={Index?.Svg?.yellowwatch}
                                            className="transaction-icon"
                                          />
                                        </Index.Box>
                                        <Index.Box className="trans-data-col-flex">
                                          <Index.Typography
                                            className="dash-trans-titles"
                                            component="h3"
                                            variant="h3"
                                          >
                                            {data?.tranxId}
                                          </Index.Typography>
                                          <Index.Typography
                                            className="dash-trans-lables"
                                            component="h3"
                                            variant="h3"
                                          >
                                            {moment(data?.createdAt).format(
                                              "DD MMM, YYYY h:mm A"
                                            )}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="trans-data-inner mob-trans-data-col-2">
                                      <Index.Typography
                                        className="dash-trans-titles"
                                        component="h3"
                                        variant="h3"
                                      >
                                        {data?.token && data?.token !== 0
                                          ? data?.token
                                              ?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "INR",
                                              })
                                              .split("₹")[1]
                                              .split(".")[0]
                                          : "0.00"}
                                      </Index.Typography>
                                      <Index.Typography
                                        className="dash-trans-lables"
                                        component="h3"
                                        variant="h3"
                                      >
                                        NCXT
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trans-data-inner mob-trans-data-col-3">
                                      <Index.Box className="trans-data-flex trans-data-flex-end">
                                        <Index.Box className="dots-drop-main">
                                          <Index.Button className="dots-drop-btn">
                                            <img
                                              src={Index?.Svg?.threedots}
                                              className="thre-dots-icon"
                                              alt="logo"
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </AccordionSummary>
                                <AccordionDetails className="cuts-accordian-details">
                                  <Index.Box className="trans-data-wrapper">
                                    <Index.Box className="trans-data-wrapper-inner">
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TRANX ID
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.tranxId}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {moment(data?.createdAt).format(
                                            "DD MMM, YYYY h:mm A"
                                          )}
                                        </Index.Typography>
                                      </Index.Box>

                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TOTAL MONTHLY TOKEN
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.UserId?.monthlyToken &&
                                          data?.UserId?.monthlyToken !== 0
                                            ? data?.UserId?.monthlyToken
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>

                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          WITHDRAWAL AMOUNT
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.receivedAmount &&
                                          data?.receivedAmount &&
                                          data?.receivedAmount !== 0
                                            ? data?.receivedAmount
                                                ?.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "INR",
                                                })
                                                .split("₹")[1]
                                                .split(".")[0]
                                            : "0.00"}
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-lables"
                                          component="h3"
                                          variant="h3"
                                        >
                                          NCXT
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trans-collpase-row">
                                        <Index.Typography
                                          className="trans-col-title"
                                          component="h3"
                                          variant="h3"
                                        >
                                          TRANX ID ON NCX
                                        </Index.Typography>
                                        <Index.Typography
                                          className="dash-trans-titles"
                                          component="h3"
                                          variant="h3"
                                        >
                                          {data?.tranxIdOnNcx || "-"}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="yellow-border-btn-main trans-data-btn-main trans-yellow-btn-main">
                                      <Index.Button
                                        className={`${
                                          data?.status.toLowerCase() ===
                                          "pending"
                                            ? "trans-data-btn"
                                            : data?.status.toLowerCase() ===
                                              "approved"
                                            ? "trans-green-btn"
                                            : "trans-red-btn"
                                        } yellow-border-btn`}
                                      >
                                        {data?.status}

                                        <Index.Typography className="trans-span-text">
                                          {data?.status.toLowerCase() ===
                                          "rejected"
                                            ? "Insufficient Funds"
                                            : ""}
                                        </Index.Typography>
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        : "No Data Found"}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="pages-pagination-main">
                  <Index.TablePagination
                    className="pages-pagination"
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box> */}
                {/* <Index.Box className="pages-pagination-main">
                  <Index.Box className="pages-pagination-flex">
                    <Index.Typography className="pages-pagination-text">
                      Show
                    </Index.Typography>
                    <Index.Box className="pages-dropdown-main">
                      <Index.Button className="pages-pagination-btn">
                        5
                      </Index.Button>
                    </Index.Box>
                    <Index.Typography className="pages-pagination-text">
                      OF 05
                    </Index.Typography>
                  </Index.Box>
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Footer />

        <Index.Modal
          open={openModal}
          onClose={() => handleClosePayment()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="private-payment-modal modal"
        >
          <TransactionModal />
        </Index.Modal>
      </Index.Box>
    </>
  );
};

export default Transaction;
