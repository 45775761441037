import React from "react";
import Index from "../../../../component/Index";
import { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CustomModal from "../../../../component/Modal/CustomModal";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

const ITEM_HEIGHT = 48;

const User = () => {
  const token = useSelector((store) => store.admin.token);
  const adminType = useSelector((store) => store.admin.adminType);
  const [isFetching, setIsFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const open1 = Boolean(anchorEl);

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onOpenModal = () => {
    setModalOpen(true);
  };

  const checklevel = (item) => {
    let res = "";

    if (item === true) {
      res = "Purchase";
      return res;
    } else if (item === false) {
      res = "Non Purchase";
      return res;
    } else {
      res = "-";
      return res;
    }
  };
  const onDelete = async (id) => {
    setIsFetching(true);
    const formData = new URLSearchParams();
    formData.append("id", id);
    await DataService.post(API.Admin.USER_REMOVE, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setIsFetching(false);
        toast("User Deleted");
        fetchData();
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const fetchData = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.GET_USER_LIST,
      {},
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        setIsFetching(false);

        const rows = res.data.data.map((item) => {
          let re = checklevel(item.kycOnNcx);

          return {
            userName: item?.userName,
            ncxUserId: item?.ncxUserId ? item?.ncxUserId : "-",
            id: item._id,
            email: item.email,
            countryId: item?.countryId?.countryName,
            purchasedOnNcx: item?.purchasedOnNcx == true ? "Purchase" : "Not Purchase",
            Register: item.registeredOnNcx === true ? "Yes" : "No",
            twoFactor: item.twoFactor === true ? "Yes" : "No",
            countryIds: item?.countryId?._id,
          };
        });

        setUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const options = [
    <Index.Button
      className="admin-menu-btn"
      variant="contained"
      onClick={() => {
        navigate(`/admin/view`, { state: { id: editId } });
      }}
    >
      View
    </Index.Button>,
    <>
      {adminType == 0 && (
        <>
          <Index.Button
            className="admin-menu-btn"
            variant="contained"
            onClick={() => {
              navigate(`/admin/edit-user/${editId}`);
            }}
          >
            Edit
          </Index.Button>
        </>
      )}
    </>,
    <>
      {adminType == 0 && (
        <>
          <Index.Button
            className="admin-menu-btn"
            variant="contained"
            onClick={() => {
              onOpenModal();
            }}
          >
            Delete
          </Index.Button>
        </>
      )}
    </>,
  ];

  const columns = [
    {
      field: "ncxUserId",
      headerName: "Client ID",
      width: "200",
    },
    {
      field: "userName",
      headerName: "User Name",
      width: "200",
    },
    {
      field: "email",
      headerName: "Email",
      width: "250",
    },
    {
      field: "countryId",
      headerName: "country",
      width: "150",
    },
    {
      field: "purchasedOnNcx",
      headerName: "Purchased On Ncx",
      width: "110",
    },
    {
      field: "Register",
      headerName: "Register On Ncx",
      width: "120",
    },
    {
      field: "twoFactor",
      headerName: "2FA",
      width: "80",
    },
    {
      field: "actions",
      headerName: "Action",
      width: "70",
      renderCell: (params) => {
        return (
          <>
            <Index.IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setEditId(params?.id);
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
            <Index.Box className="admin-menu-box">
              <Index.Menu
                className="admin-menu-main"
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "100px",
                    boxShadow: "none",
                    border: "1px solid rgba(224, 224, 224, 1)",
                    marginTop: "6px",
                  },
                }}
              >
                {options.map((option) => (
                  <Index.MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose1}
                    className="menu-option-li"
                  >
                    {option}
                  </Index.MenuItem>
                ))}
              </Index.Menu>
            </Index.Box>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <CustomModal
        modalOpen={modalOpen}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
      >
        <Index.DialogTitle
          style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
        >
          Are you sure want to delete the user?
        </Index.DialogTitle>
        <Index.DialogActions>
          <Index.Button
            onClick={() => {
              onCloseModal();
              onDelete(editId);
            }}
            style={{
              color: "white",
              backgroundColor: "#00235f",
              fontFamily: "system-ui",
              fontSize: 13,
              padding: 5,
            }}
          >
            Confirm
          </Index.Button>
          <Index.Button
            onClick={onCloseModal}
            style={{
              color: "white",
              backgroundColor: "red",
              fontSize: 12,
              fontFamily: "system-ui",
            }}
          >
            Cancel
          </Index.Button>
        </Index.DialogActions>
      </CustomModal>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ height: 530, width: "100%" }}>
          <Index.DataGrid
            className="user-data-head"
            rows={users}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default User;
