import React from 'react'
import Index from '../../../../component/Index'
import { Outlet } from 'react-router-dom';
export default function Dashboard() {
  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
        </Index.Box>
      </Index.Box>
      <Outlet/>
    </div >
  )
}
