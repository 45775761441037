import React from "react";
import Index from "../../Index";
import { Link } from "@mui/material";

export default function Footer() {
  return (
    <>
      <Index.Box className="footer-sec">
        <Index.Box className="footer-inner-main">
          <Index.Box className="container">
            <Index.Box className="footer-logo-box mobile-footer-logo">
                      <img src={Index.Svg.ncxprimarylogoicon} className="footer-logo" />
                      <Index.Typography className="token-logo-text white-token-text">
                        Token
                      </Index.Typography>
            </Index.Box>



            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 6",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="footer-col">
                          <Index.Typography
                            className="
                                                      footer-title"
                          >
                            Products
                          </Index.Typography>
                          <Index.Box className="footer-nav-main">
                            <Index.List className="footer-nav-ul">
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/buy-crypto" className="footer-nav-link">
                                Buy Crypto
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://trade.ncx.cx/" className="footer-nav-link">
                                Spot Trading
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/derivatives" className="footer-nav-link">
                                Crypto Derivatives
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/markets" className="footer-nav-link">
                                Markets
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://bsc.ncx.cx/swap" className="footer-nav-link">
                                Cross-Chain DEX
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/ncx-card" className="footer-nav-link">
                                NCX Card
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/en/ncx-token" className="footer-nav-link">
                                NCX DeFi Wallet
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/ncx-token" className="footer-nav-link">
                                NCX Token
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/en/ncx-token" className="footer-nav-link">
                                Equities Trading
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/en/ncx-token" className="footer-nav-link">
                                Forex Trading
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/en/ncx-token" className="footer-nav-link">
                                Commodities Trading
                                </a>
                              </Index.ListItem>
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 6",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="footer-col">
                          <Index.Typography className="footer-title">
                          Services
                          </Index.Typography>
                          <Index.Box className="footer-nav-main">
                            <Index.List className="footer-nav-ul">
                              <Index.ListItem className="footer-nav-li">
                                <a href="#" className="footer-nav-link">
                                NCX Insights
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/rewards" className="footer-nav-link">
                                Rewards Program
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/market-makers" className="footer-nav-link">
                                Market Makers
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/institutional" className="footer-nav-link">
                                Institutional Services
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/ncx-otc" className="footer-nav-link">
                                OTC
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/ncx-ambassador" className="footer-nav-link">
                                NCX Ambassador
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/faq" className="footer-nav-link">
                                FAQ
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://my.ncx.cx/en/helpdesk/chat" className="footer-nav-link">
                                Support
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="https://ncx.cx/market-insights" className="footer-nav-link">
                                Media & Press
                                </a>
                              </Index.ListItem>
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 6",
                          sm: "span 12",
                          md: "span 6",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="footer-col">
                          <Index.Typography className="footer-title">
                            Legal
                          </Index.Typography>
                          <Index.Box className="footer-nav-main">
                            <Index.List className="footer-nav-ul">
                              <Index.ListItem className="footer-nav-li">
                                <a
                                  href="https://ncx.cx/user-agreement"
                                  className="footer-nav-link"
                                >
                                  User Agreement
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a
                                  href="https://ncx.cx/kyc-aml-policy"
                                  className="footer-nav-link"
                                >
                               AML & KYC Policy
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a
                                  href="https://ncx.cx/privacy-policy"
                                  className="footer-nav-link"
                                >
                                Privacy Policy
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a
                                  href="https://ncx.cx/api-agreement"
                                  className="footer-nav-link"
                                >
                                  API Documentation
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a
                                  href="https://ncx.cx/cookie-policy"
                                  className="footer-nav-link"
                                >
                                  Cookie Policy
                                </a>
                              </Index.ListItem>
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 6",
                          sm: "span 12",
                          md: "span 4",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="footer-col bottom-footer-col">
                          <Index.Typography className="footer-title">
                            Apps
                          </Index.Typography>
                          <Index.Box className="footer-nav-main">
                            <Index.List className="footer-nav-ul">
                              <Index.ListItem className="footer-nav-li">
                                <a href="#" className="footer-nav-link">
                                NCX App on App Store
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="#" className="footer-nav-link">
                                NCX App on Google Play Store 
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="#" className="footer-nav-link">
                                NCX cTrader  on App Store 
                                </a>
                              </Index.ListItem>
                              <Index.ListItem className="footer-nav-li">
                                <a href="#" className="footer-nav-link">
                                NCX cTrader on Google Play Store 
                                </a>
                              </Index.ListItem>
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="footer-col footer-social-col">
                    <Index.Box className="footer-logo-box desktop-footer-logo">
                      <img src={Index.Svg.ncxprimarylogoicon} className="footer-logo" />
                      <Index.Typography className="token-logo-text white-token-text">
                        Token
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="footer-playstore-main">
                      <Index.Box className="footer-play-left footer-social-title-box">
                        <Index.Typography className="Footer-mobile-text">
                          Mobile App
                        </Index.Typography>
                        <img
                          src={Index.Svg.footerplaystore}
                          className="footer-playstore-img"
                        />
                        <img
                          src={Index.Svg.footerapplestore}
                          className="footer-playstore-img"
                        />
                      </Index.Box>
                      <Index.Box className="footer-paly-right">
                        <img
                          src={Index.Png.footerplaystorescanner}
                          className="footer-scanner-img"
                        />
                        <Index.Typography className="Footer-scanner-text">
                          Scan to Download
                          <br />
                          NCX App
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="footer-social-inner-box">
                      <Index.Box className="footer-social-media footer-social-title-box">
                        <Index.Typography className="Footer-mobile-text">
                          Follow us
                        </Index.Typography>
                        <Index.Box className="footer-social-main">
                          <Index.List className="footer-social-ul">
                            <Index.ListItem className="footer-social-li">
                              <Link
                                className="footer-social-link"
                                href="https://www.instagram.com/ncx.cx/"
                                target={"_blank"}
                              >
                                <img
                                  src={Index.Svg.footerinstagram}
                                  className="footer-social-icon"
                                />
                              </Link>
                            </Index.ListItem>
                            <Index.ListItem className="footer-social-li">
                              <Link
                                className="footer-social-link"
                                href="https://telegram.org/"
                                target={"_blank"}
                              >
                                <img
                                  src={Index.Svg.footershare}
                                  className="footer-social-icon"
                                />
                              </Link>
                            </Index.ListItem>
                            <Index.ListItem className="footer-social-li">
                              <Link
                                className="footer-social-link"
                                href="https://twitter.com/NCX_CX"
                                target={"_blank"}
                              >
                                <img
                                  src={Index.Svg.footertwitter}
                                  className="footer-social-icon"
                                />
                              </Link>
                            </Index.ListItem>
                          </Index.List>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="footer-social-title-box">
                        <Index.Typography className="Footer-mobile-text">
                          Sign Up for Updates
                        </Index.Typography>
                        <Index.Box className="footer-email-main">
                          <Index.Box className="input-box footer-email-field">
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Enter your email"
                              />
                            </Index.Box>
                            <Index.Button className="footer-go-btn">
                              <img
                                src={Index.Svg.whiterighterrow}
                                className="footer-right-errow-img"
                              />
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="footer-privacy-main">
              <Index.Typography className="footer-para-top">
                By using any services provided by NCX Platform you agree with
                NCX Digital Exchange L.L.C.{" "}
                <span className="footer-highligh-text">
                  <a
                    className="footer-highligh-text"
                    href="https://ncx.cx/user-agreement"
                  >
                    User Agreement
                  </a>
                  ,{" "}
                  <a
                    className="footer-highligh-text"
                    href="https://ncx.cx/kyc-aml-policy"
                  >
                    AML and KYC Policy
                  </a>
                </span>{" "}
                and{" "}
                <span className="footer-highligh-text">
                  <a
                    className="footer-highligh-text"
                    href="https://ncx.cx/privacy-policy"
                  >
                    Privacy Policy
                  </a>{" "}
                  <br />
                </span>
              </Index.Typography>
              <Index.Typography className="footer-para">
                NCX does not offer Services to residents of USA, Afghanistan,
                American Samoa, the Bahamas, Botswana, Democratic People's
                Republic of Korea, Ethiopia,
                <br />
                Ghana, Guam, Iran, Iraq, Libya, Panama, Puerto Rico, Samoa,
                Saudi Arabia, Sri Lanka, Syria, Trinidad and Tobago, Tunisia, US
                Virgin Islands, Yemen
              </Index.Typography>

              <Index.Typography className="footer-para footer-register-text">
                NCX Digital Exchange L.L.C., Company Reg. Number - 2477 LLC 2022
                © 2022-2023 All rights reserved.
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
