import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { useNavigate, useParams } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import UseFetch from "../../../../UseFetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../../config/Api";
import message from "../../../../ValidationMessage.json";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

export default function AddUser() {
  const [singleData, setSingleData] = useState({});
  const { editId } = useParams();
  

  useEffect(() => {
    const initialValues = {
      userName: editId !== undefined ? singleData?.userName : "",
      ncxUserId: editId !== undefined ? singleData?.ncxUserId : "",
      email: editId !== undefined ? singleData?.email : "",
      totalReward:0,
      refCommission:
        editId !== undefined && singleData?.commission !== 0
          ? singleData?.commission
          : "",
      country: editId !== undefined ? singleData?.countryId?._id : "",
      register:
        editId !== undefined
          ? singleData?.registeredOnNcx === true
            ? "true"
            : "false"
          : "true",
      kyc:
        editId !== undefined
          ? singleData?.purchasedOnNcx === true
            ? "true"
            : "false"
          : "true",

      fa:
        editId !== undefined
          ? singleData?.twoFactor === true
            ? "true"
            : "false"
          : "true",
    };
    setFields(initialValues);
  }, [singleData]);

  const [isFetching, setIsFetching] = useState(false);
  const [fields, setFields] = useState({});
  const [kyc, setKyc] = useState();
  const [error, setError] = useState({
    nameError: "",
    emailError: "",
    countryError: "",
    idOnNcxError: "",
  });


  const token = useSelector((store) => store.admin.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countriesData = UseFetch(API.Admin.GET_COUNTRY_LIST);

  const getSingleData = async () => {
    const formData = new URLSearchParams();
    formData.append("id", editId);
    await DataService.post(API.Admin.GET_USER_LIST, formData, {
      headers: {
        auth: token,
      },
    }).then((response) => {
      setSingleData(response?.data?.data);
      console.log(response?.data?.data)
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/admin/login")
      }
    });
  };

  useEffect(() => {
    getSingleData();
  }, []);

  const validateEmail = (email) => {
    const check = /\S+@\S+\.\S+/.test(email);
    if (!check) {
      setError({
        ...error,
        emailError: message.VALID_EMAIL_ADDRESS,
      });
      return true;
    } else {
      setError({
        ...error,
        emailError: "",
      });
      return false;
    }
  };

  const validateName = (name) => {
    const letters = /[a-z]/.test(name);
    const capital = /[A-Z]/.test(name);
    if (!letters) {
      setError({
        ...error,
        nameError: message.USER_LOWERCASE,
      });
      return true;
    } else if (!capital) {
      setError({
        ...error,
        nameError: message.USER_UPPERCASE,
      });
      return true;
    } else {
      setError({
        ...error,
        nameError: "",
      });
      return false;
    }
  };

  const validateCountry = (country) => {
    if (country === null || country === undefined || country === "") {
      setError({
        ...error,
        countryError: message.SELECT_COUNTRY,
      });
      return true;
    } else {
      setError({
        ...error,
        countryError: "",
      });
      return false;
    }
  };

  const validateIdOnNcx = (ncxUserId) => {
    if (ncxUserId === null || ncxUserId === undefined || ncxUserId === "") {
      setError({
        ...error,
        idOnNcxError: "Please enter id on ncx",
      });
      return true;
    } else {
      setError({
        ...error,
        idOnNcxError: "",
      });
      return false;
    }
  };

  const onHandleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "userName") {
      validateName(value);
    }
    if (name === "email") {
      validateEmail(value);
    }
    if (name === "country") {
      validateCountry(value);
    }

    if (name === "ncxUserId") {
      validateIdOnNcx(value);
    }

    if (name === "kyc") {
      setKyc(value);
    }

    setFields({
      ...fields,
      [name]: value,
    });
  };

  const onAddEdit = async () => {
    const isValid = /[a-z]/.test(fields.userName);
    setIsFetching(true);
    if (
      validateName(fields.userName) ||
      validateCountry(fields.country) ||
      validateEmail(fields.email) ||
      !isValid
    ) {
      onEmpty();
    } else {
      const formData = new URLSearchParams();
      formData.append("email", fields.email);
      formData.append("userName", fields.userName);
      formData.append("refferalReward", fields.totalReward);
      formData.append("commission", fields.refCommission);
      formData.append("countryId", fields.country);
      formData.append("twoFactor", fields.fa);
      formData.append("purchasedOnNcx", fields.kyc);
      formData.append("registeredOnNcx", fields.register);
      formData.append("ncxUserId", fields.ncxUserId);
      formData.append("registerType", 0);
      if (editId !== undefined) {
        formData.append("id", editId);
      }
      await DataService.post(API.Admin.USER_ADD_EDIT, formData, {
        headers: {
          auth: token,
        },
      })
        .then((res) => {
          setIsFetching(false);
          if (editId !== undefined) {
            toast("User updated ");
            setTimeout(() => {
              navigate("/admin/user");
            }, 1500);
          } else {
            toast(res?.data?.message);
          }
        })
        .catch((error) => {
          setIsFetching(false);
          toast.error(error.response.data.message);
          if (error.response.data.message === "Token not authorized") {
            dispatch(getAdminToken(""));
            dispatch(getAdminType(""));
            dispatch(getAdminData({}));
            navigate("/admin/login")
          }
        });
    }
  };

  const onEmpty = () => {
    if (fields.userName === "") {
      setError((prev) => ({ ...prev, nameError: "Please Enter Username" }));
    }
    if (fields.email === "") {
      setError((prev) => ({ ...prev, emailError: "Please Enter email" }));
    }
    if (fields.country === "") {
      setError((prev) => ({
        ...prev,
        countryError: "Please select country name",
      }));
    }
  };

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Typography
                className="admin-page-title  add-user-title add-user-page-title"
                component="h2"
                variant="h2"
              >
                {editId === undefined ? "Add" : "Edit"} User
              </Index.Typography>
              <ToastContainer />
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Username
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="userName"
                            id="user"
                            className="form-control"
                            value={fields.userName}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.nameError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            name="email"
                            className="form-control"
                            onChange={onHandleInputChange}
                            value={fields.email}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.emailError}</p>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Total Reward
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            type="number"
                            name="totalReward"
                            id="user"
                            className="form-control"
                            value={fields.totalReward}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Commission
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="refCommission"
                            inputProps={{ maxLength: 50 }}
                            id="user"
                            className="form-control"
                            value={fields.refCommission}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Select country name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control">
                              <Index.Select
                                className="dropdown-select "
                                value={fields?.country ? fields?.country : ""}
                                name="country"
                                onChange={onHandleInputChange}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                {countriesData.data.map((option) => (
                                  <Index.MenuItem
                                    key={option._id}
                                    value={option._id}
                                  >
                                    {option.countryName}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                        <p className="error-text">{error.countryError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Purchase On NCX
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control">
                              <Index.Select
                                className="dropdown-select "
                                value={fields.kyc ? fields.kyc : "" }
                                onChange={onHandleInputChange}
                                name="kyc"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <Index.MenuItem
                                  value="true"
                                  className="menuitem"
                                >
                                  Purchase
                                </Index.MenuItem>
                                <Index.MenuItem
                                  value="false"
                                  className="menuitem"
                                >
                                  Non Purchase
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                        {/* <p className="error-text">{error.kycError}</p> */}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Registered on NCX
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control">
                              <Index.Select
                                className="dropdown-select "
                                value={fields?.register ? fields?.register : ""}
                                onChange={onHandleInputChange}
                                name="register"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <Index.MenuItem
                                  value="true"
                                  className="menuitem"
                                >
                                  Yes
                                </Index.MenuItem>
                                <Index.MenuItem
                                  value="false"
                                  className="menuitem"
                                >
                                  No
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          2FA
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control">
                              <Index.Select
                                className="dropdown-select "
                                value={fields?.fa ? fields?.fa : ""}
                                onChange={onHandleInputChange}
                                name="fa"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <Index.MenuItem
                                  value="true"
                                  className="menuitem"
                                >
                                  Yes
                                </Index.MenuItem>
                                <Index.MenuItem
                                  value="false"
                                  className="menuitem"
                                >
                                  No
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Id On NCX
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="ncxUserId"
                            id="user"
                            className="form-control"
                            value={fields.ncxUserId}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        {/* <p className="error-text">{error.idOnNcxError}</p> */}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="submit-btn-main border-btn-main">
                        <Index.Button
                          className="submit-user-btn border-btn"
                          disableRipple
                          type="button"
                          onClick={onAddEdit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
