import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import { getAdminData, getAdminToken, getAdminType, getRoundData } from "../../../../redux/features/slices/Admin/AdminSlice";
import Index from "../../../Index";
import { useNavigate } from "react-router-dom";

export default function PrivateSaleFinal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((store) => store.admin.userToken);
  const roundList = useSelector((store) => store?.admin?.roundData);
  const [rounds, setRounds] = useState([]);
  const [purchased, setPurchased] = useState([]);

  const reverseArray = (arr) => {
    var reverseArrayData = Object.assign([], arr);
    return reverseArrayData;
  };

  useEffect(() => {
    setRounds(roundList);
  }, [roundList]);

  const getSaleRoundList = async () => {
    await DataService.get(API.User.getPrivateSale, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        dispatch(getRoundData(response?.data?.data));
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };
  const getPurchased = async () => {
    await DataService.get(API.User.totalPurchased, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setPurchased(response.data.data);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  useEffect(() => {
    getSaleRoundList();
    getPurchased();
  }, []);

  return (
    <>
      <Index.Header />
      <Index.Box className="page-wrapper">
        <Index.Box className="container">
          <Index.Box className="page-inner-main">
            <Index.Box className="page-left-main">
              <Index.UserSidebar />
            </Index.Box>
            <Index.Box className="page-right-main private-sale-final-right-main">
              <Index.Box className="private-sale-final-main">
                <Index.Box className="private-sale-final-row">
                  {reverseArray(rounds)?.map((data) => {
                    const purchasedData = purchased.find(
                      (p) => p.roundId === data._id
                    );
                    return (
                      <Index.Box
                        className="private-sale-final-col"
                        key={data?._id}
                      >
                        <Index.PriceCard
                          data={data}
                          purchased={purchasedData}
                        />
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Footer />
    </>
  );
}
