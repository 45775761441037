import React, { useState } from "react";
import Index from "../../../../component/Index";
import { useEffect } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import moment from "moment";
import CustomModal from "../../../../component/Modal/CustomModal";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const PendingWithdrawal = () => {
  const adminType = useSelector((store) => store.admin.adminType);
  const token = useSelector((store) => store.admin.token);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingWithdrawUsers, setPendingWithdrawUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hideshowAccept, sethideShowAccept] = useState(false);
  const [hideshowReject, sethideShowReject] = useState(false);
  const [editId, setEditId] = useState("");
  const [status, setStatus] = useState("");
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCloseModal2 = () => {
    setModalOpen2(false);
  };
  const onOpenModal = () => {
    setModalOpen(true);
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onOpenModal2 = () => {
    setModalOpen2(true);
  };
  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const onAccept = async (id) => {
    setIsFetching(true);
    const formData = new URLSearchParams();
    formData.append("requestId", id);
    formData.append("status", status);
    formData.append("tranxIdOnNcx", transactionId);
    await DataService.post(API.Admin.ACCEPT_WITHDRAWAL, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        if (status === "approved") {
          toast("Withdrawal accepted");
        } else {
          toast("Withdrawal rejected");
        }
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const fetchPendingData = async () => {
    setIsFetching(true);
    await DataService.get(API.Admin.PENDING_WITHDRAW, {
      headers: {
        auth: token,
      },
    })
      .then((res) => {
        const rows = res.data.data.map((item) => {
          return {
            tranxId: item?.tranxId,
            round: item?.roundId?.phaseName,
            updatedAt: item?.updatedAt,
            createdAt: item?.createdAt,
            clientId: item?.UserId?.ncxUserId,
            userName: item?.UserId.userName,
            email: item.UserId.email,
            withdrawTokens: item?.receivedToken,
            status: item.status,
            tranxonId: item.tranxIdOnNcx,
            usdtSent: item?.receivedUsdt,
            processedBy: item.processedBy?.adminName,
            id: item._id,
          };
        });
        setIsFetching(false);
        setPendingWithdrawUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };
  const pendingWithdrawColumns = [
    {
      field: "tranxId",
      headerName: "TranxId",
      width: "120",
    },
    {
      field: "round",
      headerName: "Round Name",
      width: "150",
    },
    {
      field: "createdAt",
      headerName: " Request Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "clientId",
      headerName: "Client ID",
      width: "100",
    },
    {
      field: "userName",
      headerName: "Name Surname",
      width: "100",
    },
    {
      field: "email",
      headerName: "Email",
      width: "100",
    },
    {
      field: "withdrawTokens",
      headerName: "Request NCXT",
      width: "100",
    },
    {
      field: "status",
      headerName: "Status",
      width: "100",
    },

    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },

    {
      field: "actions",
      headerName: "Action",
      width: "70",
      renderCell: (params) => {
        return (
          <>
            <Index.IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setEditId(params?.id);
                onOpenModal2();
                sethideShowAccept(false);
                sethideShowReject(false);
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    fetchPendingData();
  }, []);

  return (
    <div>
      <CustomModal
        modalOpen={modalOpen2}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal2}
      >
        <Index.Box className="">
          <Index.Box className="transcation-popup">
            <Index.TextField
              size="small"
              variant="standard"
              value={transactionId}
              onChange={(e) => {
                setTransactionId(e.target.value);
              }}
              placeholder="Enter Transcation Id"
            />
          </Index.Box>
        </Index.Box>
        <Index.DialogActions>
          {adminType == 0 && (
            <>
              <Index.Button
                variant="contained"
                value={status}
                onChange={onStatusChange}
                onClick={() => {
                  setStatus("approved");
                  // onAcceptButtonClick();
                  sethideShowAccept(true);
                  sethideShowReject(false);
                }}
              >
                Accept
              </Index.Button>
            </>
          )}
          {adminType == 0 && (
            <>
              <Index.Button
                value={status}
                variant="contained"
                onChange={onStatusChange}
                onClick={() => {
                  setStatus("rejected");
                  // onRejectButtonClick();
                  sethideShowReject(true);
                  sethideShowAccept(false);
                }}
              >
                Reject
              </Index.Button>
            </>
          )}
        </Index.DialogActions>
        {hideshowAccept && (
          <>
            <Index.Box>
              <Index.DialogTitle
                style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
              >
                Are you sure want to Accept?
              </Index.DialogTitle>
              <Index.DialogActions>
                <Index.Button
                  onClick={() => {
                    onCloseModal2();
                    onAccept(editId);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "#00235f",
                    fontFamily: "system-ui",
                    fontSize: 13,
                    // padding: 5,
                    minwidth: 64,
                    padding: "6px 16px",
                  }}
                >
                  Confirm
                </Index.Button>
                <Index.Button
                  onClick={() => {
                    sethideShowAccept(false);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    fontSize: 12,
                    fontFamily: "system-ui",
                    minwidth: 64,
                    padding: "6px 16px",
                  }}
                >
                  Cancel
                </Index.Button>
              </Index.DialogActions>
            </Index.Box>
          </>
        )}

        {hideshowReject && (
          <>
            <Index.Box>
              <Index.DialogTitle
                style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
              >
                Are you sure want to Reject?
              </Index.DialogTitle>
              <Index.DialogActions>
                <Index.Button
                  onClick={() => {
                    onCloseModal2();
                    onAccept(editId);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "#00235f",
                    fontFamily: "system-ui",
                    fontSize: 13,
                    padding: 5,
                  }}
                >
                  Confirm
                </Index.Button>
                <Index.Button
                  onClick={() => {
                    sethideShowReject(false);
                  }}
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    fontSize: 12,
                    fontFamily: "system-ui",
                  }}
                >
                  Cancel
                </Index.Button>
              </Index.DialogActions>
            </Index.Box>
          </>
        )}
      </CustomModal>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ height: 530, width: "100%" }}>
          <Index.DataGrid
            className="user-data-head"
            rows={pendingWithdrawUsers}
            columns={pendingWithdrawColumns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default PendingWithdrawal;
