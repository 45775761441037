import React from "react";
import Index from "../../container/Index";

const DashbordCard = (props) => {
  return (
      <Index.Box className={props.className}>
        <Index.Box className="dash-card-flex">
          <Index.Box className="dash-icon-main">
            <img src={Index.Svg.ncxlogoicon} className="dash-icons" />
          </Index.Box>
          <Index.Box className="dash-icon-text-main">
            <Index.Typography
              className="dash-icon-text"
              component="h3"
              variant="h3"
            >
              {props.mainLable}
            </Index.Typography>
            <Index.Typography
              className="dash-icon-price"
              component="h3"
              variant="h3"
            >
              {props.lableData}
              <span className="dash-span-text">NCXT</span>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="dash-card-content-flex">
          <Index.Box className="dash-card-content">
            <Index.Typography
              className="dash-price-text"
              component="h3"
              variant="h3"
            >
              {props.lokedData}
            </Index.Typography>
            <Index.Typography
              className="dash-price-label"
              component="h3"
              variant="h3"
            >
              LOCKED
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dash-card-content">
            <Index.Typography
              className="dash-price-text"
              component="h3"
              variant="h3"
            >
              0.00
            </Index.Typography>
            <Index.Typography
              className="dash-price-label"
              component="h3"
              variant="h3"
            >
              AVAILABLE
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dash-card-content dash-flex-end-content">
            <Index.Link to={props.btnLabelPath} className="text-decoration">
              <Index.WhiteBorderButton
                className="white-border-btn dash-btn"
                btnLabel={props.btnLabel}
              />
            </Index.Link>
          </Index.Box>
        </Index.Box>
      </Index.Box>
  );
};

export default DashbordCard;
