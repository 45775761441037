import React,{useEffect,useState} from 'react'
import { DataService } from './config/DataService'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAdminData, getAdminToken, getAdminType } from './redux/features/slices/Admin/AdminSlice'

const UseFetch = (url) => {
  const [data, setData] = useState([])
  const token = useSelector((store)=>store.admin.token);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const getData = async()=>{
    DataService.get(url,  {
      headers:{
        auth:token
      }
    }).then((response)=>{
      setData(response.data.data)
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/")
      }
    })
  }
  useEffect(()=>{
    getData();
  },[])
  return {
    data,
    getData
  }
}

export default UseFetch