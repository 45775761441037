import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { useNavigate, useParams } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../../config/Api";
import message from "../../../../ValidationMessage.json";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";



export default function AddRoadMap() {
  const [singleData, setSingleData] = useState({});
  const { editId } = useParams();
  const token = useSelector((store) => store.admin.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [isFetching, setIsFetching] = useState(false);
  const [fields, setFields] = useState({});
  const [error, setError] = useState({
    startValueError: "",
    endValueError: "",
    percentageError: "",
  });


  const getSingleData = async () => {
    if (editId !== undefined) {
      const formData = new URLSearchParams();
      formData.append("id", editId);
      await DataService.post(API.Admin.BONUS_LIST, formData, {
        headers: {
          auth: token,
        },
      }).then((response) => {
        setSingleData(response?.data?.data);
      }).catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
    }
  };

  useEffect(() => {
    getSingleData();
  }, [editId]);

  useEffect(() => {
    const initialValues = {
      startValue: editId !== undefined ? singleData?.startPrice : "",
      endValue: editId !== undefined ? singleData?.endPrice : "",
      percentage: editId !== undefined ? singleData?.bonusPercent : "",
    };
  
    
    setFields(initialValues);
  }, [singleData]);

  const validateStartValue = (startValue) => {
    if (!startValue) {
      setError({
        ...error,
        startValueError: message.START_VALUE_ENTER,
      });
      return true;
    } else {
      setError({
        ...error,
        startValueError: "",
      });
      return false;
    }
  };

  const validateEndValue = (endValue) => {
    if (!endValue) {
      setError({
        ...error,
        endValueError: message.END_VALUE_ENTER,
      });
      return true;
    } else {
      setError({
        ...error,
        endValueError: "",
      });
      return false;
    }
  };

  const validatePercentage = (percentage) => {
    if (!percentage) {
      setError({
        ...error,
        percentageError: message.ENTER_PERCENTAGE,
      });
      return true;
    } else {
      setError({
        ...error,
        percentageError: "",
      });
      return false;
    }
  };

  const onHandleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "startValue") {
      validateStartValue(value);
    }
    if (name === "endValue") {
      validateEndValue(value);
    }
    if (name === "percentage") {
      validatePercentage(value);
    }

    setFields({
      ...fields,
      [name]: value,
    });
  };

  const onAddEdit = async () => {
    setIsFetching(true);
    if (
      validateStartValue(fields.startValue) ||
      validateEndValue(fields.endValue) ||
      validatePercentage(fields.percentage)
    ) {
      onEmpty();
    } else {
      const formData = new URLSearchParams();
      formData.append("startPrice", fields.startValue);
      formData.append("endPrice", fields.endValue);
      formData.append("bonusPercent", fields.percentage);
      if (editId !== undefined) {
        formData.append("id", editId);
      }
      await DataService.post(API.Admin.ADD_EDIT_BONUS, formData, {
        headers: {
          auth: token,
        },
      })
        .then((res) => {
          setIsFetching(false);
          if (editId !== undefined) {
            toast("Bonus updated ");
          } else {
            toast("Bonus added ");
          }
          setTimeout(() => {
            navigate("/admin/bonus");
          }, 1500);
        })
        .catch((error) => {
          setIsFetching(false);
          toast.error(error.response.data.message);
          if (error.response.data.message === "Token not authorized") {
            dispatch(getAdminToken(""));
            dispatch(getAdminType(""));
            dispatch(getAdminData({}));
            navigate("/admin/login")
          }
        });
    }
  };

  const onEmpty = () => {
   
    if (fields.startValue === "") {
      setError((prev) => ({
        ...prev,
        startValueError: "Please Enter start value",
      }));
    }
    if (fields.endValue === "") {
      setError((prev) => ({
        ...prev,
        endValueError: "Please Enter end value",
      }));
    }
    if (fields.percentage === "") {
      setError((prev) => ({
        ...prev,
        percentageError: "Please Enter percentage",
      }));
    }
  };

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
                style={{ marginBottom: 24 }}
              >
                {editId === undefined ? "Add" : "Edit"} Bonus Percentage
              </Index.Typography>
              <ToastContainer />
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Start value
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="startValue"
                            id="user"
                            className="form-control"
                            value={fields.startValue}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.startValueError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          End Value
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="endValue"
                            id="user"
                            className="form-control"
                            value={fields.endValue}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.endValueError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Percentage
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="percentage"
                            id="user"
                            className="form-control"
                            value={fields.percentage}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.percentageError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="submit-btn-main border-btn-main">
                        <Index.Button
                          className="submit-user-btn border-btn"
                          disableRipple
                          type="button"
                          onClick={onAddEdit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
