import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { useNavigate, useParams } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../../config/Api";
import message from "../../../../ValidationMessage.json";
import { useDispatch, useSelector } from "react-redux";
import NativeSelect from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    color: "rgba(0, 0, 0, 0.6)",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Poppins-Regular"].join(","),
    "&:focus": {
      borderRadius: 3,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function AddRoadMap() {
  const [singleData, setSingleData] = useState({});
  const [color, setColor] = useState("64046f7c4c88fcc1aed9a1cf");
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState({
    descError: "",
    monthError: "",
    quaterError: "",
  });
  const [fields, setFields] = useState({});
  const { editId } = useParams();
  const token = useSelector((store) => store.admin.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  const getSingleData = async () => {
    const formData = new URLSearchParams();
    formData.append("id", editId);
    await DataService.post(API.Admin.GET_ROAD_LIST, formData, {
      headers: {
        auth: token,
      },
    }).then((response) => {
      setSingleData(response?.data?.data);
    }).catch((error)=>{
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/admin/login")
      }
    });
  };

  useEffect(() => {
    getSingleData();
  }, [editId]);
  

  useEffect(() => {
      const initialValues = {
        description: editId !== undefined ? singleData?.description : "",
        month: editId !== undefined ? singleData?.month : "",
        quater: editId !== undefined ? singleData?.quater : "",
      };
      
      if (editId) {
       setColor(singleData?.colorId?._id);
      }
      setFields(initialValues);
  }, [singleData]);


  const validateDescription = (name) => {
    if (!name) {
      setError({
        ...error,
        descError: message.ROADMAP_DESC,
      });
      return true;
    } else {
      setError({
        ...error,
        descError: "",
      });
      return false;
    }
  };

  const validateMonth = (month) => {
    const regex =  /^[A-Z]{3}-[A-Z]{3}\s\d{4}$/.test(month);
    if (!month) {
      setError({
        ...error,
        monthError: message.ROADMAP_MONTH,
      });
      return true;
    } else if(!regex) {
      setError({
        ...error,
        monthError: message.VALID_MONTH,
      });
    }else {
      setError({
        ...error,
        monthError: "",
      });
      return false;
    }
  };

  const validateQuater = (quater) => {
    const regex =  /^Q[1-9]|1[0-2]$/.test(quater);
    if (!quater) {
      setError({
        ...error,
        quaterError: message.ROADMAP_QUATER,
      });
      return true;
    } else if (!regex){
      setError({
        ...error,
        quaterError: "Please enter valid Quater",
      });
    }else {
      setError({
        ...error,
        quaterError: "",
      });
      return false;
    }
  };

  const onHandleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "description") {
      validateDescription(value);
    }
    if (name === "month") {
      validateMonth(value);
    }
    if (name === "quater") {
      validateQuater(value);
    }

    setFields({
      ...fields,
      [name]: value,
    });
  };

  const onAddEdit = async () => {
    setIsFetching(true);
    if (
      validateDescription(fields.description) ||
      validateMonth(fields.month) ||
      validateQuater(fields.quater)
    ) {
      onEmpty();
    } else {
      const formData = new URLSearchParams();
      formData.append("description", fields.description);
      formData.append("month", fields.month);
      formData.append("quater", fields.quater);
      formData.append("colorId", color);
      if (editId !== undefined) {
        formData.append("id", editId);
      }
      await DataService.post(API.Admin.ROAD_ADD_EDIT, formData, {
        headers: {
          auth: token,
        },
      })
        .then((res) => {
          setIsFetching(false);
          if (editId !== undefined) {
            toast("RoadMap updated ");
          } else {
            toast("Road Map added ");
          }
          setTimeout(() => {
            navigate("/admin/road-map");
          }, 1500);
        })
        .catch((error) => {
          setIsFetching(false);
          toast.error(error.response.data.message);
          if (error.response.data.message === "Token not authorized") {
            dispatch(getAdminToken(""));
            dispatch(getAdminType(""));
            dispatch(getAdminData({}));
            navigate("/admin/login")
          }
        });
    }
  };

  const onEmpty = () => {
    if (fields.description === "") {
      setError((prev) => ({ ...prev, descError: "Please Enter Description" }));
    }
    if (fields.month === "") {
      setError((prev) => ({ ...prev, monthError: "Please Enter Month" }));
    }
    if (fields.quater === "") {
      setError((prev) => ({
        ...prev,
        quaterError: "Please Enter Quater",
      }));
    }
  };


  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
                style={{ marginBottom: 24 }}
              >
                {editId === undefined ? "Add" : "Edit"} Road Map
              </Index.Typography>
              <ToastContainer />
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Description
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="description"
                            id="user"
                            className="form-control"
                            value={fields.description}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.descError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Month
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="month"
                            id="user"
                            className="form-control"
                            value={fields.month}
                            inputProps={{maxLength : 12}}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.monthError}</p>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Quater
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            name="quater"
                            className="form-control"
                            inputProps={{ maxLength: 2 }}
                            onChange={onHandleInputChange}
                            value={fields.quater}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.quaterError}</p>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Choose color of Road Map
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.FormControl className="form-control">
                            <NativeSelect
                              fullWidth
                              id="fullWidth"
                              name="colorId"
                              value={color}
                              onChange={handleColorChange}
                              input={<BootstrapInput />}
                            >
                              {/* <option aria-label="None" value="" /> */}
                              <option value={"64046f7c4c88fcc1aed9a1cf"}>
                                Blue
                              </option>
                              <option value={"64046fb34c88fcc1aed9a1d0"}>
                                White
                              </option>
                            </NativeSelect>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="submit-btn-main border-btn-main">
                        <Index.Button
                          className="submit-user-btn border-btn"
                          disableRipple
                          type="button"
                          onClick={onAddEdit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
