import React, { useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import message from "../../../../ValidationMessage.json";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../../../common/AuthValidation";

export default function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = useState(false);
  const [errors, setErrors] = useState({
    passwordError: "",
    confirmPasswordError: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors((prev) => ({
      ...prev,
      passwordError: validatePassword(e.target.value),
    }));
  };

  const onSamePassword = (condition) => {
    if (!condition) {
      setErrors((prev) => ({
        ...prev,
        confirmPasswordError: message.SAME_PASSWORD,
      }));
    } else {
      setErrors((prev) => ({ ...prev, confirmPasswordError: "" }));
    }
  };
  const onConfirmPasswordChange = (e) => {
    onSamePassword(e.target.value === password);
    setConfirmPassword(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfrimPassword = () =>
    setShowConfrimPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmitError = () => {
    if (confirmPassword.length < 1) {
      setErrors((prev) => ({
        ...prev,
        confirmPasswordError: message.ENTER_CONFIRM_PASSWORD,
      }));
    } else {
      setErrors((prev) => ({ ...prev, confirmPasswordError: "" }));
    }
    if (password.length < 1) {
      setErrors((prev) => ({ ...prev, passwordError: message.ENTER_PASSWORD }));
    } else {
      setErrors((prev) => ({ ...prev, passwordError: "" }));
    }
  };

  const onSubmit = async () => {
    if (password.length > 0 && confirmPassword.length > 0) {
      const formData = new URLSearchParams();
      formData.append("forgotId", params.id);
      formData.append("newPassword", password);
      await DataService.post(API.User.ResetPassword, formData)
        .then((response) => {
          toast(response.data.message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      onSubmitError();
    }
  };

  return (
    <div>
      <Index.Box className="auth-flex-main">
        <Index.Box className="auth-left">
          <Index.AuthVideo />
        </Index.Box>
        <Index.Box className="auth-right">
          <Index.Box className="auth-inner-main">
            <Index.Box className="auth-inner">
              <Index.Box className="auth-logo-main">
                <img
                  src={Index.Svg.ncxbluelogo}
                  className="auth-logo"
                  alt="auth-logo"
                ></img>
                <Index.Typography className="auth-logo-text">
                  Token
                </Index.Typography>
              </Index.Box>
              <ToastContainer />
              <Index.Typography
                className="auth-title"
                variant="h1"
                component="h1"
              >
                Reset your password
              </Index.Typography>
              <Index.Box className="input-box auth-field">
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye"
                      value={password}
                      onChange={onPasswordChange}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">{errors.passwordError}</p>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box auth-field">
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Confirm Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye"
                      value={confirmPassword}
                      onChange={onConfirmPasswordChange}
                      id="outlined-adornment-password"
                      type={showConfrimPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfrimPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfrimPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">{errors.confirmPasswordError}</p>
                </Index.Box>
              </Index.Box>
              <Index.Box className="auth-forget-flex">
                <Index.PrimaryButton
                  className="primary-btn auth-btn"
                  btnLabel="Reset Password"
                  type="button"
                  onClick={onSubmit}
                />
                <Index.Typography
                  className="auth-sub-para"
                  variant="p"
                  component="p"
                >
                  <span className="auth-sub-para-span">
                    <Index.Link to="/sign-in" className="back-to-login">
                      Back to Login
                    </Index.Link>
                  </span>
                  {""}
                </Index.Typography>
              </Index.Box>
              <Index.AuthFooter />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
