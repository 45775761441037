import React from "react";
import Index from "../../../Index";

const UserDetails = ({ userInfo }) => {
  return (
    <>
      <Index.Box className="user-head-title">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          User Details
        </Index.Typography>
      </Index.Box>

      <Index.Box
        className="add-user-data-main"
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Username"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={userInfo?.userName ? userInfo?.userName : "-"}
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Email"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={userInfo?.email ? userInfo?.email : "-"}
            
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Total Token"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={userInfo?.purchaseToken ? userInfo?.purchaseToken: "0"}
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="REF Commission"
            InputProps={{ readOnly: true }}
            variant="filled"
            value={`${userInfo?.commission} %`} 
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Two Factor"
            InputProps={{ readOnly: true }}
            defaultValue="No"
            variant="filled"
            value={userInfo?.twoFactor ? "Yes" : "No"}
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Airdrop Reward"
            InputProps={{ readOnly: true }}
            variant="filled"
            value={userInfo?.welcomeReward ? userInfo?.welcomeReward:"0"}
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Referral Reward"
            InputProps={{ readOnly: true }}
            variant="filled"
            value={userInfo?.refferalReward ?userInfo?.refferalReward:"0"}
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Referral Code"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={userInfo?.referCode ? userInfo?.referCode: "-"}
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="ID on NCX"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={userInfo?.ncxUserId ? userInfo?.ncxUserId :"-"}
          />
        </Index.FormControl>

        <Index.FormControl style={{ width: "30%" }} variant="filled">
          <Index.TextField
            id="filled-helperText"
            label="Total Bonus"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={userInfo?.bonus ? userInfo?.bonus :"-"}
          />
        </Index.FormControl>

      </Index.Box>
    </>
  );
};

export default UserDetails;
