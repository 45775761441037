import {useEffect,useState} from 'react'
import { DataService } from './config/DataService'

const UseCountry = (url) => {
  const [data, setData] = useState([])

  const getData = async()=>{
    DataService.get(url).then((response)=>{
      setData(response.data.data)
    }).catch((error)=>{
      console.log('error', error)
    })
  }
  useEffect(()=>{
    getData();
  },[])
  return {
    data,
    getData
  }
}

export default UseCountry