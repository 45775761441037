import React from "react";
import Index from "../Index";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
};
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 21,
  height: 21,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#6E66E6",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 21,
    height: 21,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#6E66E6",
  },
});
function BpRadio(props) {
  return (
    <Index.Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const TransactionModal = (props) => {

  return (
    <div>
      
        <Index.Box sx={style} className="payment-modal-inner modal-inner">
          <Index.Typography
            className="payment-modal-title"
            variant="h6"
            component="h2"
          >
            Payment Process
          </Index.Typography>
          <Index.Typography
            className="payment-modal-para payment-modal-para-1"
            variant="h6"
            component="h2"
          >
            Please make payment of{" "}
            <span className="modal-highlight-text">20.00 USDT </span>to receive{" "}
            <span className="modal-highlight-text">14,800 NCX</span> token
            including bonus{" "}
            <span className="modal-highlight-text">4,800 NCX</span> token.
          </Index.Typography>
          <Index.Typography
            className="payment-modal-para payment-modal-para-2"
            variant="h6"
            component="h2"
          >
            You can choose any of following payment method to make your payment.
            The tokens balance will appear in your account after successful
            payment.
          </Index.Typography>
          <Index.Typography
            className="payment-modal-title"
            variant="h6"
            component="h2"
          >
            Payment method
          </Index.Typography>

          <Index.Box className="payment-modal-flex display-flex">
            <Index.Box className="payment-modal-radio-box payment-modal-radio-box1 radio-box-main">
              <Index.RadioGroup
                defaultValue="female"
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                className="payment-radio radio"
              >
                <Index.FormControlLabel
                  value="female"
                  control={<BpRadio />}
                  label="Pay with USDT-TRC20"
                  className="payment-radio-lable radio-lable"
                />
              </Index.RadioGroup>
            </Index.Box>
            <Index.Box className="payment-modal-radio-box payment-modal-radio-box2  radio-box-main">
              <Index.RadioGroup
                defaultValue="female"
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                className="payment-radio radio"
              >
                <Index.FormControlLabel
                  value="female"
                  control={<BpRadio />}
                  label="Pay with my Balance on NCX"
                  className="payment-radio-lable radio-lable"
                />
              </Index.RadioGroup>
            </Index.Box>
          </Index.Box>

          <Index.Typography
            className="payment-condition-para"
            variant="p"
            component="p"
          >
            *Payment gateway may charge you a processing fee.
          </Index.Typography>

          <Index.Box className="payment-checkbox-main display-flex">
            <Index.FormControlLabel
              control={<Index.Checkbox />}
              className="checkbox-lable"
            />
            <Index.Typography className="payment-checkbox-lable">
              I hereby agree to the token purchase agreement and token sale
              team.
            </Index.Typography>
          </Index.Box>

          <Index.PrimaryButton
            className="primary-btn payment-buy-token-btn"
            btnLabel="Buy Token Now"
          />

          <Index.Box className="display-flex payment-error-flex">
            <img src={Index.Svg.primaryerror} className="primary-error-icon" />
            <Index.Typography
              className="payment-error-para"
              variant="p"
              component="p"
            >
              Payment wallet address will appear or redirect you for payment
              after the order is placed.
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      
    </div>
  );
};

export default TransactionModal;
