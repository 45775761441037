const API = {
  Admin: {
    AdminLogin: "admin/login",
    Forgot_Password: "admin/forgot-password",
    GET_USER_LIST: "admin/getuser-list",
    GET_SINGLE_USER_INFO: "admin/fetch-personal-info",
    PURCHASE_HISTORY: "admin/get-purchase",
    BONUS_LIST: "admin/get-list-bonus",
    ADD_EDIT_BONUS: "admin/add-edit-bonus",
    REMOVE_BONUS: "admin/remove-bonus",
    WITHDRAWLE_HISTORY: "admin/get-withdraw",
    TRANSACTION_HISTORY: "admin/get-transaction",
    AIRDROP: "admin/get-all-list",
    REFFERAL: "admin/get-reffered-list",
    PRESALE_ROUND: "admin/get-private-sale",
    GET_ROAD_LIST: "admin/get-roadmap",
    REMOVE_ROAD_LIST: "admin/remove-roadmap",
    USER_REGISTER: "user/register",
    USER_ADD_EDIT: "admin/add-edit-user",
    ROAD_ADD_EDIT: "admin/create-roadmap",
    USER_REMOVE: "admin/remove-user",
    GET_COUNTRY_LIST: "admin/getcountry-list",
    GET_USER_COUNTRY_LIST: "admin/get-country-list",
    ADMIN_PRIVATE_SALE: "admin/create-Private-Sale",
    ADMIN_PRIVATE_SALE_REMOVE: "admin/remove-Private-Sale",
    CLAIM_AIRDROP: "admin/get-airdrop-list",
    GET_BALANCE_ADMIN: "admin/get-balance",
    GET_ID: "admin/get-private-sale-id",
    TOTAL_PURCHASE: "admin/purchase-history",
    TOTAL_WITHDRAW: "admin/withdraw-history",
    ACCEPT_WITHDRAWAL: "admin/withdraw-action",
    PENDING_WITHDRAW: "admin/get-withdraw-request",
    PENDING_DEPOSITE:"admin/get-purchase-request",
    ACCEPT_DEPOSITE:"/admin/purchase-action",
    BLOCKED_USER_LIST:"/admin/get-unblock-userlist",
    UNBLOCK_USER:"/admin/action-unblock-user",
    CHANGE_PASSWORD :"/admin/change-password",
    EDIT_PROFILE:"/admin/edit-profile",
    PROFILE_DATA:"/admin/get-profile",
    GET_GENERAL_SETTINGS:"/admin/generalSettings",
    EDIT_REFFERAL_AND_AIRDROP:"/admin/generalSettings/refferal-airdrop-tokens",
  },
  User: {
    RegisterUser: "user/signup-user",
    GET_GENERAL_SETTINGS:"/admin/generalSettings",

    EmailVerify: "user/email-verification",
    UserForgotPassword: "user/forgot-password",
    ResetPassword: "user/reset-password",
    UserLogin: "user/login",
    editUserProfile: "/user/edit-user",
    changeUserPassword: "/user/change-password",
    generateTwoFactor: "/user/generate-2FA",
    VerifyTwoFactor: "/user/verify-2FA",
    DisableTwoFactor: "/user/disable-2FA",
    getReferalUsers: "/user/get-refferal-users",
    getReferalDashboardUsers: "/user/get-refferal-dashboard-users",
    GET_BALANCE: "/user/get-balance",
    getPrivateSale: "/admin/get-Private-Sale",
    getUpcomingPrivateSale: "/user/get-current-upcoming-private-sale",
    postWalletId: "/user/add-wallet-address",
    TRANSACTION_HISTORY: "/user/get-transaction",
    Purchase_HISTORY: "/user/get-deposite",
    getWalletData: "/user/get-total-purchase-token",
    getBonusList: "/user/get-list-bonus",
    getwithdrawalList: "/user/get-withdraw",
    requestOtp: "/user/request-otp",
    resendOtp: "/user/resend-otp",
    requestWithdrawToken: "/user/request-withdraw-token",
    sendRequestOTP: "/user/request-otp",
    ResendOTP: "/user/resend-otp",
    totalPurchased:"/user/get-total-purchase-token",
    purchaseToken:"/user/request-purchase-token",
    privatesaleWallet:"/user/current-purchase-data"
  },
};
export { API };
