import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";



export const LoginAdmin = createAsyncThunk(
  "users/loginAdmin",
  async (userData) => {
    const response = await DataService.post(API.Admin.AdminLogin, userData);
    return response.data;
  }
);

export const registerAdmin = createAsyncThunk(
  "users/registerAdmin",
  async (userData) => {
    const response = await DataService.post(API.Admin.USER_REGISTER, userData);
    return response.data;
  }
);

export const forgotPasswordAdmin = createAsyncThunk(
  "users/forgotPasswordAdmin",
  async (data) => {
    const response = await DataService.post(API.User.UserForgotPassword, data);
    return response.data;
  }
);

export const getUser = createAsyncThunk("users/getUser", async () => {
  const response = await DataService.post(
    API.Admin.GET_USER_LIST,
    {},
    (Headers = {
      auth: localStorage.getItem("token"),
    })
  );
  return response.data;
});



export const getGeneralSettingsFromRedux = createAsyncThunk("users/getGeneralSettings" , async()=>{
  const response = await DataService.post(API.User.GET_GENERAL_SETTINGS);
  return response.data.data[0];
})


// export const getGeneralSettings = async() =>{
//   const response = await DataService.post(API.User.GET_GENERAL_SETTINGS);
//   return response.data.data[0];
// }