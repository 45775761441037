import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

const ITEM_HEIGHT = 48;

export default function AirdroipAndReferrals() {
  const token = useSelector((store) => store.admin.token);
  const [anchorEl, setAnchorEl] = useState(null);
  const [refferalId, setRefferalId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const open1 = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const fetchData = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.AIRDROP,
      {},
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        setIsFetching(false);
        const rows = res.data.data.map((item) => {
          // let re = checklevel(item.kycOnNcx);
          return {
            name: item?.userName,
            ncxUserId: item?.ncxUserId ? item?.ncxUserId : "-",
            id: item._id,
            email: item?.email,
            countryId: item?.countryId?.countryName,
            purchasedOnNcx: item?.purchasedOnNcx === true ? "Purchase" : "Not Purchase",
            Register: item?.registeredOnNcx === true ? "Yes" : "No",
            twoFactor: item?.twoFactor === true ? "Yes" : "No",
            countryIds: item?.countryId?._id,
            refferalId: item?.refferalId,
          };
        });
        setUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const options = [
    <Index.Button
      variant="contained"
      onClick={() => {
        navigate(`/admin/refferals`, { state: { id: refferalId } });
      }}
    >
      Referrals
    </Index.Button>,
  ];
  const columns = [
    {
      field: "ncxUserId",
      headerName: "Client ID",
      width: "120",
    },
    {
      field: "name",
      headerName: "User Name",
      width: "150",
    },
    {
      field: "email",
      headerName: "Email",
      width: "220",
    },
    {
      field: "countryId",
      headerName: "country",
      width: "150",
    },
    {
      field: "purchasedOnNcx",
      headerName: "Purchased On Ncx",
      width: "110",
    },
    {
      field: "Register",
      headerName: "Register On Ncx",
      width: "150",
    },
    {
      field: "twoFactor",
      headerName: "2FA",
      width: "80",
    },
    {
      field: "actions",
      headerName: "Action",
      width: "70",
      renderCell: (params) => {
        return (
          <>
            <Index.IconButton
              aria-label="more"
              disabled
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setRefferalId(params?.row?.refferalId);
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
            <Index.Menu
              className=""
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              // open={open1}
              onClose={handleClose1}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "100px",
                  boxShadow: "none",
                  border: "1px solid rgba(224, 224, 224, 1)",
                  marginTop: "6px"
                },
              }}
            >
              {options.map((option) => (
                <Index.MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={handleClose1}
                  className="menu-option-li"
                >
                  {option}
                </Index.MenuItem>
              ))}
            </Index.Menu>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Airdrop and Referrals
                </Index.Typography>
                <ToastContainer />
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
