import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useNavigate } from "react-router-dom";
import { zeroPad } from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  claimAirDrop,
  upcomingCurrentRoundData,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import { getGeneralSettingsFromRedux } from "../../../../redux/features/slices/Admin/AdminService";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Round 1", "$0.0001", "9 Months", "10% Monthly"),
  createData("Round 2", "$0.0002", "6 Months", "10% Monthly"),
  createData("Round 3", "$0.0004", "5 Months", "20% Monthly"),
  createData("Round 4", "$0.0006", "4 Months", "25% Monthly"),
  createData("Round 5", "$0.0008", "3 Months", "25% Monthly"),
  createData("Round 6", "$0.0011", "2 Months", "25% Monthly"),
  createData("Round 7", "$0.0015", "30 Days", "25% Monthly"),
  createData("Public Sale", "$0.002", "Unlocked", "10% Weekly "),
  createData("Listing Price", "$0.004"),
];

export default function Home() {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const generalSettings = useSelector(state=>state.admin.generalSettings)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [routData, setRoutData] = useState([]);
  const [roundData, setRoundData] = useState();
  const [responsive2, setresponsive2] = useState({
    responsive2: {
      0: {
        items: 1,
        margin: 0,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 2,
      },
      1200: {
        items: 4,
      },
    },
  });

  const getUpcomingPrivateSaleRound = async () => {
    await DataService.get(API.User.getUpcomingPrivateSale).then((res) => {
      setRoundData(res?.data?.data);
      dispatch(upcomingCurrentRoundData(res?.data?.data));
    });
  };

  const getCellColor = () => {
    const currentMonth = new Date().getMonth() + 1;
    return `Q${Math.ceil(currentMonth / 3)}`;
  };

  const getRoadMap = async () => {
    await DataService.post(API.Admin.GET_ROAD_LIST)
      .then((response) => {
        setRoutData(response.data.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const valueConversion = (value) => {
    let newValue = value.replace(/\,/g, "");
    if (window.innerWidth < 767) {
      if (parseInt(newValue) >= 1000000000) {
        return (
          (parseInt(newValue) / 1000000000).toFixed(1).replace(/\.0$/, "") + "G"
        );
      }
      if (parseInt(newValue) >= 1000000) {
        return (
          (parseInt(newValue) / 1000000).toFixed(1).replace(/\.0$/, "") + "M"
        );
      }
      if (parseInt(newValue) >= 1000) {
        return (parseInt(newValue) / 1000).toFixed(1).replace(/\.0$/, "") + "K";
      }
      return value;
    } else {
      return value;
    }
  };

  useEffect(() => {
    dispatch(getGeneralSettingsFromRedux())
    getRoadMap();
    getUpcomingPrivateSaleRound();
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
      <Index.Box className="hero-bonus-inner-flex">
        <Index.Box className="hero-bonus-duration-main">
          <Index.Typography className="hero-bonus-price duration-dots-main">
            {zeroPad(days)}
          </Index.Typography>
          <Index.Typography className="hero-bonus-inner-text">
            DAYS
          </Index.Typography>
        </Index.Box>
        <Index.Box className="hero-bonus-duration-main">
          <Index.Typography className="hero-bonus-price duration-dots-main">
            {zeroPad(hours)}
          </Index.Typography>
          <Index.Typography className="hero-bonus-inner-text">
            Hours
          </Index.Typography>
        </Index.Box>
        <Index.Box className="hero-bonus-duration-main">
          <Index.Typography className="hero-bonus-price  duration-dots-main">
            {zeroPad(minutes)}
          </Index.Typography>
          <Index.Typography className="hero-bonus-inner-text">
            Minutes
          </Index.Typography>
        </Index.Box>
        <Index.Box className="hero-bonus-duration-main">
          <Index.Typography className="hero-bonus-price">
            {zeroPad(seconds)}
          </Index.Typography>
          <Index.Typography className="hero-bonus-inner-text">
            Seconds
          </Index.Typography>
        </Index.Box>
      </Index.Box>
    );
  };

  return (
    <>
      <Index.Header />
      <Index.Box className="hero-sec black-bg">
        <video
          muted
          autoPlay="true"
          loop
          id="1111"
          class="banner-video"
          playsinline=""
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source
            src={Index.Video.authvideo}
            type="video/mp4"
            alt="banner video"
          />
        </video>
        <Index.Box className="container">
          <Index.Box className="hero-content-main">
            <Index.Box class="hero-content-inner">
              <Index.Typography
                className="hero-titles"
                component="h1"
                variant="h1"
              >
                NCX TOKEN POWERING WORLD FIRST GLOBAL HYBRID CEX/DEX/TRADING
                PLATFORM & ECOSYSTEM BRIDGING THE GAP BETWEEN DEFI & CENTRALISED
                FINANCE
              </Index.Typography>
              <Index.Box className="hero-btn-main">
                <Index.Button
                  className="hero-btn hero-fill-btn"
                  onClick={() => navigate("/sign-in")}
                >
                  Presale Dashboard Sign In
                </Index.Button>
                <Index.Button
                  className="hero-btn hero-border-btn"
                  onClick={() => {
                    dispatch(claimAirDrop());
                    navigate("/sign-up");
                  }}
                >
                  Claim Airdrop
                </Index.Button>
              </Index.Box>
              <Index.Typography
                className="hero-sub-para"
                component="p"
                variant="p"
              >
                Sign Up today and get {generalSettings.airdropTokenPerRefferal} NCXT Airdrop
              </Index.Typography>
            </Index.Box>

            <Index.Box className="hero-first-row">
              <Index.Box className="hero-progress-main">
                <Index.Box className="hero-progress-inner">
                  <Index.Typography className="hero-progress-txet">
                    {roundData &&
                      roundData?.current[0] &&
                      roundData?.current[0]?.phaseName}
                  </Index.Typography>
                  <Index.Box className="hero-progress-data">
                    <Index.Typography className="hero-progress-data-price">
                      {roundData &&
                        roundData?.current[0] &&
                        roundData?.current[0]?.roundPercent}
                      %
                    </Index.Typography>
                    <Index.Typography className="hero-progress-data-text">
                      Completed
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="progress-bar-main hero-progressbar">
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      roundData &&
                      roundData?.current[0] &&
                      roundData?.current[0]?.roundPercent
                    }
                    className="progress-bar hero-progress-bar"
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="hero-bonus-main">
                <Index.Box className="hero-bonus-flex">
                  <Index.Box className="hero-bonus-content-main">
                    <Index.Typography className="hero-bonus-text">
                      Current Bonus
                    </Index.Typography>
                    <Index.Typography className="hero-bonus-price">
                      25%
                    </Index.Typography>
                    <Index.Typography className="hero-bonus-inner-text">
                      Contributors can receive
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="hero-bonus-content-main">
                    <Index.Typography className="hero-bonus-text">
                      {roundData &&
                        roundData?.current[0] &&
                        roundData?.upcoming[0]?.phaseName}{" "}
                      STARTS IN
                    </Index.Typography>
                    <Index.Countdown
                      date={moment(
                        roundData &&
                          roundData?.current[0] &&
                          roundData?.upcoming[0]?.startDate
                      )}
                      renderer={renderer}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="hero-second-row">
              <Index.Box className="hero-social-flex">
                <Index.Box className="hero-social-left">
                  <Index.Box className="hero-social-flex">
                    <Index.Box className="hero-social-round">
                      <img src={Index.Svg.play} className="hero-social-icons" />
                    </Index.Box>
                    <Index.Typography className="hero-social-lables">
                      How it works
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="hero-social-flex">
                    <Index.Box className="hero-social-round">
                      <img
                        src={Index.Svg.light}
                        className="hero-social-icons"
                      />
                    </Index.Box>
                    <Index.Typography className="hero-social-lables">
                      Why buy Tokens now?
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="hero-social-right">
                  <Index.Box className="footer-social-main">
                    <Index.List className="footer-social-ul">
                      <Index.ListItem className="footer-social-li">
                        <Index.Link
                          className="footer-social-link"
                          to="https://www.instagram.com/"
                          target={"_blank"}
                        >
                          <img
                            src={Index.Svg.footerinstagram}
                            className="footer-social-icon"
                          />
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className="footer-social-li">
                        <Index.Link
                          className="footer-social-link"
                          to="https://telegram.org/"
                          target={"_blank"}
                        >
                          <img
                            src={Index.Svg.footershare}
                            className="footer-social-icon"
                          />
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className="footer-social-li">
                        <Index.Link
                          className="footer-social-link"
                          to="https://twitter.com/"
                          target={"_blank"}
                        >
                          <img
                            src={Index.Svg.footertwitter}
                            className="footer-social-icon"
                          />
                        </Index.Link>
                      </Index.ListItem>
                    </Index.List>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="stake-token-sec black-bg">
        <Index.Box className="container">
          <Index.Box className="stake-token-third-row">
            <Index.Box className="page-title-row">
              <Index.Box className="token-logo-main stack-tokenlogo-main">
                <img
                  src={Index.Svg.ncxprimarylogoicon}
                  className="token-logo"
                  alt="logo"
                ></img>
                <Index.Typography className="token-logo-text white-token-text">
                  Token
                </Index.Typography>
              </Index.Box>
              <Index.Box className="page-title-right-box">
                <Index.Typography className="page-title-right">
                  Stake NCX Tokens & Reap Rewards
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="stake-token-flex-row display-flex">
              <Index.Box className="stake-token-main stake-token-col-1">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading stake-token-col-1"
                    component="h5"
                    variant="h5"
                  >
                    NCXT Staked
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col stake-token-col-1">
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-1">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("50,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-2">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("100,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-3">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("250,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-4">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("500,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-5">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("1,000,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-6">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("2,500,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-7">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("5,000,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-8">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("10,000,000")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-data-round stake-token-data-round-9">
                      <img
                        src={Index.Svg.blueright}
                        className="blue-right-icon"
                      />
                    </Index.Box>
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {valueConversion("25,000,000")}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-2">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    Trading Fee Discount (Spot & Derivatives)
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      10%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      20%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      30%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      40%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      50%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      60%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      70%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      80%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      100%
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-3">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    Additional Staking Rewards (APR)
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      3%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      5%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      7%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      10%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      15%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      20%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      25%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      30%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      35%
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-4">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    Withdrawal Fee Refund{" "}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    ></Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      10%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      20%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      30%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      40%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      50%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      60%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      80%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      100%
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-5">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    Derivatives Leverage
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex colspan-2">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      80x{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      100x{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      110x{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      120x{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      130x{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      140x{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      150x{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      200x{" "}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-6">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    Additional APR for Providing Liquidity in NCX Yield Farms
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      5%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      8%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      10%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      12%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      15%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      25%{" "}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-7">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    Use NCXT as Derivatives Trading Collateral{" "}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      10%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      15%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      20%{" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      25%{" "}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-8">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    Use NCXT as Collateral to Borrow other Crypto Assets
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      {" "}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    ></Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-check-flex">
                      <img
                        src={Index.Svg.greycheck}
                        className="grey-check-icon"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-check-flex">
                      <img
                        src={Index.Svg.greycheck}
                        className="grey-check-icon"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Box className="stake-token-check-flex">
                      <img
                        src={Index.Svg.greycheck}
                        className="grey-check-icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="stake-token-main stake-token-col-9">
                <Index.Box className="stake-token-heading-main">
                  <Index.Typography
                    className="stake-token-heading"
                    component="h5"
                    variant="h5"
                  >
                    IEO Tickets for New NCX Spot Launches
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="stake-token-col">
                  <Index.Box className="stake-token-data-flex colspan-2">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      8%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex colspan-2">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      10%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex colspan-2">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      12%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex colspan-2">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      12%
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="stake-token-data-flex">
                    <Index.Typography
                      className="stake-token-label"
                      component="h5"
                      variant="h5"
                    >
                      15%{" "}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="home-token-utility-sec black-bg">
        <Index.Box className="container">
          <Index.Box className="home-token-main">
            <Index.Box className="main-titles-box">
              <Index.Typography className="main-titles">
                NCX Token Utility & Features
              </Index.Typography>
            </Index.Box>
            <Index.List className="home-token-ul">
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Trading Fees Discounts of up to 100%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT holders will be entitled to lower trading fees on
                    Derivatives and Spot Trading of up to 100%. The detailed
                    discount schedule provided above.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility1}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Fee Reimbursement on Withdrawals up to 100%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT holders benefit from reimbursement of their withdrawal
                    fees. The detailed reimbursement schedule and minimum amount
                    of Staked NCXT required is provided above.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility2}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Increased Derivatives Leverage
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT holders will be granted increased Derivatives Trading
                    Leverage of up to 200x. The detailed schedule and minimum
                    amount of Staked NCXT required is provided above. above.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility3}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Additional Staking Rewards
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT holders enjoy an additional 5% to 40% APR Staking
                    Rewards on top of the Standard Staking Rate. Example: If the
                    Standard APR is 30%, Additional Rewards Bonus is 40% APR,
                    the total Staking Rewards the staking holder will receive is
                    70% APR in total.secure.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility4}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    NCX Crypto Debit Card
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCX Card is Debit Card powered by MasterCard to facilitate
                    every day payments using card at more than 44 million
                    merchants word-wide, online payments and ATM withdrawals.
                    Multi-tier system unlocks the card benefits and discounts,
                    depending on the amount of NCX Tokens Staked.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility5}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Governance
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT holders are able to vote on future upgrades and
                    incentives on NCX Ecosystem allowing for more decentralised
                    decision-making approach through governance.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility6}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Derivatives Trading Collateral
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT can be used as collateral for Derivatives Trading
                    across 500 Markets including Cryptocurrencies, Equities,
                    Fiat Currencies, ETF’s and Commodities.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility7}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Additional APR for Providing Liquidity in NCX DEX
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT holders get additional 5% - 25% Rewards for providing
                    liquidity in NCX DEX Liquidity Pools and Yield Farms. The
                    detailed schedule provided in the table above.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility8}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Use NCXT as Collateral to Borrow other Crypto Assets
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT can be used as collateral to borrow other Crypto Assets
                    on NCX Lending Protocol, including USDT, Bitcoin, Ethereum,
                    BNB, Solana, Polygon and others.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility9}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Token Burn to Drive the Growth of NCXT
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCX will burn NCXT every month to create a continuous
                    utility environment for holders though the NCXT Buy Back &
                    Burn Function decreasing the circulating supply in order to
                    drive price growth of NCXT
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility10}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Exclusive Product Access & IEO Tickets
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    NCXT holders will enjoy early access to new products, as
                    well as preferential terms for up-and coming services such
                    as IEOs on our Spot market.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility11}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="advance-sec black-bg">
        <Index.Box className="container">
          <Index.Box className="advance-main">
            <Index.Typography className="advance-titles">
              Advanced CEX, DEX & Trading features on one platform{" "}
            </Index.Typography>
            <Index.Typography className="advance-para">
              Utilising the power of Decentralised Finance with the ease of use
              & advantages of a traditional Cryptocurrency Exchange and advanced
              Trading Platform in a single, cutting-edge, all-in-one platform
              and scalable ecosystem{" "}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="advance-data-main">
            <Index.Box className="advance-data-head">
              <Index.Box className="advance-col-1">
                <Index.Typography className="advance-data-titles">
                  Features
                </Index.Typography>
              </Index.Box>
              <Index.Box className="advance-col-2">
                <Index.Typography className="advance-data-titles">
                  NCX
                </Index.Typography>
              </Index.Box>
              <Index.Box className="advance-col-3">
                <Index.Typography className="advance-data-titles">
                  CEX
                </Index.Typography>
              </Index.Box>
              <Index.Box className="advance-col-4">
                <Index.Typography className="advance-data-titles">
                  DEX
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="advance-data-body">
              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Security
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      High
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      High
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Margin Trading
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Fees
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      High
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      User Interface
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      User Friendly
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      User Friendly
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Complex
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Transaction Congestion
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue ">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      High
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Trading Speed & Volume
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      High
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      High
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Transaction Confirmation Latency
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Low
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      High
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Staking
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Decentalised
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Centalised
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Decentalised
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Box>
                      <Index.Typography className="advance-data-text">
                        Equities Trading
                      </Index.Typography>
                      <Index.Typography className="advance-data-text-small">
                        (USA, EU, ASIA Stock Market)
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-dark-blue">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="advance-data-body">
              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Derivatives Trading
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Fiat Deposits / Withdrawals
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Crypto-Fiat Trading Pairs
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Commodities Trading
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Forex Trading
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      ETF’s Trading
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      OTC Trading
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Institutional Services{" "}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Crypto Debit Card
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      24/7 Support
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.checkround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      <img src={Index.Svg.minusround} className="round-icons" />
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="advance-data-row">
                <Index.Box className="advance-col-1">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text">
                      Coverage
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-2 bg-purple">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Global**
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-3 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Regional
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="advance-col-4 bg-light-black">
                  <Index.Box className="advance-data-box">
                    <Index.Typography className="advance-data-text text-center">
                      Global
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Typography className="advance-condition-text">
              **- NCX Platform is available Globally apart of Restricted
              Jurisdictions listed in{" "}
              <span className="advance-condition-text-span">
                User Agreement
              </span>{" "}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* <Index.Box className="earn-token-sec white-bg">
        <Index.Box className="container">
          <Index.Box className="earn-token-row">
            <Index.Box className="earn-token-left-main">
              <Index.Typography className="earn-token-title">
                Complete Tasks Earn NCX Tokens
              </Index.Typography>
              <Index.Typography className="earn-token-para">
                Sign Up, Complete Tasks, reach new Levels while earning NCXT
                along the way with NCX Rewards Program. One of the most thought
                after Rewards Programs built for the Traders and Community.
              </Index.Typography>
              <Index.Box className="earn-token-card-flex">
                <Index.Box className="earn-token-card">
                  <Index.Typography className="earn-token-price">
                    4,000<span className="earn-token-span">NCXT</span>
                  </Index.Typography>
                  <Index.Typography className="earn-token-card-title">
                    Sign Up and verify
                  </Index.Typography>
                  <Index.Typography className="earn-token-card-para earn-token-card-para1">
                    For new users only
                  </Index.Typography>
                  <Index.Box className="earn-btn-flex">
                    <Index.Button className="earn-token-btn">
                      Start Task
                    </Index.Button>
                    <img
                      src={Index.Svg.questionmarkicon}
                      className="earn-question-icon"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="earn-token-card">
                  <Index.Typography className="earn-token-price">
                    1,250<span className="earn-token-span">NCXT</span>
                  </Index.Typography>
                  <Index.Typography className="earn-token-card-title">
                    Reach $10,000+ in Derivatives Trading Volume
                  </Index.Typography>
                  <Index.Typography className="earn-token-card-para">
                    For new users only
                  </Index.Typography>
                  <Index.Box className="earn-btn-flex">
                    <Index.Button className="earn-token-btn">
                      View All
                    </Index.Button>
                    <img
                      src={Index.Svg.questionmarkicon}
                      className="earn-question-icon"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="earn-token-right-main">
              <Index.Box className="earn-token-bg">
                <video
                  muted
                  autoPlay="true"
                  loop
                  id="1111"
                  class="earn-token-video"
                  playsinline=""
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src={Index.Video.authvideo}
                    type="video/mp4"
                    alt="banner video"
                  />
                </video>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box> */}

      <Index.Box className="token-private-sale-sec">
        <Index.Box className="container">
          <Index.Box className="private-sale-main-token">
            <Index.Box className="token-logo-main">
              <img
                src={Index.Svg.ncxbluelogo}
                className="token-logo"
                alt="logo"
              ></img>
              <Index.Typography className="token-logo-text">
                Token
              </Index.Typography>
            </Index.Box>
            <Index.Box className="token-private-sale-main">
              <Index.Box className="token-private-sale-title-box">
                <Index.Typography className="token-private-sale-title">
                  Private Sale
                </Index.Typography>
              </Index.Box>
              <Index.Box className="token-private-data-main token-private-scrollbar">
                {window.innerWidth > 767 ? (
                  <Index.Box
                    component={Paper}
                    className="token-table-container"
                  >
                    <Index.Box
                      // sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      className="token-table"
                    >
                      <Index.Box className="token-table-head">
                        <Index.Box className="token-table-row">
                          <Index.Box
                            className="token-table-th"
                        
                          >
                            Private Sale Round
                          </Index.Box>
                          <Index.Box
                            className="token-table-th"
                           
                          >
                            Token Sale Price
                          </Index.Box>
                          <Index.Box
                            className="token-table-th"
                      
                          >
                            Vesting Period
                          </Index.Box>
                          <Index.Box
                            className="token-table-th"
                       
                          >
                            Token Release
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="token-table-body">
                        {rows.map((row) => (
                          <Index.Box
                            className="token-table-row"
                            key={row.name}
                          >
                            <Index.Box
                              className="token-table-td"
                        
                            >
                              {row.name}
                            </Index.Box>
                            <Index.Box
                              className="token-table-td"
                
                            >
                              {row.calories}
                            </Index.Box>
                            <Index.Box
                              className="token-table-td"
                       
                            >
                              {row.fat}
                            </Index.Box>
                            <Index.Box
                              className="token-table-td"
                           
                            >
                           
                              {row.carbs}
                            </Index.Box>
                            </Index.Box>
                        ))}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>


                ) : (
                  <Index.Box
                    component={Paper}
                    className="token-table-container"
                  >
                    <Index.Box
                      // sx={{ minWidth: 1500 }}
                      aria-label="simple table"
                      className="token-table"
                    >
                      <Index.Box className="token-table-head">
                        <Index.Box className="token-table-row">
                          <Index.Box
                            className="token-table-th"
                        
                          >
                            Private Sale Round
                          </Index.Box>
                          <Index.Box
                            className="token-table-th"
                           
                          >
                            Token Sale Price
                          </Index.Box>
                          <Index.Box
                            className="token-table-th"
            
                          >
                            Vesting Period
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="token-table-body">
                        {rows.map((row) => (
                          <Index.Box
                            className="token-table-row"
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <Index.Box
                              className="token-table-td"
                      
                            >
                              {row.name}
                            </Index.Box>
                            <Index.Box
                              className="token-table-td"
                        
                            >
                              {row.calories}
                            </Index.Box>
                        
                            {row.fat && (
                              <Index.Box
                                className="token-table-td"
                           
                              >{row.fat} </Index.Box>
                            )}
                          </Index.Box>
                        ))}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>

              {/* <Index.Box className="cust-token-private-data-main">
                <Index.Box className="cust-token-private-head">
                <Index.Box className="cust-token-private-head-row">
                  <Index.Box className="cust-token-private-col-th">Private Sale Round</Index.Box>
                  <Index.Box className="cust-token-private-col-th">	Token Sale Price</Index.Box>
                  <Index.Box className="cust-token-private-col-th">	Vesting Period</Index.Box>
                  <Index.Box className="cust-token-private-col-th">Token Release</Index.Box>
                </Index.Box>
                </Index.Box>
                <Index.Box className="cust-token-private-body token-private-scrollbar">
                <Index.Box className="cust-token-private-row">
                  <Index.Box className="cust-token-private-col-td">Round 1</Index.Box>
                  <Index.Box className="cust-token-private-col-td">$0.0001</Index.Box>
                  <Index.Box className="cust-token-private-col-td">9 Months</Index.Box>
                  <Index.Box className="cust-token-private-col-td">	10% Monthly</Index.Box>
                </Index.Box>
                <Index.Box className="cust-token-private-row">
                  <Index.Box className="cust-token-private-col-td">Round 1</Index.Box>
                  <Index.Box className="cust-token-private-col-td">$0.0001</Index.Box>
                  <Index.Box className="cust-token-private-col-td">9 Months</Index.Box>
                  <Index.Box className="cust-token-private-col-td">	10% Monthly</Index.Box>
                </Index.Box>
                </Index.Box>
                <Index.Box className="cust-token-private-row grediant-border-token">
                  <Index.Box className="cust-token-private-col-td">Listing Ptice</Index.Box>
                  <Index.Box className="cust-token-private-col-td">$0.004</Index.Box>
                  <Index.Box className="cust-token-private-col-td"></Index.Box>
                  <Index.Box className="cust-token-private-col-td"></Index.Box>
                </Index.Box>
              </Index.Box> */}


              <Index.Box className="token-private-notes-main">
                <Index.Typography className="token-private-note-titels">
                  Vesting Details
                </Index.Typography>
                <Index.Typography className="token-private-note-para">
                  1. Tokens purchased during the Private Sale are subject to
                  vesting periods during which the Tokens are locked and are
                  released in parts as per Vesting Schedule above.
                </Index.Typography>
                <Index.Typography className="token-private-note-para">
                  2. After the initial lock up period ends the tokens are
                  available for withdrawal from Presale Dashboard by automatic
                  release, this is done by the projects to safeguard the
                  investors and secure fair and even token distribution.
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="rewards-program-sec white-bg">
        <Index.Box className="container">
          <Index.Box className="rewards-main">
            <Index.Typography className="rewards-titles">
              Rewards Program{" "}
            </Index.Typography>
            <Index.Typography className="rewards-titles-para">
              Earn NCX Tokens and Commission by registering on the NCX platform
              and inviting friends{" "}
            </Index.Typography>

            <Index.Box className="rewards-data-main">
              <Index.Box className="rewards-main-flex rewards-data-bg1">
                <Index.Box className="rewards-round-box">
                  <img src={Index.Svg.rewards1} className="rewards-icons" />
                </Index.Box>
                <Index.Typography className="rewards-data rewards-data-1">
                  Sign Up Airdrop
                </Index.Typography>
                <Index.Typography className="rewards-data rewards-data-2">
                  {generalSettings.airdropTokenPerRefferal} NCXT
                </Index.Typography>
                <Index.Typography className="rewards-data rewards-data-3">
                  Sign Up on NCX and complete KYC to receive your SignUp Airdrop
                  Bonus
                </Index.Typography>
              </Index.Box>
              <Index.Box className="rewards-main-flex rewards-data-bg2">
                <Index.Box className="rewards-round-box">
                  <img src={Index.Svg.rewards2} className="rewards-icons" />
                </Index.Box>
                <Index.Typography className="rewards-data rewards-data-1">
                  Referral
                </Index.Typography>
                <Index.Typography className="rewards-data rewards-data-2">
                {generalSettings.refferalTokenPerRefferal} NCXT
                </Index.Typography>
                <Index.Typography className="rewards-data rewards-data-3">
                  Invite a friend and receive Referral Bonus once he registers &
                  completes KYC
                </Index.Typography>
              </Index.Box>
              <Index.Box className="rewards-main-flex rewards-data-bg3">
                <Index.Box className="rewards-round-box">
                  <img src={Index.Svg.rewards3} className="rewards-icons" />
                </Index.Box>
                <Index.Typography className="rewards-data rewards-data-1">
                  Commission
                </Index.Typography>
                <Index.Typography className="rewards-data rewards-data-2">
                  5%{" "}
                </Index.Typography>
                <Index.Typography className="rewards-data rewards-data-3">
                  Earn 5% Commission on referees NCXT purchases across all
                  presale stages
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Typography className="rewards-note-titels">
              Notes:
            </Index.Typography>
            <Index.Typography className="rewards-note-para">
              1. To prevent unethical misuse of the Promotion, the Sign Up
              Airdrop will only be credited once the KYC Verification is
              completed successfully, limit of one registration per user, new
              users only, any accounts suspected in fraudulent submissions would
              be permanently banned and will lose all the rewards. Sign Up
              Airdrop tokens would be available after the Presale is completed.
            </Index.Typography>
            <Index.Typography className="rewards-note-para">
              2. After you sign into your Presale Dashboard you will find your
              unique Referral Link, share this link with your friends, once they
              register with your link you and complete KYC you will receive
               {generalSettings.refferalTokenPerRefferal} NCXT as a Referral Bonus, friends who used your link will 
              get {generalSettings.airdropTokenPerRefferal } NCXT Sign Up Airdrop, promotion is available to new
              users only.
            </Index.Typography>
            <Index.Typography className="rewards-note-para">
              3. Once the user that registered with your referral link completes
              the purchase of NCXT during any of the Presale Stages, you will
              receive 5% commission in your Referral Wallet, you can view
              referral credits transactions history in Presale Dashboard
              Referral Section.
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="home-token-allocation-sec black-bg ">
        <video
          muted
          autoPlay="true"
          loop
          id="1111"
          class="allocation-bg-video"
          playsinline=""
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source
            src={Index.Video.authvideo}
            type="video/mp4"
            alt="banner video"
          />
        </video>
        <Index.Box className="container">
          <Index.Box className="home-token-main">
            <Index.Box className="main-titles-box">
              <Index.Typography className="main-titles">
                NCX Token Allocation
              </Index.Typography>
            </Index.Box>
            <Index.List className="home-token-ul">
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Airdrops & Launch - 10%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Airdrops for new user acquisition campaigns as well as for
                    referral programs & affiliate rewards that drive the NCX
                    Platform user growth and NCX Token holders.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility1}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Products & Liquidity - 16%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Used for platform liquidity and as ongoing support for
                    product features and incentives such as Staking
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility2}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Private Sale & IDO - 24%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Funds raised during the Private Sale and IDO are allocated
                    for initial locked liquidity for NCX Tokens on Decentralised
                    Exchanges and NCX Spot Trading, global marketing, growing of
                    NCX Ecosystem and new user acquisition, operations,
                    development, partnerships and initial liquidity
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility3}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    CEX/DEX Listings - 8%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Used for strong Marketing Campaigns across all regions and
                    marketing channels including Social Media, Target Marketing,
                    Outdoor Marketing, Premium Sports Events & Tournaments and
                    Sponsorship Deals
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility4}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Marketing & PR - 15%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Used for strong Marketing Campaigns across all regions and
                    marketing channels including Social Media, Target Marketing,
                    Outdoor Marketing, Premium Sports Events & Tournaments and
                    Sponsorship Deals
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility5}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Reserve - 12%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Used exclusively for Buyback and Burn of NCX Tokens to
                    decrease the circulating supply stimulating price
                    growthservice, institutions can use the OTC API.
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility6}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Ecosystem - 10%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Used for expanding the NCX Ecosystem and NCX Token Utility
                    through partnerships and integrations in the DeFi and CeFi
                    Space
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility7}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="home-token-li">
                <Index.Box className="home-token-utility-box">
                  <Index.Typography className="home-token-utility-titles">
                    Team - 5%
                  </Index.Typography>
                  <Index.Typography className="home-token-utility-para">
                    Used exclusively for employee incentives and performance
                    rewards
                  </Index.Typography>
                  <img
                    src={Index.Svg.utility8}
                    className="home-utility-icons"
                  />
                </Index.Box>
              </Index.ListItem>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="home-roadmap-sec black-bg">
        <Index.Box className="container">
          <Index.Box className="page-center-title-box">
            <Index.Typography className="page-center-title">
              ROADMAP
            </Index.Typography>
          </Index.Box>

          <Index.Box className="roadmap-main">
            <Index.OwlCarousel
              className="owl-theme roadmap-owl"
              loop
              margin={0}
              responsive={responsive2.responsive2}
              nav={true}
              dots={false}
              startPosition={1}
              navText={[
                "<img src=" + Index.Svg.bluerightthin + " >",
                "<img src=" + Index.Svg.bluerightthin + " >",
              ]}
              stagePadding={0}
            >
              {routData?.map((item, index) => {
              
                return (
                  <Index.Box class="item">
                    <Index.Box className="roadmap-content-box">
                      <Index.Typography className="roadmap-quarter-text">
                        {item?.quater}
                      </Index.Typography>
                      <Index.Box className="roadmap-dot-main">
                        <Index.Box
                          className={
                            item?.quater == getCellColor()
                              ? "roadmap-line-left"
                              : "roadmap-line-left-nun"
                          }
                        ></Index.Box>
                        <Index.Box
                          className={
                            item?.quater == getCellColor()
                              ? "roadmap-dots"
                              : "roadmap-dots-nun"
                          }
                        ></Index.Box>
                        <Index.Box
                          className={
                            item?.quater == getCellColor()
                              ? "roadmap-line-right"
                              : "roadmap-line-right-nun"
                          }
                        ></Index.Box>
                      </Index.Box>
                      <Index.Typography className="roadmap-quarter-date">
                        {item?.month}
                      </Index.Typography>
                      <Index.Typography className="roadmap-para">
                        {item?.description}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                );
              })}
            </Index.OwlCarousel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Footer />
    </>
  );
}
