import React from "react";
import { useLocation } from "react-router-dom";
import Index from "../../Index";

export default function Sidebar() {
  const pathname = useLocation();
  const removeClass = () => {
    var element = document.getElementById("admin-sidebar");
    element.classList.remove("active");
    var body = document.getElementsByTagName("body");
    body[0].classList.remove("body-overflow");
  };
  return (
    <>
      <Index.Box className="admin-sidebar-main scrollbar" id={"admin-sidebar"}>
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={Index.Svg.logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button onClick={removeClass}>
              <img
                src={Index.Svg.whiteclose}
                className="admin-sidebar-close"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/user"
                  className={
                    pathname?.pathname === "/admin/user" 
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.usericon}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  User
                </Index.Link>
              </Index.ListItem>

              <Index.Link
                to="/admin/airdroip-and-referrals"
                className={
                  pathname?.pathname === "/admin/airdroip-and-referrals"
                    ? "admin-sidebar-link active"
                    : "dashboard-header"
                }
                onClick={removeClass}
              >
                <Index.Box className="admin-sidebar-icons">
                  <img
                    src={Index.Svg.referrals}
                    className="admin-sidebar-icon"
                  />
                </Index.Box>
                Airdrop and Referrals
              </Index.Link>

              <Index.Link
                to="/admin/presale-round"
                className={
                  pathname?.pathname === "/admin/presale-round"
                    ? "admin-sidebar-link active"
                    : "dashboard-header"
                }
                onClick={removeClass}
              >
                <Index.Box className="admin-sidebar-icons">
                  <img
                    src={Index.Svg.presaleround}
                    className="admin-sidebar-icon"
                  />
                </Index.Box>
                Presale Round
              </Index.Link>
              <Index.Link
                to="/admin/road-map"
                className={
                  pathname?.pathname === "/admin/road-map"
                    ? "admin-sidebar-link active"
                    : "dashboard-header"
                }
                onClick={removeClass}
              >
                <Index.Box className="admin-sidebar-icons">
                  <img src={Index.Svg.roadmap} className="admin-sidebar-icon" />
                </Index.Box>
                Road Map
              </Index.Link>

              <Index.Link
                to="/admin/withdrawal-history"
                className={
                  pathname?.pathname === "/admin/withdrawal-history"
                    ? "admin-sidebar-link active"
                    : "dashboard-header"
                }
              >
                <Index.Box className="admin-sidebar-icons">
                <img src={Index.Svg.withdrawal} className="admin-sidebar-icon" />
                </Index.Box>
                Withdrawals
              </Index.Link>

              <Index.Link
                to="/admin/purchase-histoty"
                className={
                  pathname?.pathname === "/admin/purchase-histoty"
                    ? "admin-sidebar-link active"
                    : "dashboard-header"
                }
              >
                <Index.Box className="admin-sidebar-icons">
                <img src={Index.Svg.deposite} className="admin-sidebar-icon" />
                </Index.Box>
                Deposits
              </Index.Link>
              <Index.Link
                to="/admin/blocked-users"
                className={
                  pathname?.pathname === "/admin/blocked-users"
                    ? "admin-sidebar-link active"
                    : "dashboard-header"
                }
              >
                <Index.Box className="admin-sidebar-icons">
                <img src={Index.Svg.withdrawal} className="admin-sidebar-icon" />
                </Index.Box>
                Blocked Users
              </Index.Link>
              <Index.Accordion>
                <Index.AccordionSummary
                  expandIcon={<Index.ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >  <Index.Box className="admin-sidebar-icons">
               <Index.SettingsIcon/>
              </Index.Box>
                  <Index.Typography>General settings</Index.Typography>
                </Index.AccordionSummary>
                <Index.AccordionDetails>
                  <Index.Link
                    to="/admin/bonus"
                    className={
                      pathname?.pathname === "/admin/bonus"
                        ? "admin-sidebar-link active"
                        : "dashboard-header"
                    }
                  ><Index.Box className="admin-sidebar-icons">
                  <Index.PercentIcon/>
                 </Index.Box>
                    <Index.Typography>Bonus Percentage</Index.Typography>
                  </Index.Link>

                  <Index.Link
                    to="/admin/airdrop-edit"
                    className={
                      pathname?.pathname === "/admin/airdrop-edit"
                        ? "admin-sidebar-link active"
                        : "dashboard-header"
                    }
                  ><Index.Box className="admin-sidebar-icons">
     <img
                    src={Index.Svg.referrals}
                    className="admin-sidebar-icon"
                  />                 </Index.Box>
                    <Index.Typography>Airdrop And Refferals</Index.Typography>
                  </Index.Link>
                </Index.AccordionDetails>
              </Index.Accordion>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
