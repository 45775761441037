import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import message from "../../../../ValidationMessage.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  validateRoundNo,
  validatePrice,
  validateDatePicker,
  validatePaymentMethod,
  validateMinimumPurchase,
  validateMonthlyRealese,
  validateRoundPercent,
  validateVestingPeriod,
} from "../../../../component/common/AuthValidation";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

const PrivateAdminSale = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((store) => store.admin.token);
  const [startValue, setStartValue] = useState("");
  const [endValue, setEndValue] = useState("");
  const [roundNo, setRoundNo] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [minimumPur, setMinimumPur] = useState("");
  const [vestingPeriod, setVestingPeriod] = useState("");
  const [monthlyRealese, setMonthlyRealese] = useState("");
  const [roundPercent, setRoundPercent] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [singleData, setSingleData] = useState({});
  const { editId } = useParams();
  const [price, setPrice] = useState("");
  const [error, setError] = useState({
    roundNoError: "",
    tokenQuantityError: "",
    priceError: "",
    paymentMethodError: "",
    minimumPurchaseError: "",
    monthlyRealeseError: "",
    roundPercentError: "",
    vestingPeriodError: "",
  });
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setendDateError] = useState("");

  useEffect(() => {
    const initialValues = {
      startValue: editId !== undefined ? singleData?.startDate : "",
      endValue: editId !== undefined ? singleData?.endDate : "",
      roundNo: editId !== undefined ? singleData?.phaseName : "",
      minimumPur: editId !== undefined ? singleData?.minimumPurchase : "",
      paymentMethod: editId !== undefined ? singleData?.paymentMethod : "",
      price: editId !== undefined ? singleData?.tokenPrice : "",
      roundPercent: editId !== undefined ? singleData?.roundPercent : "",
      bonusPercent: editId !== undefined ? singleData?.bonusPercent : "",
      vestingPeriod: editId !== undefined ? singleData?.vestingPeriod : "",
      monthlyRealese: editId !== undefined ? singleData?.monthlyRelease : "",
    };
    setStartValue(initialValues?.startValue);
    setEndValue(initialValues?.endValue);
    setRoundNo(initialValues?.roundNo);
    setPaymentMethod(initialValues?.paymentMethod);
    setMinimumPur(initialValues?.minimumPur);
    setPrice(initialValues?.price);
    setRoundPercent(initialValues?.roundPercent);
    setVestingPeriod(initialValues?.vestingPeriod);
    setMonthlyRealese(initialValues?.monthlyRealese);
  }, [singleData]);

  const handleChange = (e) => {
    setStartValue(e);
    setStartDateError(validateDatePicker(e));
  };
  const handleChange1 = (e) => {
    setEndValue(e);
    setendDateError(validateDatePicker(e));
  };
  const handleRoundOnErrorChange = (e) => {
    setRoundNo(e.target.value);
    setError((prev) => ({
      ...prev,
      roundNoError: validateRoundNo(e.target.value),
    }));
  };

  const onChangeroundPercent = (e) => {
    setRoundPercent(e.target.value);
    setError((prev) => ({
      ...prev,
      roundPercentError: validateRoundPercent(e.target.value),
    }));
  };

  const onPaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    setError((prev) => ({
      ...prev,
      paymentMethodError: validatePaymentMethod(e.target.value),
    }));
  };
  const onMinimumPurchaseChange = (e) => {
    setMinimumPur(e.target.value);
    setError((prev) => ({
      ...prev,
      minimumPurchaseError: validateMinimumPurchase(e.target.value),
    }));
  };

  const onVestingPeriod = (e) => {
    setVestingPeriod(e.target.value);
    setError((prev) => ({
      ...prev,
      vestingPeriodError: validateVestingPeriod(e.target.value),
    }));
  };
  const onMonthlyRealese = (e) => {
    const val = Number(e.target.value);
    const threeDigitRegex = /^\d{3}$/;

    setMonthlyRealese(e.target.value);

    setError((prev) => ({
      ...prev,
      monthlyRealeseError: validateMonthlyRealese(e.target.value),
    }));
  };

  const handlePriceErrorChange = (e) => {
    setPrice(e.target.value);
    setError((prev) => ({
      ...prev,

      priceError: validatePrice(e.target.value),
    }));
  };

  const getSingleData = async () => {
    if (editId !== undefined) {
      const formData = new URLSearchParams();

      formData.append("id", editId);

      await DataService.post(API.Admin.GET_ID, formData, {
        headers: {
          auth: token,
        },
      }).then((response) => {
        setSingleData(response?.data?.data);
      }).catch(() =>{
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
    }
  };

  useEffect(() => {
    getSingleData();
  }, []);
  const handleSubmit = async (e) => {
    setIsFetching(true);
    if (
      roundNo !== "" &&
      price !== "" &&
      minimumPur !== "" &&
      roundPercent !== "" &&
      paymentMethod !== "" &&
      vestingPeriod !== "" &&
      monthlyRealese !== ""
    ) {
      const urlencoded = new URLSearchParams();
      if(startValue){
        urlencoded.append("startDate", startValue);
      }
      if(endValue){
        urlencoded.append("endDate", endValue);
      }
      
      urlencoded.append("phaseName", roundNo);
      urlencoded.append("minimumPurchase", minimumPur);
      urlencoded.append("roundPercent", roundPercent);
      urlencoded.append("paymentMethod", paymentMethod);
      urlencoded.append("tokenPrice", price);
      urlencoded.append("vestingPeriod", vestingPeriod);
      urlencoded.append("monthlyRelease", monthlyRealese);
      if (editId !== undefined) {
        urlencoded.append("id", editId);
      }

      await DataService.post(API.Admin.ADMIN_PRIVATE_SALE, urlencoded, {
        headers: {
          auth: token,
        },
      })
        .then((response) => {
          setIsFetching(false);
          toast(response.data.message);
          setStartValue("");
          setEndValue("");
          setRoundNo("");
          setPrice("");
          setPaymentMethod("");
          setMinimumPur("");
          setRoundPercent("");
          setVestingPeriod("");
          setMonthlyRealese("");
          setTimeout(() => {
            navigate("/admin/presale-round");
          }, 1500);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setIsFetching(false);
        });
    } else {
      if (error.response.data.message === "Token not authorized") {
        dispatch(getAdminToken(""));
        dispatch(getAdminType(""));
        dispatch(getAdminData({}));
        navigate("/admin/login")
      }
      if (startValue === null) {
        setStartDateError(message.SELECT_DATE);
      }
      if (endValue === null) {
        setendDateError(message.SELECT_DATE);
      }
      if (roundNo === "") {
        setError((prev) => ({ ...prev, roundNoError: message.ENTER_ROUND_NO }));
      }
      if (paymentMethod === "") {
        setError((prev) => ({
          ...prev,
          paymentMethodError: "Please Enter Payment Method",
        }));
      }
      if (roundPercent === "") {
        setError((prev) => ({
          ...prev,
          roundPercentError: "Please Enter Round Percentage",
        }));
      }
      if (minimumPur === "") {
        setError((prev) => ({
          ...prev,
          minimumPurchaseError: "Please Enter Minimum Purchase",
        }));
      }
      if (monthlyRealese === "") {
        setError((prev) => ({
          ...prev,
          monthlyRealeseError: "Please Enter Monthly Realese Amount",
        }));
      }
      if (vestingPeriod === "") {
        setError((prev) => ({
          ...prev,
          vestingPeriodError: "Please Enter Vesting Period",
        }));
      }

      if (price === "") {
        setError((prev) => ({
          ...prev,
          priceError: message.ENTER_PRICE,
        }));
      }
    }
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });
  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  {editId === undefined ? "Add" : "Edit"} Private Sale
                </Index.Typography>
                <ToastContainer />
              </Index.Box>

              <Index.Box className="add-user-data-main">
                <Index.Box className="admin-private-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            className="display-row"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="admin-text-field-main">
                                <Index.FormHelperText
                                  className="form-lable"
                                  for="user"
                                >
                                  Start Date
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.Stack className="sale-field">
                                      <Index.DesktopDatePicker
                                        className="privat-sale-datepicker"
                                        inputFormat="MM/DD/YYYY"
                                        value={startValue}
                                        onChange={handleChange}
                                        renderInput={(params) => (
                                          <Index.TextField {...params} />
                                        )}
                                      />
                                    </Index.Stack>
                                  </Index.LocalizationProvider>
                                </Index.Box>
                                <p className="error-text">{startDateError}</p>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="admin-text-field-main">
                                <Index.FormHelperText
                                  className="form-lable"
                                  for="user"
                                >
                                  End Date
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.Stack className="sale-field">
                                      <Index.DesktopDatePicker
                                        className="privat-sale-datepicker"
                                        inputFormat="MM/DD/YYYY"
                                        value={endValue}
                                        onChange={handleChange1}
                                        renderInput={(params) => (
                                          <Index.TextField {...params} />
                                        )}
                                      />
                                    </Index.Stack>
                                  </Index.LocalizationProvider>
                                </Index.Box>
                                <p className="error-text">{endDateError}</p>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main">
                          <Index.FormHelperText
                            className="form-lable"
                            for="user"
                          >
                            Round No
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              className="admin-textfield"
                              id="outlined-basic"
                              placeholder="Please Enter Round Number"
                              value={roundNo}
                              onChange={handleRoundOnErrorChange}
                            />
                          </Index.Box>
                          <p className="error-text">{error.roundNoError}</p>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main">
                          <Index.FormHelperText
                            className="form-lable"
                            for="user"
                          >
                            Payment Method
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              className="admin-textfield"
                              variant="outlined"
                              type="text"
                              id="outlined-basic"
                              placeholder="Please Enter Payment Method"
                              value={paymentMethod}
                              onChange={onPaymentMethodChange}
                            />
                          </Index.Box>
                          <p className="error-text">
                            {error.paymentMethodError}
                          </p>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main">
                          <Index.FormHelperText
                            className="form-lable"
                            for="user"
                          >
                            Minimum Purchase
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              className="admin-textfield"
                              variant="outlined"
                              id="outlined-basic"
                              placeholder="Please Enter Minimum Purchase"
                              value={minimumPur}
                              onChange={onMinimumPurchaseChange}
                            />{" "}
                          </Index.Box>
                          <p className="error-text">
                            {error.minimumPurchaseError}
                          </p>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main">
                          <Index.FormHelperText
                            className="form-lable"
                            for="user"
                          >
                            Monthly release %
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              className="admin-textfield"
                              variant="outlined"
                              id="outlined-basic"
                              placeholder="Please Enter Monthly Release %"
                              value={monthlyRealese}
                              onChange={onMonthlyRealese}
                            />
                          </Index.Box>
                          <p className="error-text">
                            {error.monthlyRealeseError}
                          </p>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main">
                          <Index.FormHelperText
                            className="form-lable"
                            for="user"
                          >
                            Round Percent %
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              className="admin-textfield"
                              variant="outlined"
                              id="outlined-basic"
                              placeholder="Please Enter Round Percentage"
                              value={roundPercent}
                              onChange={onChangeroundPercent}
                            />
                          </Index.Box>
                          <p className="error-text">
                            {error.roundPercentError}
                          </p>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main">
                          <Index.FormHelperText
                            className="form-lable"
                            for="user"
                          >
                            Token Price
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              className="admin-textfield"
                              variant="outlined"
                              id="outlined-basic"
                              placeholder="Please Enter Token Price"
                              value={price}
                              onChange={handlePriceErrorChange}
                            />
                          </Index.Box>
                          <p className="error-text">{error.priceError}</p>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main">
                          <Index.FormHelperText
                            className="form-lable"
                            for="user"
                          >
                            Vesting Period
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              className="admin-textfield"
                              variant="outlined"
                              id="outlined-basic"
                              placeholder="Please Enter Vesting Period"
                              value={vestingPeriod}
                              onChange={onVestingPeriod}
                            />
                          </Index.Box>
                          <p className="error-text">
                            {error.vestingPeriodError}
                          </p>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="private-admin-btn-main">
                    <Index.PrimaryButton
                      className="sale-btn admin-private-btn"
                      type="button"
                      btnLabel="Submit"
                      onClick={handleSubmit}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default PrivateAdminSale;
