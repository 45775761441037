import React, { useState } from "react";
import Index from "../../../Index";
import { useSelector, useDispatch } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import {
  enabledFa,
  disabledFa,
  getAdminToken,
  getAdminType,
  getAdminData,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import { validateTokenCode } from "../../../../component/common/AuthValidation";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
};
const TowFA = () => {
  const tokenType = useSelector((data) => data.admin.faType);
  const token = useSelector((store) => store.admin.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tokenCodeError, setTokenCodeError] = useState("");
  const [tokenCode, setTokenCode] = useState("");
  const [ScanImage, setScanImage] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen2Fa = () => setOpen(true);
  const handleClose2fa = () => setOpen(false);

  const onTokenCode = (e) => {
    setTokenCode(e?.target?.value);
    setTokenCodeError(validateTokenCode(e?.target?.value));
  };

  const onGenerateTwoFactor = async () => {
    await DataService.get(API.User.generateTwoFactor, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setScanImage(response?.data?.data?.twoFAqrCode);
        toast.success("2FA generated");
        setTokenCode("");
        setTimeout(() => {
          handleOpen2Fa();
        }, 1000);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const onVerifyTwoFactor = async () => {
    const formData = new URLSearchParams();
    formData.append("token", tokenCode);
    if (tokenCode !== "") {
      await DataService.post(API.User.VerifyTwoFactor, formData, {
        headers: {
          auth: token,
        },
      })
        .then((response) => {
          toast.success("2FA authentication verified");
          dispatch(enabledFa());
          handleClose2fa();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          if (error.response.data.message === "Token not authorized") {
            dispatch(getAdminToken(""));
            dispatch(getAdminType(""));
            dispatch(getAdminData({}));
            navigate("/")
          }
        });
    } else {
      if (tokenCode === "") {
        setTokenCodeError("Please enter a code");
      }
    }
  };

  const onDisableTwoFactor = async () => {
    await DataService.get(API.User.DisableTwoFactor, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        dispatch(disabledFa());
        toast.success("2FA disabled");
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };
  return (
    <div>
      <ToastContainer />
      <Index.Box className="profile-main page-main-box">
        <Index.Typography className="page-titles" variant="h3" component="h3">
          Two-Factor Authentication
        </Index.Typography>
        <Index.Typography className="profile-para" variant="p" component="p">
          Two-factor authentication is a method for protection your web account.
          When it is activated you need to enter not only your password, but
          also a special code. You can receive this code by in mobile app. Even
          if third person will find your password, then can't access with that
          code.
        </Index.Typography>
        <Index.Box className="profile-btn-flex flex-all">
          <Index.PrimaryButton
            className="primary-btn fa2-btn"
            btnLabel={tokenType === "enabled" ? "Disable 2FA" : "Enable 2FA"}
            type="button
                    "
            onClick={() => {
              if (tokenType === "enabled") {
                onDisableTwoFactor();
              } else {
                onGenerateTwoFactor();
              }
            }}
          />
          <Index.Box className="profile-btn-left">
            <Index.TextButton
              className="text-btn current-btn"
              btnLabel="CURRENT STATUS:"
            />
            <Index.GreyButton
              className="grey-btn disable-btn"
              btnLabel={tokenType === "enabled" ? "Enabled" : "Disabled"}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose2fa}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="twofa-modal modal"
      >
        <Index.Box sx={style} className="modal-twofa-inner modal-inner">
          <Index.Box className="modal-header">
            <img
              src={Index.Svg.close}
              className="modal-close"
              onClick={handleClose2fa}
            />
          </Index.Box>
          <Index.Typography
            className="twofa-modal-title"
            variant="h6"
            component="h2"
          >
            Enable 2FA Authentication
          </Index.Typography>

          <Index.Box className="twofa-step-flex display-flex">
            <Index.Typography
              className="twofa-modal-step-text"
              variant="h6"
              component="h2"
            >
              <span className="twofa-modal-step-lable">Step 1:</span>install
              this app from{" "}
              <span className="twofa-highlight-text">
                Google Play or App Store
              </span>
            </Index.Typography>
          </Index.Box>

          <Index.Box className="twofa-step-flex display-flex">
            <Index.Typography
              className="twofa-modal-step-text"
              variant="h6"
              component="h2"
            >
              <span className="twofa-modal-step-lable">Step 2:</span>Scan the QR
              Code by your Google Authenticator app, or you can add account
              manually.
            </Index.Typography>
          </Index.Box>

          <Index.Box className="display-flex fa2-scanner-flex">
            <Index.Box className="wallet-saccner-main twofa-scanner-main">
              <img src={ScanImage} className="twofa-scanner-img" />
            </Index.Box>
            <Index.Box className="wallet-saccner-data">
              <Index.Typography className="wallet-scanner-data-text">
                Enter Google Authenticator Code
              </Index.Typography>

              <Index.Box className="input-box twofa-input-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    value={tokenCode}
                    inputProps={{ maxLength: 8 }}
                    onChange={onTokenCode}
                    className="form-control twofa-text-control"
                    placeholder="Enter the code to verify"
                  />
                </Index.Box>
                <p className="error-text">{tokenCodeError}</p>
              </Index.Box>
              <Index.PrimaryButton
                onClick={onVerifyTwoFactor}
                className="primary-btn twofa-btn"
                btnLabel="Confirm 2FA"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </div>
  );
};

export default TowFA;
