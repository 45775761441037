import React from "react";
import Index from "../../container/Index";
import { Link } from "react-router-dom";

const PrivateSaleWalletCard = (props) => {
  return (
      <Index.Box className={props.className}>
        <Index.Box className="private-card-flex">
          <Index.Box className={props.imgclass}>{props.img}</Index.Box>
          <Index.Box className="private-icon-text-main">
            <Index.Typography
              className="private-icon-text"
              component="h3"
              variant="h3"
            >
              {props.Label}
            </Index.Typography>
            <Index.Typography
              className="private-icon-price"
              component="h3"
              variant="h3"
            >
             {props.data}
              <span className="private-span-text">NCXT</span>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="private-card-wallet-flex">
          <Index.Box className={props.endClass}>
            <Link to={props.path} className="text-decoration">
              <Index.WhiteBorderButton
                className="white-border-btn dash-btn"
                btnLabel="HISTORY"
              />
            </Link>
          </Index.Box>
        </Index.Box>
      </Index.Box>
  
  );
};

export default PrivateSaleWalletCard;
