import React, { useState } from "react";
import Index from "../../../../component/Index";
import { validatePassword } from "../../../../component/common/AuthValidation";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const token = useSelector((store) => store.admin.token);
  const [showNewPasswordError, setShowNewPasswordError] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = useState(false);
  const handleMouseDownConfrimPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfrimPassword = () =>
    setShowConfrimPassword((show) => !show);

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };
  const [passwordFields, setPasswordFields] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const onConfirmPasswordError = (val) => {
    if (!val) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Password and confirmPassword must be same",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        confirmPassword: "",
      }));
    }
  };
  const onSubmitError = () => {
    if (passwordFields.oldPassword.length === 0) {
      setError((prev) => ({
        ...prev,
        password: "Please Enter old password",
      }));
    }
    if (passwordFields.newPassword.length === 0) {
      setError((prev) => ({
        ...prev,
        newPassword: "Please Enter new password",
      }));
    }
    if (passwordFields.confirmPassword.length === 0) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Please Enter confirm password",
      }));
    }
  };

  const onHandleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "confirmPassword") {
      onConfirmPasswordError(value === passwordFields.newPassword);
    }
    if (name === "oldPassword") {
      if (value.length > 0) {
        setError((prev) => ({
          ...prev,
          password: "",
        }));
      }
    }
    if (name === "newPassword") {
      if (value.length > 0) {
        setShowNewPasswordError(validatePassword(value));
        setError(() => ({
          newpassword: "",
        }));
      }
    }
    if (name === "confirmPassword") {
      if (value.length > 0) {
        setError((prev) => ({
          ...prev,
          confirmPassword: "",
        }));
      }
    }
    setPasswordFields({
      ...passwordFields,
      [name]: value,
    });
  };
  const onChangePassword = async () => {
    if (
      passwordFields.newPassword === passwordFields.confirmPassword &&
      passwordFields?.oldPassword?.length > 0 &&
      passwordFields?.newPassword?.length > 0 &&
      passwordFields?.confirmPassword?.length > 0
    ) {
      const formData = new URLSearchParams();
      formData.append("oldPassword", passwordFields.oldPassword);
      formData.append("newPassword", passwordFields.newPassword);
      await DataService.post(API.Admin.CHANGE_PASSWORD, formData, {
        headers: {
          auth: token,
        },
      })
        .then((response) => {
          toast(response?.data?.message);
          setPasswordFields({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        })
        .catch((error) => {
          toast(error?.response?.data?.message);
        });
    } else {
      onConfirmPasswordError(
        passwordFields.newPassword === passwordFields.confirmPassword
      );
      onSubmitError();
    }
  };
  const onDiscard = () => {
    setPasswordFields({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <Index.Box className="tabpanel-main">
      <ToastContainer />
      <Index.Box className="change-pass-main">
        <Index.Typography
          className="common-para edit-highlight-text"
          component="p"
          variant="p"
        >
          Password
        </Index.Typography>
        <Index.Typography
          className="common-para edit-para-text"
          component="p"
          variant="p"
        >
          Enter your current & new password to reset your password
        </Index.Typography>
        <Index.Box className="input-box profile-input-box">
          <Index.FormHelperText className="form-lable">
            Old Password
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.OutlinedInput
              className="form-control-eye form-control"
              value={passwordFields.oldPassword}
              placeholder="Old Password"
              name="oldPassword"
              onChange={onHandleInputChange}
              id="outlined-adornment-password"
              type={showOldPassword ? "text" : "password"}
              endAdornment={
                <Index.InputAdornment position="end">
                  <Index.IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownOldPassword}
                    edge="end"
                  >
                    {showOldPassword ? (
                      <Index.VisibilityOff />
                    ) : (
                      <Index.Visibility />
                    )}
                  </Index.IconButton>
                </Index.InputAdornment>
              }
            />
          </Index.Box>
          <p className="error-text">{error.password}</p>
        </Index.Box>
        <Index.Box className="input-box profile-input-box">
          <Index.FormHelperText className="form-lable">
            New Password
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.OutlinedInput
              className="form-control-eye form-control"
              value={passwordFields.newPassword}
              placeholder="New Password"
              name="newPassword"
              onChange={onHandleInputChange}
              id="outlined-adornment-password"
              type={showNewPassword ? "text" : "password"}
              endAdornment={
                <Index.InputAdornment position="end">
                  <Index.IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? (
                      <Index.VisibilityOff />
                    ) : (
                      <Index.Visibility />
                    )}
                  </Index.IconButton>
                </Index.InputAdornment>
              }
            />
          </Index.Box>
          <p className="error-text">
            {showNewPasswordError}
            {error.newPassword}
          </p>
        </Index.Box>
        <Index.Box className="input-box profile-input-box">
          <Index.FormHelperText className="form-lable">
            Confirm New Password
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.OutlinedInput
              className="form-control-eye form-control"
              value={passwordFields.confirmPassword}
              placeholder="Confirm New Password"
              onChange={onHandleInputChange}
              name="confirmPassword"
              id="outlined-adornment-password"
              type={showConfrimPassword ? "text" : "password"}
              endAdornment={
                <Index.InputAdornment position="end">
                  <Index.IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfrimPassword}
                    onMouseDown={handleMouseDownConfrimPassword}
                    edge="end"
                  >
                    {showConfrimPassword ? (
                      <Index.VisibilityOff />
                    ) : (
                      <Index.Visibility />
                    )}
                  </Index.IconButton>
                </Index.InputAdornment>
              }
            />
          </Index.Box>
          <p className="error-text">{error.confirmPassword}</p>
        </Index.Box>

        <Index.Box className="user-btn-flex comman-btn-main change-pass-flex">
          <Index.Box className="discard-btn-main border-btn-main">
            <Index.Button
              className="discard-user-btn border-btn"
              onClick={onDiscard}
            >
              Discard
            </Index.Button>
          </Index.Box>
          <Index.Box className="save-btn-main ">
            <Index.Button
              className="save-user-btn primary-btn"
              onClick={onChangePassword}
            >
              <img
                src={Index.Svg.save}
                alt="save"
                className="user-save-icon"
              ></img>
              Save
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default ChangePassword;
