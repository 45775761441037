import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

const VerifyUser = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const params = useParams();
  const { id } = params;

  const verify = async () => {
    const formData = new URLSearchParams();
    const code = localStorage.getItem("referCode");
    const Id = localStorage.getItem("User_id");
    formData.append("eVerificationId", id);
    formData.append("id", Id);
    if (code) {
      formData.append("referCode", code);
    }

    await DataService.post(API.User.EmailVerify, formData)
      .then((response) => {
        setError(false);
        if (response.data.message !== "Email already verified") {
          navigate("/sign-in");
        } else {
          navigate("/sign-in");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setError(true);
      });
  };
  useEffect(() => {
    verify();
  }, []);
  return (
    <Typography variant="h3" gutterBottom align="center">
     {/* {error ? "Please Verify your email" : "Email verified"} */}
    </Typography>
  );
};

export default VerifyUser;
