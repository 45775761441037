import React from "react";
import Index from "../../../../component/Index";
import { useState } from "react";
import moment from "moment";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const Purchase = () => {
  const token = useSelector((store) => store.admin.token);
  const [isFetching, setIsFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checklevel = (item) => {
    let res = "";

    if (item === 0) {
      res = "Pay With USDT";
      return res;
    } else if (item === 1) {
      res = "Pay with any Tokens";
      return res;
    } else {
      res = "-";
      return res;
    }
  };
  const checkdata = (item) => {
    if(item){
      return item ;
    }else{
      return '-'
    }
  }
  const fetchData = async () => {
    setIsFetching(true);
    await DataService.get(API.Admin.TOTAL_PURCHASE, {
      headers: {
        auth: token,
      },
    })
      .then((res) => {
        const rows = res.data.data.map((item) => {
          let re = checklevel(item?.paymentType);
          return {
            tranxId: checkdata(item?.tranxId),
            userName: checkdata(item?.UserId?.userName),
            clientId: checkdata(item?.UserId?.ncxUserId),
            email: checkdata(item?.UserId?.email),
            roundId: checkdata(item?.roundId?.phaseName),
            ncxtCreditedToken: checkdata(item?.ncxtCreditedToken),
            usdtAmount: checkdata(item?.requestUsdt),
            requestedToken: checkdata(item?.requestToken),
            bonus: checkdata(item?.bonusToken),
            paymentType: re,
            total: checkdata(item?.totalToken),
            status: checkdata(item?.status),
            tranxIdNcx: checkdata(item?.tranxIdOnNcx),
            usdtTaken: checkdata(item?.requestUsdt),
            status: checkdata(item?.status),
            proceedBy: checkdata(item?.processedBy?.adminName),
            updatedAt: checkdata(item?.updatedAt),
            createdAt: checkdata(item?.createdAt),
            id: item._id,
          };
        });
        setIsFetching(false);
        setUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const columns = [
    {
      field: "tranxId",
      headerName: "TranxId",
      width: "120",
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: "150",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "clientId",
      headerName: "Client ID",
      width: "150",
    },
    {
      field: "userName",
      headerName: "Name Surname",
      width: "150",
    },
    {
      field: "email",
      headerName: "Email",
      width: "150",
    },
    {
      field: "roundId",
      headerName: "Round Name",
      width: "150",
    },
    {
      field: "usdtAmount",
      headerName: "USDT Amount",
      width: "100",
    },
    {
      field: "requestedToken",
      headerName: "Requested Token",
      width: "100",
    },

    {
      field: "bonus",
      headerName: "Bonus",
      width: "100",
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      width: "100",
    },
    {
      field: "total",
      headerName: "Total",
      width: "100",
    },
    {
      field: "status",
      headerName: "Status",
      width: "150",
    },
    {
      field: "tranxIdNcx",
      headerName: "Tranx ID NCX",
      width: "150",
    },
    {
      field: "usdtTaken",
      headerName: "USDT Taken",
      width: "150",
    },
    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "150",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "ncxtCreditedToken",
      headerName: "NCXT Credited",
      width: "100",
    },
    {
      field: "proceedBy",
      headerName: "Proceed By",
      width: "100",
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ height: 530, width: "100%" }}>
          <Index.DataGrid
            className="user-data-head"
            rows={users}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Purchase;
