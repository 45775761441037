import usericon from './images/png/user.png';
// import logo from './images/png/logo.png';
import edit from './images/png/edit.png';
import logout from './images/png/logout.png'
import setting from './images/png/setting.png'
import profile from './images/png/profile.png'
import footerplaystorescanner from './images/png/footer-playstore-scanner.png'



const Png = {
      usericon,
      // logo,
      edit,
      setting,
      logout,
      profile,
      footerplaystorescanner,
}

export default Png;