import React, {useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Index from "../../../../component/Index";

const Wallet = () => {
  const [users, setUsers] = useState([]);

  const columns = [
    {
      field: "name",
      headerName: "Username",
      width: "350",
    },

    {
      field: "transactionId ",
      headerName: "Transaction Id",
      width: "350",
    },

    {
      field: "amount",
      headerName: "Amount",
      width: "300",
    },
  ];
  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Wallet
                </Index.Typography>
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <DataGrid
                    className="user-data-head"
                    rows={users}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Wallet;
