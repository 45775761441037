import React from "react";
import Index from "../../../../component/Index";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

const ITEM_HEIGHT = 48;

const ClaimAirDrop = () => {
  const token = useSelector((store) => store.admin.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [claimUsers, setClaimUsers] = useState([]);
  const [editId, setEditId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const checklevel = (item) => {
    let res = "";

    if (item === true) {
      res = "Purchase";
      return res;
    } else if (item === false) {
      res = "Non Purchase";
      return res;
    } else {
      res = "-";
      return res;
    }
  };
  const fetchClaimData = async () => {
    setIsFetching(true);
    await DataService.get(API.Admin.CLAIM_AIRDROP, {
      headers: {
        auth: token,
      },
    })
      .then((res) => {
        setIsFetching(false);
        const rows = res.data.data.map((item) => {
          let re = checklevel(item.purchasedOnNcx);
          return {
            name: item?.userName,
            ncxUserId: item?.ncxUserId ? item?.ncxUserId : "-",
            id: item._id,
            email: item.email,
            countryId: item?.countryId?.countryName,
            purchasedOnNcx: re,
            Register: item.registeredOnNcx === true ? "Yes" : "No",
            twoFactor: item.twoFactor === true ? "Yes" : "No",
          };
        });
        setClaimUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login")
        }
      });
  };

  const claimOptions = [
    <Index.Button
      variant="contained"
      onClick={() => {
        navigate(`/admin/view`, { state: { id: editId } });
      }}
    >
      View
    </Index.Button>,
  ];
  const claimColumns = [
    {
      field: "ncxUserId",
      headerName: "Client ID",
      width: "200",
    },
    {
      field: "name",
      headerName: "User Name",
      width: "200",
    },
    {
      field: "email",
      headerName: "Email",
      width: "250",
    },
    {
      field: "countryId",
      headerName: "country",
      width: "150",
    },
    {
      field: "purchasedOnNcx",
      headerName: "Purchased On Ncx",
      width: "110",
    },
    {
      field: "Register",
      headerName: "Register On Ncx",
      width: "120",
    },
    {
      field: "twoFactor",
      headerName: "2FA",
      width: "80",
    },
    {
      field: "actions",
      headerName: "Action",
      width: "70",
      renderCell: (params) => {
        return (
          <>
            <Index.IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setEditId(params?.id);
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
            <Index.Menu
              className=""
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open1}
              onClose={handleClose1}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "100px",
                  boxShadow: "none",
                  border: "1px solid rgba(224, 224, 224, 1)",
                  marginTop: "6px",
                },
              }}
            >
              {claimOptions.map((claimOptions) => (
                <Index.MenuItem
                  key={claimOptions}
                  selected={claimOptions === "Pyxis"}
                  onClick={handleClose1}
                  className="menu-option-li"
                >
                  {claimOptions}
                </Index.MenuItem>
              ))}
            </Index.Menu>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    fetchClaimData();
  }, []);

  return (
    <div>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ height: 530, width: "100%" }}>
          <Index.DataGrid
            className="user-data-head"
            rows={claimUsers}
            columns={claimColumns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ClaimAirDrop;
