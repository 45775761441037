import axios from "axios";


// const API_ENDPOINT = "http://localhost:3015/api/";
const API_ENDPOINT = "https://ncxt.ncx.cx/api/";

// const API_ENDPOINT = "http://35.177.56.74:3015/api/"


const DataService = axios.create({
baseURL: API_ENDPOINT,
});

export { DataService, API_ENDPOINT };
