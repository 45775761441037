import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import AddUser from "../container/admin/pages/user/AddUser";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import Login from "../container/admin/auth/Login";
import EditProfile from "../container/admin/pages/editprofile/EditProfile";
import ForgotPassword from "../container/admin/auth/ForgotPassword";
import UserList from "../container/admin/pages/user/UserList";
import SignUp from "../component/user/auth/signup/SignUp";
import SignIn from "../component/user/auth/signin/SignIn";
import ForgotPasswordUser from "../component/user/auth/forgotpassword/ForgotPassword";
import Profile from "../container/user/pages/profile/Profile";
import UserDashboard from "../container/user/pages/dashboard/UserDashboard";
import PrivateSaleFinal from "../container/user/pages/privatesalefinal/PrivateSaleFinal";
import PrivateSale from "../container/user/pages/privatesale/PrivateSale";
import Transaction from "../container/user/pages/transaction/Transaction";
import PrivateSaleWallet from "../container/user/pages/privatesalewallet/PrivateSaleWallet";
import ResetPassword from "../component/user/auth/ResetPassword/ResetPassword";
import VerifyUser from "../component/defaulLayout/user/VerifyUser";
import { useSelector } from "react-redux";
import Wallet from "../container/admin/pages/wallet/Wallet";
import Referral from "../container/user/pages/referral/Referral";
import Home from "../container/user/pages/Home/Home";
import PrivateAdminSalefrom from "../container/admin/pages/privateSale/PrivateAdminSale";
import PurchaseHistoty from "../container/admin/pages/purchaseHistory/PurchaseHistoty"
import AirdroipAndReffals from "../container/admin/pages/airdropAndReffals/AirdroipAndReferrals";
import WithdrawalHistory from "../container/admin/pages/withdrawalHistory/WithdrawalHistory";
import PresaleRound from "../container/admin/pages/privateSale/PresaleRound";
import RoadMap from "../container/admin/pages/roadMap/RoadMap";
import View from "../container/admin/pages/user/View";
import AddRoadMap from "../container/admin/pages/roadMap/AddRoadMap";
import Reffals from "../container/admin/pages/airdropAndReffals/Referrals";
import Bonus from "../container/admin/pages/bonus/Bonus";
import AddBonus from "../container/admin/pages/bonus/AddBonus";
import BlockedUsers from "../container/admin/pages/blockedUsers/BlockedUsers";
import AirdroipAndReferrals from "../container/admin/pages/airdropAndReffals/AirdroipAndReferrals";
import AirdropRefferalsAndEdit from "../container/admin/pages/airdropAndRefferals/AirdropRefferalsAndEdit";




const Routers = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const auth = useSelector((store) => store.admin.isAuthenticated);
  useEffect(() => {
    if (path.pathname !== "/" && path.pathname.includes("/dashboard") && !auth) {
      navigate("/");
    } else if (path.pathname.includes("/admin") && !auth) {
      navigate("admin/login");
    }
  }, [path]);
  return (
    <Routes>
      <Route path="admin/login" element={<Login />} />
      <Route path="admin/forgot-password" element={<ForgotPassword />} />
      <Route path="admin" element={<Dashboard />}>
        <Route path="edit-profile" element={<EditProfile />} />
        <Route path="user" element={<UserList />} />
        <Route path="purchase-histoty" element={<PurchaseHistoty />} />
        <Route path="airdroip-and-referrals" element={<AirdroipAndReffals />} />
        <Route path='airdrop-edit' element={<AirdropRefferalsAndEdit />} />
        <Route path="withdrawal-history" element={<WithdrawalHistory />} />
        <Route path="presale-round" element={<PresaleRound />} />
        <Route path="road-map" element={<RoadMap />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="add-user" element={<AddUser />} />
        <Route path="add-roadmap" element={<AddRoadMap />} />
        <Route path="bonus" element={<Bonus />} />
        <Route path="add-bonus" element={<AddBonus />} />
        <Route path="edit-bonus/:editId" element={<AddBonus />} />
        <Route path="edit-user/:editId" element={<AddUser />} />
        <Route path="edit-roadmap/:editId" element={<AddRoadMap />} />
        <Route path="View" element={<View />} />
        <Route path="referrals" element={<Reffals />} />
        <Route path="edit-privateadminsale/:editId" element={<PrivateAdminSalefrom />} />
        <Route path="privateadminsale" element={<PrivateAdminSalefrom />} />
        <Route path="blocked-users" element={<BlockedUsers />} />
      </Route>
      <Route path="sign-in" element={<SignIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="reset-password/:id" element={<ResetPassword />} />
      <Route path="email-verification/:id" element={<VerifyUser />} />
      <Route path="forgot-password" element={<ForgotPasswordUser />} />
      <Route path="dashboard/" element={<UserDashboard />} />
      <Route path="profile" element={<Profile />} />
      <Route path="private-sale" element={<PrivateSaleFinal />} />
      <Route path="privatesale" element={<PrivateSale />} />
      <Route path="transactions" element={<Transaction />} />
      <Route path="wallets" element={<PrivateSaleWallet />} />
      <Route path="refferals" element={<Referral />} />
      <Route path="" element={<Home />} />
    </Routes>
  );
};

export default Routers;
