import React from "react";
import Index from "../Index";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ListTab = (props) => {
  return (
    <div>
      <Index.Tabs
        value={props.value}
        onChange={props.onChange}
        aria-label="basic tabs example"
        className="admin-tabs-main"
      >
        <Index.Tab
          label={props.label1}
          {...a11yProps(0)}
          className="admin-tab"
          disableRipple
        />
        <Index.Tab
          label={props.label2}
          {...a11yProps(1)}
          className="admin-tab"
          disableRipple
        />
      </Index.Tabs>

      <TabPanel value={props.tabVal} index={0} className="admin-tabpanel">
        {props.children1}
      </TabPanel>
      <TabPanel value={props.tabVal} index={1} className="admin-tabpanel">
        {props.children2}
      </TabPanel>
    </div>
  );
};

export default ListTab;
