import React, { useState } from "react";
import Index from "../../Index";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LoginAdmin } from "../../../redux/features/slices/Admin/AdminService";
import { getAdminData, getAdminToken, getAdminType } from "../../../redux/features/slices/Admin/AdminSlice";
import "react-toastify/dist/ReactToastify.css";
import { validateEmail, validatePassword } from "../../../component/common/AuthValidation";


export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passWordError, setPassWordError] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmail = (e) => {
    setEmailError(validateEmail(e.target.value));
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassWordError(validatePassword(e.target.value));
    setPassword(e.target.value);
  };

  const handleFormSubmit = () => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", password);
    dispatch(LoginAdmin(formData)).then((response) => {
      dispatch(getAdminToken(response?.payload?.data?.token));
      dispatch(getAdminType(response?.payload?.data?.adminType));
      dispatch(getAdminData(response?.payload?.data))
      if (response.payload) {
        toast.success("Logged In!");
        setTimeout(() => {
          navigate("/admin/user");
        }, 2000);
      } else {
        if(email===""){
          setEmailError("Please enter email");
        }
        if(password==""){
          setPassWordError("Please enter password");
        }
      }
    });
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Svg.logo}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>
              <Index.Box className="card-login-main">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Welcome back!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter your credentials to sign in!
                </Index.Typography>
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Email
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      value={email}
                      onChange={handleEmail}
                      className="form-control"
                    />
                  </Index.Box>
                  <p className="error-text">
                    {emailError}
                  </p>
                  <ToastContainer />
                </Index.Box>
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye"
                      value={password}
                      onChange={handlePassword}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">
                    {passWordError}
                  </p>
                </Index.Box>
                
                <Index.Box className="flex-all forgot-row">
                  <Index.Box className="checkbox-main">
                    <Index.FormControlLabel
                      control={<Index.Checkbox />}
                      label="Remember Me"
                      className="checkbox-lable"
                    />
                  </Index.Box>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="admin-forgot-para common-para"
                  >
                    <Index.Link
                      to="/admin/forgot-password"
                      style={{ textDecoration: "none" }}
                    >
                      Forgot Password?
                    </Index.Link>
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="btn-main login-btn-main">
                  <Index.Button
                    className="btn login-btn"
                    onClick={handleFormSubmit}
                    type="submit"
                    disableRipple
                  >
                    Login
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
