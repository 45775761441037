import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import { Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
  userLogout,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import { ToastContainer, toast } from "react-toastify";
// payment modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
};

// payment radio
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 21,
  height: 21,
  boxShadow: "none",
  display: "flex",
  border: "1px solid #6E66E6",
  backgroundColor: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  // '.Mui-focusVisible &': {
  //   outline: '2px auto rgba(19,124,189,.6)',
  //   outlineOffset: 2,

  // },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#ffffff",
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  "&:before": {
    display: "block",
    width: 15,
    height: 15,
    backgroundColor: "#6E66E6",
    content: '""',
    borderRadius: "50%",
    margin: "auto",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Index.Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function PrivateSale(props) {
  // start checkbox

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 0,
    border: "1px solid #E6EFFB",
    width: 16,
    height: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#FFFFFF",
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "transparent",
    "&:before": {
      display: "block",
      width: 12,
      height: 12,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%236E66E6'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
  });

  // Inspired by blueprintjs
  function BpCheckbox(props) {
    return (
      <Index.Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }

  const location = useLocation();
  const dispatch = useDispatch();
  // progress bar
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);

  // payment modal
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const handleOpenPayment = () => setOpen(true);
  const handleClosePayment = () => setOpen(false);
  // wallet modal
  const [walletOpen, setWalletOpen] = useState(false);
  const handleOpenWallet = () => navigate("/privatesale");
  const [amount, setAmount] = useState("");
  const [selected1, setSelected1] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const [data, setData] = useState({});

  const [NCXTAmount, setNCXTAmount] = useState(0);
  const [bonusAmount, setBonusAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [bonusList, setBonusList] = useState([]);
  const [radioButton, setRadioButton] = useState("");
  const token = useSelector((store) => store.admin.userToken);
  const balanceData = useSelector((store) => store.admin.userBalanceData);
  const currentRoundData = useSelector(
    (store) => store.admin.upcomingCurrentRoundData
  );

  const navigate = useNavigate();

  const handleCloseWallet = () => {
    setWalletOpen(false);
  };
  const handleOpenmodalWallet = () => {
    setWalletOpen(true);
  };

  const handleOpenWalletPage = () => {
    navigate("/wallets");
    setWalletOpen(false);
  };

  const handleFinishNavigate = () => {
    setWalletOpen(false);
    navigate("/private-sale");
  };
  const handleRadioChange = (event) => {
    setRadioButton(event.target.value);
  };

  const getBonusList = async () => {
    await DataService.post(
      API.User.getBonusList,
      {},
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((response) => {
        setBonusList(response?.data?.data);

        console.log(bonusList)
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };

  const handlePaymentData = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("paymentType ", radioButton);
    urlencoded.append("requestUsdt", amount);
    await DataService.post(API.User.purchaseToken, urlencoded, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setData(response?.data?.data);
        handleOpenWallet();
        toast(response?.data?.message);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
        if (
          error?.response?.data?.message === "Maximum 1 attempt left" ||
          error?.response?.data?.message === "Maximum 2 attempt left" ||
          error?.response?.data?.message === "Maximum 3 attempt left"
        ) {
          handleOpenConfirm();
          toast.warn(error?.response?.data?.message);
        }
        if (error?.response?.data?.message === "Account Blocked") {
          toast.error(
            "Your account has been blocked due to multiple purchase orders with insufficient balance on NCX"
          );
          setTimeout(() => {
            dispatch(userLogout());
            navigate("/");
          }, 3000);
        }
        if (
          error?.response?.data?.message ===
          "Client ID is compulsory to buy a token on NCX"
        ) {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    getBonusList();
  }, []);

  useEffect(() => {
    setTotal(NCXTAmount + bonusAmount);
  }, [amount]);

  const onChangeAmount = (e) => {
    if (e.target.value !== "") {
      if (e.target.value < currentRoundData?.current[0]?.minimumPurchase) {
        setError("Amount cannot be less than minimum purchase");
      } else {
        setError("");
      }

      
      setAmount(e.target.value);
      const bonus = bonusList.find((data) => {
        if (data.startPrice && data.endPrice) {
          return (
            parseInt(e.target.value) > data.startPrice &&
            parseInt(e.target.value) <= data?.endPrice
          );
        }
        if (data.startPrice && !data?.endPrice) {
          return parseInt(e.target.value) > data.startPrice;
        }
      });

      

      setBonusAmount(
        ((parseInt(e.target.value) /
          (location?.state?.roundData?.tokenPrice ||
            currentRoundData?.current[0]?.tokenPrice)) *
          (bonus?.bonusPercent || 0)) /
        100
      );

      console.log()
      setNCXTAmount(
        (parseInt(e.target.value) /
          (location?.state?.roundData?.tokenPrice ||
            currentRoundData?.current[0]?.tokenPrice)));
    } else {
      setAmount("");
      setNCXTAmount(0);
      setBonusAmount(0);
    }
  };

  return (
    <>
      <ToastContainer />
      <Index.Header />
      <Index.Box className="page-wrapper">
        <Index.Box className="container">
          <Index.Box className="page-inner-main">
            <Index.Box className="page-left-main">
              <Index.UserSidebar />
            </Index.Box>
            <Index.Box className="page-right-main private-sale-right-main">
              <Index.Box className="private-sale-main">
                <Index.Box className="private-sale-row display-flex">
                  <Index.Box className="private-sale-col-left">
                    <Index.Box className="private-sale-data-main">
                      <Index.Typography
                        className="private-sale-titles"
                        variant="h4"
                        component="h4"
                      >
                        Buy NCX Token with USDT
                      </Index.Typography>
                      <Index.Typography
                        className="private-sale-para"
                        variant="p"
                        component="p"
                      >
                        Enter the USDT amount you would like to contribute and
                        calculate the amount of NCX tokens you will receive
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="private-sale-flex private-sale-usd-main-flex">
                      <Index.Box>
                        <Index.Box className="private-sale-usd-box">
                          <Index.Typography className="private-sale-usd-text">
                            <img
                              src={Index.Svg.usd}
                              className="private-sale-usd-icons"
                            />
                            USDT
                          </Index.Typography>
                          <Index.TextField
                            value={amount}
                            inputProps={{ maxLength: 10 }}
                            id="standard-basic"
                            variant="standard"
                            onChange={onChangeAmount}
                            onWheel={(e) => e.target.blur()}
                            className="private-sale-usd-para"
                            placeholder="Enter Amount"
                          />
                        </Index.Box>
                        <p className="error-text">{error}</p>
                      </Index.Box>
                      <Index.Box className="private-sale-usd-data">
                        <Index.Typography className="private-sale-data-equal">
                          =
                        </Index.Typography>
                        <Index.Box className="private-sale-usd-data-inner">
                          <Index.Typography className="private-sale-data-lable">
                            NCXT
                          </Index.Typography>
                          <Index.Typography className="private-sale-usd-amount">
                            {NCXTAmount !== 0
                              ? NCXTAmount?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                                .split("₹")[1]
                                .split(".")[0]
                              : "0.00"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="private-sale-flex private-sale-ckeckbox-flex">
                      <Index.Box className="private-checkbox-main display-flex">
                        <Index.FormControlLabel
                          control={
                            <BpCheckbox
                              checked={selected1}
                              onChange={(e) => {
                                setSelected1(e.target.checked);
                              }}
                            />
                          }
                          className="checkbox-lable"
                        />
                        <Index.Typography className="private-checkbox-lable">
                          I am not United States Resident{" "}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="private-checkbox-main display-flex">
                        <Index.FormControlLabel
                          control={
                            <BpCheckbox
                              checked={selected2}
                              onChange={(e) => {
                                setSelected2(e.target.checked);
                              }}
                            />
                          }
                          className="checkbox-lable"
                        />
                        <Index.Typography className="private-checkbox-lable">
                          I agree with NCX Terms of Service
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="private-amount-main">
                      <Index.Box className="private-amount-col">
                        <Index.Typography
                          className="private-amount-lable"
                          variant="p"
                          component="p"
                        >
                          + AMOUNT BONUS
                        </Index.Typography>
                        <Index.Typography
                          className="private-amount-data"
                          variant="p"
                          component="p"
                        >
                          {bonusAmount !== 0
                            ? bonusAmount
                              ?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })
                              .split("₹")[1]
                              .split(".")[0]
                            : "0.00"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="private-amount-col">
                        <Index.Typography
                          className="private-amount-lable"
                          variant="p"
                          component="p"
                        >
                          + TOTAL NCXT
                        </Index.Typography>
                        <Index.Typography
                          className="private-amount-data"
                          variant="p"
                          component="p"
                        >
                          {total !== 0
                            ? (total )
                              ?.toLocaleString("en-US", {
                                // style: "currency",
                                currency: "INR",
                              })
                            : "0.00"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Typography
                      className="private-amount-para"
                      variant="p"
                      component="p"
                    >
                      Your contribution will be calculated based on exchange
                      rate at the moment your transaction is confirmed.
                    </Index.Typography>

                    <Index.PrimaryButton
                      className="primary-btn make-payment-btn"
                      btnLabel="Make Payment"
                      onClick={handleOpenPayment}
                      disabled={
                        !selected1 ||
                        !selected2 ||
                        amount < currentRoundData?.current[0]?.minimumPurchase
                      }
                    />

                    <Index.Box className="peivate-sale-border"></Index.Box>
                    <Index.Box className="display-flex private-error-token-flex">
                      <img
                        src={Index.Svg.primaryerror}
                        className="primary-error-icon"
                      />
                      <Index.Typography
                        className="private-token-para"
                        variant="p"
                        component="p"
                      >
                        Tokens will appear in your account after payment
                        successfully made and approved by NCX team.
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="private-sale-col-right">
                    <Index.Box className="private-sale-card">
                      <Index.Box className="dashboard-card private-sale-card-1">
                        <Index.Box className="dash-card-flex">
                          <Index.Box className="dash-icon-main">
                            <img
                              src={Index.Svg.ncxlogoicon}
                              className="dash-icons"
                            />
                          </Index.Box>
                          <Index.Box className="dash-icon-text-main">
                            <Index.Typography
                              className="dash-icon-text"
                              component="h3"
                              variant="h3"
                            >
                              Your Token Balance
                            </Index.Typography>
                            <Index.Typography
                              className="dash-icon-price"
                              component="h3"
                              variant="h3"
                            >
                              {balanceData?.reward !== 0
                                ? balanceData?.reward
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00"}
                              <span className="dash-span-text">NCXT</span>
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="dash-icon-text-main">
                          <Index.Typography
                            className="dash-icon-text"
                            component="h3"
                            variant="h3"
                          >
                            Balance Breakdown
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="dash-card-content-flex private-card-space-between">
                          <Index.Box className="dash-card-content">
                            <Index.Typography
                              className="dash-price-text"
                              component="h3"
                              variant="h3"
                            >
                              {balanceData?.purchaseToken !== 0
                                ? balanceData?.purchaseToken
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-price-label"
                              component="h3"
                              variant="h3"
                            >
                              PURCHASED
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dash-card-content">
                            <Index.Typography
                              className="dash-price-text"
                              component="h3"
                              variant="h3"
                            >
                              {balanceData?.refferalReward &&
                                balanceData?.welcomeReward

                                ? (
                                  balanceData?.refferalReward +
                                  balanceData?.welcomeReward + balanceData?.bonus
                                )
                                  ?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .split("₹")[1]
                                  .split(".")[0]
                                : "0.00"}
                            </Index.Typography>
                            <Index.Typography
                              className="dash-price-label"
                              component="h3"
                              variant="h3"
                            >
                              AIRDROP & REFERRALS
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="private-sale-price-card">
                      <Index.Box className="price-card-main private-price-card-main">
                        <Index.Box className="price-card-inner">
                          <Index.Box className="price-flex display-flex">
                            <Index.Typography
                              className="card-price"
                              component="h3"
                              variant="h3"
                            >
                              {currentRoundData?.current[0]?.phaseName} -{" "}
                              <span className="price-span">
                                $
                                {currentRoundData?.current[0]?.tokenPrice
                                  ? currentRoundData?.current[0]?.tokenPrice
                                  : "0.00"}
                              </span>
                            </Index.Typography>
                            <Index.GreenButton
                              className="green-btn running-btn"
                              btnLabel="RUNNING"
                            />
                          </Index.Box>

                          <Index.Box className="dash-token-flex">
                            <Index.Box className="dash-token-data-content">
                              <Index.Typography
                                className="dash-token-data-text"
                                component="p"
                                variant="p"
                              >
                                TOKEN PRICE:
                              </Index.Typography>
                              <Index.Typography
                                className="dash-token-data-price"
                                component="h4"
                                variant="h5"
                              >
                                1 NCXT = $
                                {currentRoundData?.current[0]?.tokenPrice !== 0
                                  ? currentRoundData?.current[0]?.tokenPrice
                                  : "0.00"}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="dash-token-data-content">
                              <Index.Typography
                                className="dash-token-data-text"
                                component="p"
                                variant="p"
                              >
                                PAYMENT METHOD
                              </Index.Typography>
                              <Index.Typography
                                className="dash-token-data-price"
                                component="h4"
                                variant="h5"
                              >
                                {currentRoundData?.current[0]?.paymentMethod}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="token-price-para">
                            Vesting Period:{" "}
                            {currentRoundData?.current[0]?.vestingPeriod} months
                            After {currentRoundData?.current[0]?.vestingPeriod}{" "}
                            months{" "}
                            {currentRoundData?.current[0]?.monthlyRelease}%
                            monthly release
                          </Index.Box>

                          <Index.Box className="progress-bar-main dash-progressbar">
                            <BorderLinearProgress
                              variant="determinate"
                              value={70}
                              className="progress-bar"
                            />
                            <span className="progress-bar-text">
                              {currentRoundData?.current[0]?.roundPercent}%
                            </span>
                          </Index.Box>

                          <Index.Box className="dash-token-flex">
                            <Index.PrimaryButton
                              className="primary-btn buy-token-btn"
                              btnLabel="BUY TOKENS"
                              onClick={handleOpenWallet}
                            />
                            <Index.Box className="dash-token-content">
                              <Index.Typography
                                className="dash-token-text"
                                component="p"
                                variant="p"
                              >
                                MINIMUM PURCHASE:
                              </Index.Typography>
                              <Index.Typography
                                className="dash-token-price"
                                component="h4"
                                variant="h5"
                              >
                                {currentRoundData?.current[0]?.minimumPurchase
                                  ? currentRoundData?.current[0]
                                    ?.minimumPurchase
                                  : "0.00"}{" "}
                                USDT
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="private-sale-card">
                      <Index.Box className="dashboard-card private-sale-card-2">
                        <Index.Typography
                          className="private-sale-card-title"
                          variant="h3"
                          component="h3"
                        >
                          {location?.state?.roundData?.phaseName} Bonus{" "}
                        </Index.Typography>
                        <Index.Box className="dash-card-content-flex private-card-content-flex private-card-wrap">
                          {bonusList.length > 0 &&
                            bonusList?.map((value) => {
                              return (
                                <Index.Box className="dash-card-content private-card-content privatsale-card-wrapper">
                                  <Index.Typography
                                    className="dash-price-text"
                                    component="h3"
                                    variant="h3"
                                  >
                                    {value !== 0 ? value?.bonusPercent : "0.00"}
                                    %
                                  </Index.Typography>
                                  <Index.Typography
                                    className="dash-price-label"
                                    component="h3"
                                    variant="h3"
                                  >
                                    {value !== 0 ? value?.startPrice : "0.00"}
                                    {value?.endPrice ? "-" : ""}
                                    {value !== 0 ? value?.endPrice : 0.0} USDT
                                  </Index.Typography>
                                </Index.Box>
                              );
                            })}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Footer />

      <Index.Modal
        open={open}
        onClose={handleClosePayment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="private-payment-modal modal"
      >
        <Index.Box
          sx={style}
          className="payment-modal-inner modal-inner scrollbar"
        >
          <Index.Typography
            className="payment-modal-title"
            variant="h6"
            component="h2"
          >
            Payment Process
          </Index.Typography>
          <Index.Typography
            className="payment-modal-para payment-modal-para-1"
            variant="h6"
            component="h2"
          >
            Make payment of{" "}
            <span className="modal-highlight-text">
              {
                parseFloat(amount)
                  ?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })
                  .split("₹")[1]
              }{" "}
              USDT{" "}
            </span>
            to receive{" "}
            <span className="modal-highlight-text">
              {
                NCXTAmount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "INR",
                })
                  .split("₹")[1]
                  .split(".")[0]
              }{" "}
              NCXT
            </span>{" "}
            token including bonus{" "}
            <span className="modal-highlight-text">
              {
                bonusAmount
                  ?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })
                  .split("₹")[1]
                  .split(".")[0]
              }{" "}
              NCXT
            </span>{" "}
          </Index.Typography>
          <Index.Typography
            className="payment-modal-para payment-modal-para-2"
            variant="h6"
            component="h2"
          >
            You can choose any of following payment method to make your payment.
            The tokens balance will appear in your account after successful
            payment.
          </Index.Typography>
          <Index.Typography
            className="payment-modal-title"
            variant="h6"
            component="h2"
          >
            Payment Method
          </Index.Typography>
          <Index.Box className="payment-modal-flex">
            <Index.FormControl>
              <Index.RadioGroup
                defaultValue="female"
                name="radio-buttons-group"
                sx={{ my: 1 }}
              >
                <Index.Box
                  className={`${radioButton === "0" ? "payment-modal-radio-box1" : ""
                    } payment-modal-radio-box radio-box-main`}
                >
                  <Index.FormControlLabel
                    value="0"
                    checked={radioButton === "0"}
                    onChange={handleRadioChange}
                    control={<BpRadio />}
                    label={
                      <Index.FormHelperText
                        id="female-helper-text"
                        className="radio-txt-main"
                      >
                        <Index.Box>
                          Pay with USDT held in your Wallet on NCX Platform
                        </Index.Box>
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="payment-error-para payment-modal-error-para"
                          >
                            <img
                              src={Index.Svg.primaryerror}
                              className="primary-error-icon"
                            />
                            The requested amount of USDT will be automatically
                            withdrawn from your NCX Wallet to complete the
                            purchase
                          </Index.Typography>
                        </Index.Box>
                      </Index.FormHelperText>
                    }
                  />
                </Index.Box>
                <Index.Box
                  className={`${radioButton === "1" ? "payment-modal-radio-box1" : ""
                    } payment-modal-radio-box radio-box-main`}
                >
                  <Index.FormControlLabel
                    value="1"
                    checked={radioButton === "1"}
                    onChange={handleRadioChange}
                    control={<BpRadio />}
                    label={
                      <Index.FormHelperText className="radio-txt-main">
                        <Index.Box>
                          Pay with any Tokens or USD held in your Wallet on NCX
                          Platform
                        </Index.Box>
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="payment-error-para payment-modal-error-para"
                          >
                            <img
                              src={Index.Svg.primaryerror}
                              className="primary-error-icon"
                            />
                            NCX will automatically convert Crypto or USD to a
                            requested amount of USDT and it will be
                            automatically withdrawn from your Wallet on NCX to
                            complete the purchase
                          </Index.Typography>
                        </Index.Box>
                      </Index.FormHelperText>
                    }
                  />
                </Index.Box>
              </Index.RadioGroup>
            </Index.FormControl>
          </Index.Box>

          <Index.Typography
            className="payment-condition-para"
            variant="p"
            component="p"
          >
            Requested payment will be automatically deducted from your NCX
            Wallet. You can view your balance held in your Wallet on NCX here -{" "}
            <Index.Link
              to="https://ncx.cx/wallet"
              className="payment-modal-links"
            >
              https://my.ncx.cx/wallet
            </Index.Link>
          </Index.Typography>
          <Index.PrimaryButton
            btnLabel="NCX Wallet"
            className="primary-btn payment-link-btn"
          />
          <Index.Typography
            className="payment-condition-para"
            variant="p"
            component="p"
          >
            Your requested payment will be automatically deducted from you NCX
            Wallet and you will see the payment in your Transactions on NCX -{" "}
            <Index.Link to="/" className="payment-modal-links">
              https://my.ncx.cx/history/transactions
            </Index.Link>
          </Index.Typography>
          <Index.PrimaryButton
            btnLabel="Transactions"
            className="primary-btn payment-link-btn"
          />
          <Index.Typography
            className="payment-condition-para"
            variant="p"
            component="p"
          >
            If you don’t have sufficient balance in your Wallet on NCX to
            complete the purchase, the request will be marked as ‘Failed - Due
            to Insufficient Funds’. Please Top Up your Wallet held on NCX -
            <Index.Link to="/" className="payment-modal-links">
              https://my.ncx.cx/tx/payment
            </Index.Link>{" "}
            and place a new NCX Token purchase order in NCX Token Dashboard
            again.
          </Index.Typography>
          <Index.PrimaryButton
            btnLabel="Top Up Wallet"
            className="primary-btn payment-link-btn"
          />
          <Index.Typography
            className="payment-condition-para private-new-token-para"
            variant="p"
            component="p"
          >
            and place a new NCX Token purchase order in NCX Token Dashboard
            again.
          </Index.Typography>
          <Index.PrimaryButton
            className="primary-btn payment-buy-token-btn"
            btnLabel="Complete Payment"
            disabled={radioButton === "" ? true : false}
            onClick={() => {
              handleClosePayment();
              handleOpenmodalWallet();
              handlePaymentData();
            }}
          />

          {/* <Index.Box className="display-flex payment-error-flex">
            <img src={Index.Svg.primaryerror} className="primary-error-icon" />
            <Index.Typography
              className="payment-error-para"
              variant="p"
              component="p"
            >
              Payment wallet address will appear or redirect you for payment
              after the order is placed.
            </Index.Typography>
          </Index.Box> */}
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={walletOpen}
        onClose={handleCloseWallet}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="wallet-modal modal"
      >
        <Index.Box
          sx={style}
          className="modal-wallet-inner modal-inner scrollbar"
        >
          <Index.Box className="modal-header">
            <img
              src={Index.Svg.close}
              className="modal-close"
              onClick={handleCloseWallet}
            />
          </Index.Box>
          <Index.Typography
            className="wallet-modal-title"
            variant="h6"
            component="h2"
          >
            Your Order no.
            {data.tranxId} has been placed successfully.
          </Index.Typography>

          <Index.Typography
            className="wallet-modal-para"
            variant="h6"
            component="h2"
          >
            Requested payment will be automatically deducted from your NCX
            Wallet
          </Index.Typography>

          <Index.PrimaryButton
            btnLabel="NCX Wallet"
            className="primary-btn wallet-link-btn"
          />

          <Index.Typography
            className="wallet-modal-para"
            variant="h6"
            component="h2"
          >
            You will see the payment in your Transactions History on NCX here
          </Index.Typography>

          <Index.PrimaryButton
            btnLabel="Transactions"
            className="primary-btn wallet-link-btn"
          />

          <Index.Typography
            className="wallet-modal-para"
            variant="h6"
            component="h2"
          >
            If you don’t have a sufficient balance in your Wallet on NCX to
            complete the purchase, the request will be marked as ‘Failed - Due
            to Insufficient Funds’. Please Top Up your Wallet held on NCX
          </Index.Typography>

          <Index.PrimaryButton
            btnLabel="Top Up Wallet"
            className="primary-btn wallet-link-btn"
          />

          <Index.Typography
            className="wallet-modal-para"
            variant="h6"
            component="h2"
          >
            and place a new NCX Token purchase order in NCX Token Dashboard
          </Index.Typography>
          <Index.Typography
            className="wallet-modal-subpara"
            variant="h5"
            component="h5"
          >
            Your Payment request will be processed within 30 minutes, once
            completed the NCX Tokens will be added in your Token Dashboard and
            the payment deducted from you wallet held on NCX.
          </Index.Typography>

          <Index.Box className="wallet-btn-modal-flex display-flex">
            <Index.PrimaryBorderButton
              className="primary-border-btn wallet-cancel-btn"
              btnLabel="Open your Wallet on NCX"
              onClick={handleOpenWalletPage}
            />
            <Index.PrimaryButton
              className="primary-btn wallet-refresh-btn"
              btnLabel="Finish"
              onClick={() => {
                handleFinishNavigate();
                handleOpenConfirm();
              }}
            />
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={confirmOpen}
        onClose={handleCloseConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="confirm-modal modal"
      >
        <Index.Box
          sx={style}
          className="modal-confirm-inner modal-inner scrollbar"
        >
          <Index.Box className="modal-header">
            <img
              src={Index.Svg.close}
              className="modal-close"
              onClick={handleCloseConfirm}
            />
          </Index.Box>
          <Index.Typography className="confirm-modal-title">
            Can not be Completed
          </Index.Typography>
          <Index.Typography className="confirm-modal-para">
            You have pending Token Purchase request. Please Wait until the
            current request is processed before placing another order.{" "}
          </Index.Typography>
          <Index.Typography className="confirm-modal-para">
            If you place multiple purchase orders with insufficient balance on
            NCX, your token purchase function will be permanently disabled.
          </Index.Typography>

          <Index.PrimaryButton
            className="primary-btn modal-return-btn"
            btnLabel="Return"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
        </Index.Box>
      </Index.Modal>

      <Index.GoToTop />
    </>
  );
}
