import { Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdminData, getAdminToken, getAdminType, userLogout } from "../../../redux/features/slices/Admin/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { claimAirDrop } from "../../../redux/features/slices/Admin/AdminSlice";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { toast } from "react-toastify";
import { alpha } from '@mui/material/styles';




const StyledMenu = styled((props) => (
  <Index.Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    marginTop: theme.spacing(0),
    color:
      theme.palette.mode === 'light' ? 'transparent' : theme.palette.grey[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));



let list = [
  { page: "Dashboard", path: "/dashboard/" },
  { page: "Private Sale", path: "/private-sale" },
  { page: "Wallet", path: "/wallets" },
  { page: "Purchase History", path: "/transactions" },
  { page: "Referral", path: "/refferals" },
  { page: "My Account", path: "/profile" },
  { page: "Sign Out", path: "/sign-in" },
  
];

let menu=["English","Gujarati","Hindi","Tamil","Kannad"]

export default function Header(props) {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openswitch = Boolean(anchorEl);
  const handleClickSwitch = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSwitch = () => {
    setAnchorEl(null);
  };



  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [onSet, setOnSet] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [showList, setShowList] = useState(false);
  const [showLanguage,setShowLanguage]=useState(false)
  const [selectedLanguage,setSelectedLanguage]=useState("English")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((store) => store.admin.isAuthenticated);
  const token = useSelector((store) => store.admin.userToken);
  const [address, setAddress] = useState("");
  useEffect(() => {
    if (location?.pathname === "/dashboard") {
      setSelected("Dashboard");
    }
    if (
      location?.pathname === "/private-sale" ||
      location.pathname === "/privatesale"
    ) {
      setSelected("Private Sale");
    }
    if (location?.pathname === "/wallets") {
      setSelected("Wallet");
    }
    if (location?.pathname === "/transactions") {
      setSelected("Purchase History");
    }
    if (location?.pathname === "/refferals") {
      setSelected("Referral");
    }
    if (location?.pathname === "/profile") {
      setSelected("My Account");
    }
  }, []);

  const connect = () => {
    if (typeof window.ethereum !== "undefined") {
      // Request account access if needed
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          // Get the user's address
          setAddress(accounts[0]);
          DataService.post(
            API.User.postWalletId,
            { walletAddress: accounts[0] },
            {
              headers: {
                auth: token,
              },
            }
          )
            .then((response) => { })
            .catch((error) => {
              toast.error(error.response.data.message);
              if (error.response.data.message === "Token not authorized") {
                dispatch(getAdminToken(""));
                dispatch(getAdminType(""));
                dispatch(getAdminData({}));
                navigate("/")
              }
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  let value = (
    <LightTooltip title={`${address}`}>
      <p
        className="turncate-text"
        style={{
          color: "white",
          marginRight: 255,
          maxWidth: "15%",
        }}
      >
        {address}
      </p>
    </LightTooltip>
  );
  return (
    <>
      <Index.Box
        className={
          location?.pathname == "/" ? "header-sec-trans" : "header-sec"
        }
      >
        <Index.Box className="container header-container">
          <Index.Box className="row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 4",
                    sm: "span 1",
                    md: "span 1",
                    lg: "span 1",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="header-col">
                    <Index.Box className="header-logo-box">
                      {auth && (
                        <Index.Button
                          onClick={() => {
                            setOnSet(!onSet);
                            document.body.classList[!onSet ? "add" : "remove"](
                              "body-overflow"
                            );
                          }}
                          className="mobile-menu-btn"
                        >
                          <img
                            src={Index.Svg.mobilemenu}
                            className="mobile-menu-icon"
                            alt="logo"
                          />
                        </Index.Button>
                      )}

                      {/* <img src={Index.Svg.ncxlogo} className="header-logo" /> */}
                      <img src={Index.Svg.ncxprimarylogoicon} className="header-logo" />
                      <Index.Typography className="token-logo-text white-token-text">
                        Token
                      </Index.Typography>
                      <img src={Index.Svg.squre} className="squre-icon" />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 4",
                    sm: "span 8",
                    md: "span 8",
                    lg: "span 8",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="header-col">
                    <Index.Box
                      className={
                        onSet ? "header-nav-main active" : "header-nav-main"
                      }
                    >
                      <Index.List className="header-nav-ul">
                        <Index.ListItem className="header-nav-li">
                          <a href="https://ncx.cx/buy-crypto" className="header-nav-link">
                            Buy Crypto
                          </a>
                        </Index.ListItem>
                        <Index.ListItem className="header-nav-li">
                          <a
                            href="https://trade.ncx.cx/"
                            className="header-nav-link"
                          >
                            Spot Trading
                          </a>
                        </Index.ListItem>
                        <Index.ListItem className="header-nav-li">
                          <a href="https://ncx.cx/derivatives" className="header-nav-link">
                            Derivatives
                          </a>
                        </Index.ListItem>
                        <Index.ListItem className="header-nav-li">
                          <a href="https://ncx.cx/markets" className="header-nav-link">
                            Markets
                          </a>
                        </Index.ListItem>
                        <Index.ListItem className="header-nav-li">
                          <a
                            href="https://bsc.ncx.cx/swap"
                            target="_blank"
                            rel="noreferrer"
                            className="header-nav-link"
                          >
                            DEX
                          </a>
                        </Index.ListItem>
                        <Index.ListItem className="header-nav-li">
                          <a href="https://ncx.cx/ncx-card" className="header-nav-link">
                            NCX Card
                          </a>
                        </Index.ListItem>
                        <Index.ListItem className="header-nav-li">
                          <a
                            href="https://ncx.cx/ncx-token"
                            target="_blank"
                            rel="noreferrer"
                            className="header-nav-link"
                          >
                            NCX Token
                          </a>
                        </Index.ListItem>
                        <Index.ListItem className="header-nav-li">
                          <a
                            href="https://ncx.cx/rewards"
                            target="_blank"
                            rel="noreferrer"
                            className="header-nav-link"
                          >
                            Rewards
                          </a>
                        </Index.ListItem>
                        <Index.Box className="mobile-switch-main">
                          <Index.Box className="header-btn-main">
                            {!auth ? (
                              <Index.Button
                                className="header-btn header-signout-btn"
                                onClick={() => {
                                  navigate("/sign-in");
                                }}
                              >
                                Presale Sign In
                              </Index.Button>
                            ) : (
                              <Index.Button
                                className="header-btn header-signout-btn"
                                onClick={() => {
                                  dispatch(userLogout());
                                  navigate("/");
                                }}
                              >
                                Sign Out
                              </Index.Button>
                            )}
                            {/* <Index.Button className="header-btn header-signout-btn">
                              Log Out of Token Dash
                            </Index.Button> */}
                          </Index.Box>
                        </Index.Box>
                      </Index.List>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 4",
                    sm: "span 3",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column header-col-3"
                >



                  {location?.pathname !== "/" ? (
                    <Index.Box className="header-btn-main header-swtch-main">
                      {!auth ? (
                        <Index.Button
                          className="header-btn header-landing-btn-fill"
                          onClick={() => {
                            navigate("/sign-up");
                            dispatch(claimAirDrop());
                          }}
                        >
                          Claim Airdrop
                        </Index.Button>
                      ) : (
                        <>
                          <Index.Button
                            id="demo-customized-button"
                            aria-controls={openswitch ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openswitch ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickSwitch}
                            className="header-btn header-switch-btn"
                          >
                            Switch
                          </Index.Button>
                          <StyledMenu
                            className="header-popup-switch-main"
                            id="demo-customized-menu"
                            MenuListProps={{
                              'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={openswitch}
                            onClose={handleCloseSwitch}
                          >
                            <Index.Link to="https://my.ncx.cx/en/login" className="text-decoration"><Index.MenuItem onClick={handleCloseSwitch} disableRipple className="sign-in-btn header-popup-btn">
                              CEX Sign In{" "}
                            </Index.MenuItem></Index.Link>
                            <Index.Link to="https://bsc.ncx.cx/swap" className="text-decoration"><Index.MenuItem onClick={handleCloseSwitch} disableRipple className="sign-in-btn header-popup-btn">
                              Launch DEX
                            </Index.MenuItem></Index.Link>
                          </StyledMenu>
                        </>
                      )}
                      {!auth ? (
                        <Index.Button
                          className="header-btn header-signout-btn"
                          onClick={() => {
                            navigate("/sign-in");
                          }}
                        >
                          Presale Sign In
                        </Index.Button>
                      ) : (
                        <Index.Button
                          className="header-btn header-signout-btn"
                          onClick={() => {
                            dispatch(userLogout());
                            navigate("/");
                          }}
                        >
                          Sign Out
                        </Index.Button>
                      )}
                      <Index.Button className="header-btn head-play-btn-main">
                        <img
                          src={Index.Svg.apple}
                          className="playstore-icons"
                        />
                        <img
                          src={Index.Svg.playstore}
                          className="playstore-icons"
                        />
                      </Index.Button>


                      <Index.Box className="header-scanner-show">
                        <Index.Box className="header-play-scanner-box">
                          <img
                            src={Index.Svg.walletscanner}
                            className="header-scanner-img"
                          />
                          <Index.Typography className="head-scan-text">
                            Scan the QR code to download NCX mobile APP
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="header-language-main">
                            <Index.Button onClick={()=>setShowLanguage((e)=>!e)} className="header-language-button">{selectedLanguage}<span><img src={Index.Svg.thindownarrow} className="language-down-arrow"/></span></Index.Button>
                          {
                            showLanguage?
                            <Index.Box className="header-language-drop-box">
                            <Index.List className="haeder-language-ul">
                              {
                                menu.map((e)=><Index.ListItem onClick={()=>{
                                  setSelectedLanguage(e)
                                  setShowLanguage((e)=>!e)
                                }} className="header-language-li">
                                {e}
                              </Index.ListItem>)
                              }
                            </Index.List>
                          </Index.Box>:""
                          }
                     
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    ""
                  )}

                  {!auth ? (
                    <Index.Box className="header-logo-box">
                      <Index.Button
                        onClick={() => {
                          setOnSet(!onSet);
                          document.body.classList[!onSet ? "add" : "remove"](
                            "body-overflow"
                          );
                        }}
                        className="mobile-menu-btn"
                      >
                        <img
                          src={Index.Svg.mobilemenu}
                          className="mobile-menu-icon"
                          alt="logo"
                        />
                      </Index.Button>
                    </Index.Box>
                  ) : (
                    <Index.Box className="mobile-dropdown-main">
                      <Index.Button
                        onClick={() => setShowList(!showList)}
                        className="mobile-dro-btn"
                      >
                        {selected}
                        <img
                          src={Index.Svg.whitedown}
                          className="drop-down-icon"
                        />
                      </Index.Button>
                      {showList ? (
                        <Index.Box className="mobile-drop-ul-main">
                          <Index.List className="mobile-drop-list">
                            {list.map((e) => {
                              return (
                                <Index.ListItem
                                  onClick={() => {
                                    setSelected(e?.page);
                                    setShowList(false);
                                    navigate(e.path);
                                  }}
                                  className="mobile-drop-item"
                                >
                                  {e.page}
                                </Index.ListItem>
                              );
                            })}
                          </Index.List>
                        </Index.Box>
                      ) : (
                        ""
                      )}
                    </Index.Box>
                  )}
                  {location?.pathname == "/" ? (
                    <Index.Box className="header-landing-btn-main">
                      {!auth ? (
                        <Index.Button
                          className="header-btn header-landing-btn-fill"
                          onClick={() => {
                            navigate("/sign-up");
                            dispatch(claimAirDrop());
                          }}
                        >
                          Claim Airdrop
                        </Index.Button>
                      ) : (
                        <Index.Button className="header-btn header-switch-btn">
                          Switch
                        </Index.Button>
                      )}
                      {!auth ? (
                        <Index.Button
                          className="header-btn header-signout-btn"
                          onClick={() => {
                            navigate("/sign-in");
                          }}
                        >
                          Presale Sign In
                        </Index.Button>
                      ) : (
                        <Index.Button
                          className="header-btn header-signout-btn"
                          onClick={() => {
                            dispatch(userLogout());
                            navigate("/");
                          }}
                        >
                          Sign Out
                        </Index.Button>
                      )}
                    </Index.Box>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* header popup for sign out */}
      {/* <Index.Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="header-switch-popup"
      >
        <Index.Box className="header-switch-popup">
        <Index.Box className="header-popup-main">
          <Index.Box className="header-popup-btn-main">
            <Index.Button className="sign-in-btn header-popup-btn">
            CEX Sign In{" "}
            </Index.Button>
            <Index.Button className="launch-btn header-popup-btn">
            Launch DEX
            </Index.Button>
          </Index.Box>
        </Index.Box>
        </Index.Box>
      </Index.Dialog> */}
    </>
  );
}
