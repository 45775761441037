import React, { useState } from "react";
import { DataService } from "../../../../config/DataService";
import Index from "../../../Index";
import { API } from "../../../../config/Api";
import { validatePassword } from "../../../../component/common/AuthValidation";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const token = useSelector((store) => store.admin.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showNewPasswordError, setShowNewPasswordError] = useState("");
  const [passwordFields, setPasswordFields] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = useState(false);

  const handleMouseDownConfrimPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfrimPassword = () =>
    setShowConfrimPassword((show) => !show);

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };
  const onConfirmPasswordError = (val) => {
    if (!val) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Password and confirmPassword must be same",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        confirmPassword: "",
      }));
    }
  };
  const onSubmitError = () => {
    if (passwordFields.oldPassword.length === 0) {
      setError((prev) => ({
        ...prev,
        password: "Please Enter old password",
      }));
    }
    if (passwordFields.newPassword.length === 0) {
      setError((prev) => ({
        ...prev,
        newPassword: "Please Enter new password",
      }));
    }
    if (passwordFields.confirmPassword.length === 0) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Please Enter confirm password",
      }));
    }
  };

  const onHandleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "confirmPassword") {
      onConfirmPasswordError(value === passwordFields.newPassword);
    }
    if (name === "oldPassword") {
      if (value.length > 0) {
        setError((prev) => ({
          ...prev,
          password: "",
        }));
      }
    }
    if (name === "newPassword") {
      if (value.length > 0) {
        setShowNewPasswordError(validatePassword(value));
        setError(() => ({
          newpassword: "",
        }));
      }
    }
    if (name === "confirmPassword") {
      if (value.length > 0) {
        setError((prev) => ({
          ...prev,
          confirmPassword: "",
        }));
      }
    }
    setPasswordFields({
      ...passwordFields,
      [name]: value,
    });
  };

  const onChangePassword = async () => {
    if (
      passwordFields.newPassword === passwordFields.confirmPassword &&
      passwordFields?.oldPassword?.length > 0 &&
      passwordFields?.newPassword?.length > 0 &&
      passwordFields?.confirmPassword?.length > 0
    ) {
      const formData = new URLSearchParams();
      formData.append("oldPassword", passwordFields.oldPassword);
      formData.append("newPassword", passwordFields.newPassword);
      await DataService.post(API.User.changeUserPassword, formData, {
        headers: {
          auth: token,
        },
      })
        .then((response) => {
          toast(response?.data?.message);
        })
        .catch((error) => {
          toast(error?.response?.data?.message);
          if (error.response.data.message === "Token not authorized") {
            dispatch(getAdminToken(""));
            dispatch(getAdminType(""));
            dispatch(getAdminData({}));
            navigate("/")
          }
        });
    } else {
      onConfirmPasswordError(
        passwordFields.newPassword === passwordFields.confirmPassword
      );
      onSubmitError();
    }
  };
  return (
      <Index.Box className="tabpanel-main">
        <Index.Box className="profile-sequrity-setting-main">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="profile-display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box profile-input-box">
                  <Index.FormHelperText className="form-lable">
                    Old Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye form-control"
                      value={passwordFields.oldPassword}
                      placeholder="Old Password"
                      name="oldPassword"
                      onChange={onHandleInputChange}
                      id="outlined-adornment-password"
                      type={showOldPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownOldPassword}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">{error.password}</p>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              ></Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box profile-input-box">
                  <Index.FormHelperText className="form-lable">
                    New Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye form-control"
                      value={passwordFields.newPassword}
                      placeholder="New Password"
                      name="newPassword"
                      onChange={onHandleInputChange}
                      id="outlined-adornment-password"
                      type={showNewPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownNewPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">
                    {showNewPasswordError}
                    {error.newPassword}
                  </p>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box profile-input-box">
                  <Index.FormHelperText className="form-lable">
                    Confirm New Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye form-control"
                      value={passwordFields.confirmPassword}
                      placeholder="Confirm New Password"
                      onChange={onHandleInputChange}
                      name="confirmPassword"
                      id="outlined-adornment-password"
                      type={showConfrimPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfrimPassword}
                            onMouseDown={handleMouseDownConfrimPassword}
                            edge="end"
                          >
                            {showConfrimPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>
                  <p className="error-text">{error.confirmPassword}</p>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="error-text-main">
            <Index.Typography className="blue-error-text">
              <img
                src={Index.Svg.blueerror}
                className="error-icon"
                alt="error icon"
              />
              Password should be minimum 6 Character long.
            </Index.Typography>
            <Index.Typography className="red-error-text">
              <img
                src={Index.Svg.rederror}
                className="error-icon"
                alt="error icon"
              />
              Your password will update after confirm from your email.
            </Index.Typography>
          </Index.Box>
          <Index.PrimaryButton
            className="primary-btn profile-btn"
            btnLabel="Update"
            type="button"
            onClick={onChangePassword}
          />
        </Index.Box>
      </Index.Box>
  );
};

export default ChangePassword;
