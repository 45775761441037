import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";


export default function AirdropRefferalsAndEdit() {
    const token = useSelector((store) => store.admin.token);
    const [genralSettings, setGenralSettings] = useState(null)
    const [data, datatosend] = useState()
    const [error,setError] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const onChangeInput = (e, type) => {
        // setGenralSettings({ ...genralSettings, ['type']: type === 'refferals' ? 'refferals' : 'airdrop' })
        if (e.target.value.length >= 10) {
            e.preventDefault();
          }

        datatosend({ ...data, [e.target.name]: e.target.value })
    }


    // types: 'refferals' || 'airdrop';
    // tokens: "165535"

    const PostData = async (type) => {
        data['type'] = type
        await DataService.post(
            API.Admin.EDIT_REFFERAL_AND_AIRDROP,
            data,
            {
                headers: {
                    auth: token,
                },
            }
        )
            .then((res) => {
                console.log(res)
                toast.success(res.data.message)
                // setGenralSettings(res)
            })
            .catch((error) => {
                if (error.response.data.message === "Token not authorized") {
                    dispatch(getAdminToken(""));
                    dispatch(getAdminType(""));
                    dispatch(getAdminData({}));
                    navigate("/admin/login")
                }
            });
    }

    const fetchData = async () => {
        await DataService.post(
            API.Admin.GET_GENERAL_SETTINGS,
            {},
            {
                headers: {
                    auth: token,
                },
            }
        )
            .then((res) => {
                setGenralSettings(res.data)
            })
            .catch((error) => {
                if (error.response.data.message === "Token not authorized") {
                    dispatch(getAdminToken(""));
                    dispatch(getAdminType(""));
                    dispatch(getAdminData({}));
                    navigate("/admin/login")
                }
            });
    };
    useEffect(() => {
        let token = localStorage.getItem("token");
        if (!token) {
            <Navigate to="/admin/login"></Navigate>;
        }
    });

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Index.Box className="dashboard-main">
                <Index.Box className="dashboard-left-main">
                    <Index.Sidebar />
                </Index.Box>
                <Index.Box className="dashboard-right-main">
                    <Index.Header />
                    <Index.Box className="dashboard-containt-main">
                        <Index.Box className="dashboard-content add-user-containt">
                            <Index.Box className="user-head-title">
                                <Index.Typography
                                    className="admin-page-title"
                                    component="h2"
                                    variant="h2"
                                >
                                    Airdrop and Referrals
                                </Index.Typography>
                                <ToastContainer />
                            </Index.Box>
                            <Index.Box className="add-user-data-main">
                                <Index.Typography
                                    className="admin-page-title"
                                    style={{ marginBottom: '16px' }}
                                    component="h2"
                                    variant="h2"
                                >
                                    Refferal Tokens </Index.Typography>

                                <Index.Box className="input-box add-user-input">
                                    <Index.FormHelperText className="form-lable">
                                        Tokens
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                        <Index.TextField
                                            name="tokens"
                                            max={10}
                                            className="form-control"
                                   
                                            // onChange={onHandleInputChange}
                                            // value={fields.email}
                                            style={{ width: 200 }}
                                            inputProps={{ maxLength: 10 }}
                                            placeholder={genralSettings ? genralSettings.data[0].refferalTokenPerRefferal : ''}
                                            onChange={onChangeInput}
                                        />
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="submit-btn-main border-btn-main">
                                    <Index.Button
                                        className="submit-user-btn border-btn"
                                        //   disableRipple
                                        type="button"

                                        onClick={() => { PostData('refferals') }}
                                    >
                                        Submit
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box  style={{marginTop:20}}className="dashboard-content add-user-containt">
                            <Index.Box className="add-user-data-main">
                                <Index.Typography
                                    className="admin-page-title"
                                    style={{ marginBottom: '16px' }}
                                    component="h2"
                                    variant="h2"
                                >
                                    Airdrop Tokens
                                </Index.Typography>
                                <Index.Box className="input-box">
                                    <Index.FormHelperText className="form-lable">
                                        Tokens
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                        <Index.TextField
                                            name="tokens"
                                            max={10}
                                            className="form-control"
                                            // onChange={onHandleInputChange}
                                            // value={fields.email}
                                            inputProps={{ maxLength: 10 }}
                                            style={{ width: 200 }}
                                            placeholder={genralSettings ? genralSettings.data[0].airdropTokenPerRefferal : ''}
                                            onChange={onChangeInput}
                                        />
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="submit-btn-main border-btn-main">
                                    <Index.Button
                                        className="submit-user-btn border-btn"
                                        //   disableRipple
                                        type="button"
                                        onClick={() => { PostData('airdrop') }}
                                    >
                                        Submit
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </div >
    );
}
