import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import PendingPurchase from "./PendingPurchase";
import Purchase from "./Purchase";
import ListTab from "../../../../component/common/ListTab";

export default function PurchaseHistoty() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title withdrawal-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Deposits
                </Index.Typography>
                <ToastContainer />
              </Index.Box>

              <Index.Box className="tabs-main-box">
                <Index.Box sx={{ width: "100%" }}>
                  <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <ListTab
                      value={value}
                      onChange={handleChange}
                      label1={"Purchase History"}
                      label2={"Pending Purchase"}
                      children1 = {<Purchase />}
                      children2 = {<PendingPurchase />}
                      tabVal={value}
                    >
                     <Purchase />
                    </ListTab>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
