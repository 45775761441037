import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getAdminData, getAdminToken, getAdminType, getUserData } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((store) => store.admin.userToken);
  const data = useSelector((store) => store.admin.userData);
  const [name, setName] = useState(data.userName?.split(" ")[0]);
  const [lastName, setLastName] = useState(data.userName?.split(" ")[1]);
  const [email, setEmail] = useState(data.email);
  const [purchase, setPurchase] = useState(data.purchasedOnNcx);
  const [clientIdonNcx, setClientIdonNcx] = useState(data.ncxUserId);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const onNameChange = (e) => {
    setBtnDisabled(false);
    setName(e.target.value);
  };
  const onPurchaseChange = (e) => {
    setPurchase(e.target.value);
  };
  const onclientIdonNcxChange = (e) => {
    setClientIdonNcx(e.target.value);
  };

  const onLastNameChange = (e) => {
    setBtnDisabled(false);
    setLastName(e.target.value);
  };

  const onEmailChange = (e) => {
    setBtnDisabled(false);
    setEmail(e.target.value);
  };

  const onEditProfile = async () => {
    const formData = new URLSearchParams();
    formData.append("id", data._id);
    formData.append("userName", `${name} ${lastName}`);
    formData.append("email", email);
    await DataService.post(API.User.editUserProfile, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        dispatch(getUserData(response?.data?.data));
        toast(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };
  return (
    <Index.Box className="tabpanel-main">
      <Index.Box className="my-profile-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="profile-display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 6",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box profile-input-box ">
                <Index.FormHelperText className="form-lable">
                  CLIENT ID ON NCX
                </Index.FormHelperText>
                <Index.Typography
                  className="profile-data-text"
                  value={clientIdonNcx}
                  onChange={onclientIdonNcxChange}
                >
                  {clientIdonNcx || "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 6",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box profile-input-box ">
                <Index.FormHelperText className="form-lable">
                  Purchase on NCX
                </Index.FormHelperText>
                <Index.Typography
                  className="profile-data-text"
                  onChange={onPurchaseChange}
                >
                  {purchase ? "Purchased" : "Not Purchased"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box profile-input-box">
                <Index.FormHelperText className="form-lable">
                  FIRST NAME
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    value={name}
                    onChange={onNameChange}
                    className="form-control"
                    placeholder="Antoni "
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box profile-input-box">
                <Index.FormHelperText className="form-lable">
                  LAST NAME
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    value={lastName}
                    onChange={onLastNameChange}
                    className="form-control"
                    placeholder="Blinkin"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box profile-input-box ">
                <Index.FormHelperText className="form-lable">
                  EMAIL ADDRESS
                  <Index.Typography className="profile-condition-text">
                    (must match your email on NCX)
                  </Index.Typography>
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    value={email}
                    onChange={onEmailChange}
                    className="form-control"
                    placeholder="info@electornic.com"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.PrimaryButton
          onClick={onEditProfile}
          className="primary-btn profile-btn"
          btnLabel="Update"
          disabled={btnDisabled}
        />
      </Index.Box>
    </Index.Box>
  );
};

export default MyProfile;
