import Routers from "./routes/Routes";

import admin from "./assets/style/admin.css";
import style from "./assets/style/style.css";
import responsive from "./assets/style/responsive.css";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </div>
  );
}

export default App;
