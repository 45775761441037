import React, { useState } from "react";
import Index from "../../Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { validateEmail } from "../../../component/common/AuthValidation";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError(validateEmail(e.target.value));
  };
  const onForgotPassword = async () => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    if (email !== "") {
      await DataService.post(API.Admin.Forgot_Password, formData)
        .then((response) => {
          toast(" Mail has been sent to your registered account");
        })
        .catch((error) => {
          toast.error("your account does'nt exist");
        });
    }else{
      if(email===""){
        setError("Please Enter your email")
      }
    }
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Svg.logo}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>
              <Index.Box className="card-login-main">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Forgot Password
                </Index.Typography>
                <Index.Box className="input-box">
                  <Index.FormHelperText
                    className="form-lable"
                    style={{ marginTop: 36 }}
                  >
                    Enter your email
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      value={email}
                      onChange={handleEmail}
                      className="form-control"
                    />
                  </Index.Box>
                  <p className="error-text">{error}</p>
                  <ToastContainer />
                </Index.Box>
                <Index.Box className="btn-main login-btn-main">
                  <Index.Button
                    className="btn login-btn"
                    onClick={onForgotPassword}
                    type="button"
                    disableRipple
                  >
                    Submit
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
